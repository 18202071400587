import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import mainTheme from 'styles/mainTheme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './router/AppRoutes';
import { IPublicClientApplication } from '@azure/msal-browser';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { MsalProvider } from '@azure/msal-react';
import { ClientSideNavigation } from 'auth/ClientSideNavigation';
import { Provider } from 'react-redux';
import store from './redux/store';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

interface IAppProps {
  msalInstance: IPublicClientApplication;
  aiReactPlugin: ReactPlugin;
}

export const App = ({ msalInstance, aiReactPlugin }: IAppProps): JSX.Element => {
  return (
    <AppInsightsContext.Provider value={aiReactPlugin}>
      <BrowserRouter>
        <ClientSideNavigation pca={msalInstance}>
          <MsalProvider instance={msalInstance}>
            <CssBaseline />
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={mainTheme}>
                <Provider store={store}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <ToastContainer />
                    <AppRoutes />
                  </LocalizationProvider>
                </Provider>
              </ThemeProvider>
            </StyledEngineProvider>
          </MsalProvider>
        </ClientSideNavigation>
      </BrowserRouter>
    </AppInsightsContext.Provider>
  );
};
