import { useTheme, Typography, useMediaQuery, Chip, FormControl, MenuItem } from '@mui/material';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface SupplierSelectStepProps {
  selectedSuppliersIds: string[];
  setSelectedSuppliersIds: Function;
  allSuppliers: ISupplierCategoryGetModel[];
  setSelectedSuppliersWithCats: Function;
  selectedSuppliersWithCats: ISupplierCategoryPostModel[];
}

const SupplierSelectStep: React.FC<SupplierSelectStepProps> = ({
  selectedSuppliersIds,
  setSelectedSuppliersIds,
  allSuppliers,
  setSelectedSuppliersWithCats,
  selectedSuppliersWithCats,
}: SupplierSelectStepProps): JSX.Element | null => {
  const inputMaxWidth = 280;
  const theme = useTheme();
  const isMdWidth = useMediaQuery(theme.breakpoints.up('md'));
  const [unselectedSuppliers, setUnselectedSuppliers] = useState<ISupplierCategoryGetModel[]>([]);

  useEffect(() => {
    // filter out selected suppliers
    if (allSuppliers?.length > 0 && selectedSuppliersIds?.length > 0) {
      const unselectedOnes = allSuppliers.reduce((accumulator: any, currentItem: ISupplierCategoryGetModel) => {
        if (!selectedSuppliersIds.includes(currentItem?.id.toString())) {
          return accumulator.concat(currentItem);
        } else {
          return accumulator;
        }
      }, []);
      setUnselectedSuppliers(unselectedOnes);
    } else if (allSuppliers?.length > 0 && selectedSuppliersIds?.length === 0) {
      setUnselectedSuppliers(allSuppliers);
    }
  }, [selectedSuppliersIds, allSuppliers]);

  const handleAddSupplierChange = (event: SelectChangeEvent): void => {
    setSelectedSuppliersIds([...selectedSuppliersIds, event.target.value]);
  };

  const renderPlaceHolderInput = (): JSX.Element => {
    return (
      <FormControl variant='standard' sx={{ m: 1, minWidth: 120, maxWidth: inputMaxWidth }}>
        <Select disabled labelId='create-eval-placeholder' id='create-eval-placeholder'></Select>
      </FormControl>
    );
  };

  const listSelectedSuppliers = (): JSX.Element | null => {
    if (selectedSuppliersIds?.length > 0 && allSuppliers?.length > 0) {
      const filteredSuppliers = selectedSuppliersIds.map((supplierId) => {
        const supplierItem = allSuppliers.find((supplierObj) => supplierObj?.id.toString() === supplierId);
        if (supplierItem != null) {
          return supplierItem;
        } else return null;
      });

      const handleDelete = (idToDelete: any): void => {
        if (idToDelete !== 'undefined' || idToDelete !== 'null') {
          const newSelectedSuppliers = selectedSuppliersIds.filter((id) => id !== idToDelete.toString());
          setSelectedSuppliersIds(newSelectedSuppliers);
          // remove selected categories for supplier if they were selected
          if (selectedSuppliersWithCats?.length > 0) {
            const newSupplierCats = selectedSuppliersWithCats.filter((item) => item.supplierId !== idToDelete);
            setSelectedSuppliersWithCats(newSupplierCats);
          }
        }
      };

      if (filteredSuppliers?.length > 0) {
        return (
          <div>
            {filteredSuppliers.map((supplierItemObj) => {
              return (
                <div key={supplierItemObj?.id} className='evalChipItem'>
                  <Chip
                    onDelete={() => handleDelete(supplierItemObj?.id)}
                    onClick={() => handleDelete(supplierItemObj?.id)}
                    sx={{ borderRadius: 0 }}
                    label={supplierItemObj?.name}
                    deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
                  />
                </div>
              );
            })}
          </div>
        );
      }
    }
    return null;
  };

  const renderSelect = (): JSX.Element => {
    const _findContent = (): JSX.Element => {
      if (unselectedSuppliers?.length > 0) {
        return (
          <FormControl variant='standard' sx={{ m: 1, minWidth: 120, maxWidth: inputMaxWidth }}>
            <Select labelId='create-eval-suppliers' id='create-eval-suppliers' onChange={handleAddSupplierChange} value=''>
              <MenuItem hidden selected key='empty-value-supp' value=''></MenuItem>
              {unselectedSuppliers?.map((supplierItem) => (
                <MenuItem key={supplierItem?.id} value={supplierItem.id}>
                  {supplierItem?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      } else {
        return renderPlaceHolderInput();
      }
    };

    return (
      <div className='evalItemInnerStep2'>
        <Typography sx={{ margin: '8px', marginBottom: 0, marginTop: '15px', letterSpacing: '0.46px', lineHeight: '26px' }}>
          VELG LEVARANDØRER*
        </Typography>
        {_findContent()}
      </div>
    );
  };

  return (
    <div className='evalItemInnerStep2'>
      {renderSelect()}
      <div className={isMdWidth ? 'evalCipContainer' : 'evalCipContainerMobile'}>{listSelectedSuppliers()}</div>
    </div>
  );
};

export default SupplierSelectStep;
