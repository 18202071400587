import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { backe } from 'styles/jsColors';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: backe.white,
    '&:hover': {
      backgroundColor: alpha(backe.green, theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: backe.green,
  },
}));

export default CustomSwitch;
