/* eslint-disable @typescript-eslint/no-unused-vars */
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { useTheme, ButtonBase } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioGroup from '@mui/material/RadioGroup';
import './styles.scss';

interface CheckBoxAccordionProps {
  accordionTitle: string;
  setOpen: Function;
  open: boolean;
  checkBoxObjectsArr?:
    | EvaluationTypeGetModel[]
    | FilterCompanyModel[]
    | IProjectPhaseGetModel[]
    | AccordionCheckBoxItem[]
    | ISupplierCategoryGetModel[]
    | QuestionCategory[];
  accordionId: string;
  selectedValues:
    | EvaluationTypeGetModel[]
    | FilterCompanyModel[]
    | IProjectPhaseGetModel[]
    | AccordionCheckBoxItem[]
    | ISupplierCategoryGetModel[]
    | QuestionCategory[]
    | undefined;
  setSelectedValues?: Function;
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  // backgroundColor: theme.palette.background.default,
  padding: 0,
  margin: 0,
  border: 'none',
  borderBottomColor: theme.palette.primary.main,
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreOutlinedIcon fontSize='small' />} {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
}));

// list items by their Id and name
export const CheckBoxAccordion: React.FunctionComponent<CheckBoxAccordionProps> = ({
  accordionTitle,
  setOpen,
  open,
  checkBoxObjectsArr,
  accordionId,
  selectedValues,
  setSelectedValues,
}: CheckBoxAccordionProps): JSX.Element | null => {
  const theme = useTheme();

  const handleClick = (itemId: number): void => {
    const itemFound = selectedValues?.find((item) => item.id === itemId);
    if (itemFound != null) {
      const newArr = selectedValues?.filter((item) => item.id !== itemId);
      if (setSelectedValues !== undefined) {
        setSelectedValues(newArr);
      }
    } else {
      const newItem = checkBoxObjectsArr?.find((item) => item.id === itemId);
      const oldValues = selectedValues !== undefined && selectedValues?.length > 0 ? selectedValues : [];
      if (setSelectedValues !== undefined) {
        setSelectedValues([...oldValues, newItem]);
      }
    }
  };

  const isValueMarked = (itemId: number): boolean => {
    if (selectedValues !== undefined && selectedValues?.length > 0) {
      const valueFound = selectedValues.find((item) => item.id === itemId);
      if (valueFound != null) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const toggleOpen = (): void => {
    setOpen(!open);
  };

  if (checkBoxObjectsArr?.length === 0) {
    return null;
  }

  const renderCheckBoxes = (): JSX.Element | null => {
    return (
      <RadioGroup>
        {checkBoxObjectsArr?.map((item) => {
          return (
            <div key={item.id} className='checkBoxItem'>
              <ButtonBase onClick={() => handleClick(item.id)}>
                <Checkbox
                  checked={isValueMarked(item.id)}
                  value={item.id}
                  sx={{
                    color: theme?.palette?.primary?.main,
                  }}
                />
                <Typography sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  {item.name}
                </Typography>
              </ButtonBase>
            </div>
          );
        })}
      </RadioGroup>
    );
  };

  return (
    <Accordion expanded={open} onChange={toggleOpen} className='accordionWrapper'>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={accordionId} id={accordionId} className='accordionSummary'>
        <Typography sx={{ fontWeight: 700 }}>{accordionTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails className='accordionContent'>{renderCheckBoxes()}</AccordionDetails>
    </Accordion>
  );
};
