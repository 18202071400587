import {
  SET_QUESTION_FILTERS_PROJECT,
  SET_ALL_QUESTION_FILTERS_PROJECT,
  SET_LOADING_QUESTION_FILTERS_PROJECT,
  SET_LOADING_QUESTION_FILTERS_SUPPLIER,
  SET_AVAILABLE_SUPPLIER_CATEGORIES,
  SET_SELECTED_PROJECT_QUESTION_FILTER_PHASES,
  SET_SELECTED_PROJECT_QUESTION_FILTER_SUPP_CATS,
  SET_SELECTED_PROJECT_QUESTION_FILTER_QUESTION_CATS,
  SET_AVAILABLE_PROJECT_PHASES,
  RESET_SELECTED_PROJECT_QUESTION_FILTERS,
  SET_PAGINATED_QUESTIONS_PROJECT,
  SET_SELECTED_PAGE_QUESTIONS_PROJECT,
  SET_LOADING_QUESTION_DATA_PROJECT,
  SET_RESET_PROJECTS_QUESTION_LIST_MANUALLY,
  SET_SELECTED_SUPPLIER_QUESTION_FILTER_PHASES,
  SET_SELECTED_SUPPLIER_QUESTION_FILTER_SUPP_CATS,
  SET_SELECTED_SUPPLIER_QUESTION_FILTER_QUESTION_CATS,
  RESET_SELECTED_SUPPLIER_QUESTION_FILTERS,
  SET_PAGINATED_QUESTIONS_SUPPLIER,
  SET_SELECTED_PAGE_QUESTIONS_SUPPLIER,
  SET_LOADING_QUESTION_DATA_SUPPLIER,
  SET_RESET_SUPPLIER_QUESTION_LIST_MANUALLY,
  SET_ALL_QUESTION_FILTERS_SUPPLIER,
  SetProjectQuestionFiltersAction,
  SetAllQuestionFiltersAction,
  SetLoadingQuestionFiltersAction,
  SetLoadingSupplierFiltersAction,
  SetAvailableSupplierCategoriesAction,
  SetAvailableProjectPhasesAction,
  SetSelectedProjectQuestionsFilterPhasesAction,
  SetSelectedProjectQuestionsFilterSuppCatsAction,
  SetSelectedProjectQuestionsFilterQuestionCatsAction,
  ResetSelectedProjectQuestionFiltersAction,
  SetPaginatedQuestionsProjectAction,
  SetSelectedPageQuestionsProjectAction,
  SetLodingQuestionProjectDataAction,
  SetResetProjectQuestionsListManuallyAction,
  SetSelectedSupplierQuestionsFilterPhasesAction,
  SetSelectedSupplierQuestionsFilterSuppCatsAction,
  SetSelectedSupplierQuestionsFilterQuestionCatsAction,
  ResetSelectedSupplierQuestionFiltersAction,
  SetPaginatedQuestionsSupplierAction,
  SetSelectedPageQuestionsSupplierAction,
  SetLodingQuestionSupplierDataAction,
  SetResetSupplierQuestionsListManuallyAction,
  SetAllQuestionFiltersSupplierAction,
} from 'redux/actions/questions';

const initialState = {
  availableSupplierCategories: [] as ISupplierCategoryGetModel[],
  availalbleProjectPhases: [] as IProjectPhaseGetModel[],
  projects: {
    questionFilters: [] as QuestionCategory[],
    loadingQuestions: false,
    selectedFilters: {
      selectedPhases: [] as IProjectPhaseGetModel[],
      selectedSupplierTypes: [] as ISupplierCategoryGetModel[],
      selectedQuestionCats: [] as QuestionCategory[],
    },
    paginatedQuestions: {
      pageNumber: 1,
      totalPages: 0,
      pageSize: 30,
      totalRecords: 0,
      data: [],
    },
    loadingQuestionData: false,
    resetQuestionList: false,
  },
  suppliers: {
    questionFilters: [] as QuestionCategory[],
    loadingQuestions: false,
    selectedFilters: {
      selectedPhases: [] as IProjectPhaseGetModel[],
      selectedSupplierTypes: [] as ISupplierCategoryGetModel[],
      selectedQuestionCats: [] as QuestionCategory[],
    },
    paginatedQuestions: {
      pageNumber: 1,
      totalPages: 0,
      pageSize: 30,
      totalRecords: 0,
      data: [],
    },
    loadingQuestionData: false,
    resetQuestionList: false,
  },
};

export default function (
  state = initialState,
  action:
    | SetProjectQuestionFiltersAction
    | SetAllQuestionFiltersAction
    | SetLoadingQuestionFiltersAction
    | SetLoadingSupplierFiltersAction
    | SetAvailableSupplierCategoriesAction
    | SetAvailableProjectPhasesAction
    | SetSelectedProjectQuestionsFilterPhasesAction
    | SetSelectedProjectQuestionsFilterSuppCatsAction
    | SetSelectedProjectQuestionsFilterQuestionCatsAction
    | ResetSelectedProjectQuestionFiltersAction
    | SetPaginatedQuestionsProjectAction
    | SetSelectedPageQuestionsProjectAction
    | SetLodingQuestionProjectDataAction
    | SetResetProjectQuestionsListManuallyAction
    | SetSelectedSupplierQuestionsFilterPhasesAction
    | SetSelectedSupplierQuestionsFilterSuppCatsAction
    | SetSelectedSupplierQuestionsFilterQuestionCatsAction
    | ResetSelectedSupplierQuestionFiltersAction
    | SetPaginatedQuestionsSupplierAction
    | SetSelectedPageQuestionsSupplierAction
    | SetLodingQuestionSupplierDataAction
    | SetResetSupplierQuestionsListManuallyAction
    | SetAllQuestionFiltersSupplierAction,
): QuestionsStateOutter {
  switch (action.type) {
    case SET_QUESTION_FILTERS_PROJECT:
      return {
        ...state,
        projects: {
          ...state.projects,
          questionFilters: action.data,
        },
      };
    case SET_ALL_QUESTION_FILTERS_PROJECT:
      return {
        ...state,
        projects: {
          ...state.projects,
          questionFilters: action.data,
        },
        suppliers: {
          ...state.suppliers,
          questionFilters: action.data,
        },
      };

    case SET_LOADING_QUESTION_FILTERS_PROJECT:
      return {
        ...state,
        projects: {
          ...state.projects,
          loadingQuestions: action.data,
        },
      };

    case SET_LOADING_QUESTION_FILTERS_SUPPLIER:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          loadingQuestions: action.data,
        },
      };

    case SET_AVAILABLE_SUPPLIER_CATEGORIES:
      return {
        ...state,
        availableSupplierCategories: action.data,
      };

    case SET_AVAILABLE_PROJECT_PHASES:
      return {
        ...state,
        availalbleProjectPhases: action.data,
      };

    case SET_SELECTED_PROJECT_QUESTION_FILTER_PHASES:
      return {
        ...state,
        projects: {
          ...state.projects,
          selectedFilters: {
            ...state.projects.selectedFilters,
            selectedPhases: action.data,
          },
        },
      };

    case SET_SELECTED_PROJECT_QUESTION_FILTER_SUPP_CATS:
      return {
        ...state,
        projects: {
          ...state.projects,
          selectedFilters: {
            ...state.projects.selectedFilters,
            selectedSupplierTypes: action.data,
          },
        },
      };

    case SET_SELECTED_PROJECT_QUESTION_FILTER_QUESTION_CATS:
      return {
        ...state,
        projects: {
          ...state.projects,
          selectedFilters: {
            ...state.projects.selectedFilters,
            selectedQuestionCats: action.data,
          },
        },
      };

    case RESET_SELECTED_PROJECT_QUESTION_FILTERS:
      return {
        ...state,
        projects: {
          ...state.projects,
          selectedFilters: initialState.projects.selectedFilters,
        },
      };

    case SET_PAGINATED_QUESTIONS_PROJECT:
      return {
        ...state,
        projects: {
          ...state.projects,
          paginatedQuestions: action.data,
        },
      };

    case SET_SELECTED_PAGE_QUESTIONS_PROJECT:
      return {
        ...state,
        projects: {
          ...state.projects,
          paginatedQuestions: {
            ...state.projects.paginatedQuestions,
            pageNumber: action.data,
          },
        },
      };

    case SET_LOADING_QUESTION_DATA_PROJECT:
      return {
        ...state,
        projects: {
          ...state.projects,
          loadingQuestionData: action.data,
        },
      };

    case SET_RESET_PROJECTS_QUESTION_LIST_MANUALLY:
      return {
        ...state,
        projects: {
          ...state.projects,
          resetQuestionList: action.data,
        },
      };

    case SET_SELECTED_SUPPLIER_QUESTION_FILTER_PHASES:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          selectedFilters: {
            ...state.suppliers.selectedFilters,
            selectedPhases: action.data,
          },
        },
      };

    case SET_SELECTED_SUPPLIER_QUESTION_FILTER_SUPP_CATS:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          selectedFilters: {
            ...state.suppliers.selectedFilters,
            selectedSupplierTypes: action.data,
          },
        },
      };

    case SET_SELECTED_SUPPLIER_QUESTION_FILTER_QUESTION_CATS:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          selectedFilters: {
            ...state.suppliers.selectedFilters,
            selectedQuestionCats: action.data,
          },
        },
      };

    case RESET_SELECTED_SUPPLIER_QUESTION_FILTERS:
      return {
        ...state,
        suppliers: {
          ...state.projects,
          selectedFilters: initialState.suppliers.selectedFilters,
        },
      };

    case SET_PAGINATED_QUESTIONS_SUPPLIER:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          paginatedQuestions: action.data,
        },
      };

    case SET_SELECTED_PAGE_QUESTIONS_SUPPLIER:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          paginatedQuestions: {
            ...state.suppliers.paginatedQuestions,
            pageNumber: action.data,
          },
        },
      };

    case SET_LOADING_QUESTION_DATA_SUPPLIER:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          loadingQuestionData: action.data,
        },
      };

    case SET_RESET_SUPPLIER_QUESTION_LIST_MANUALLY:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          resetQuestionList: action.data,
        },
      };

    case SET_ALL_QUESTION_FILTERS_SUPPLIER:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          questionFilters: action.data,
        },
      };

    default:
      return state;
  }
}
