import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { evaluationsReducer, evaluationFormReducer, questionsReducer } from './reducers';

interface IAppState {
  evaluations?: EvaluationStateOutter | undefined | any;
  evaluationForm?: ReduxEvaluationFormStateModel | undefined | any;
  questions?: QuestionsStateOutter | undefined | any;
}

const rootReducer = combineReducers<IAppState>({
  evaluations: evaluationsReducer,
  evaluationForm: evaluationFormReducer,
  questions: questionsReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: {
    reducer: rootReducer,
  },
});

export default store;
