import React from 'react';
import { Box, Skeleton, Stack } from '@mui/material';

export const QuestionListSkeleton: React.FC = () => {
  return (
    <Stack alignItems='stretch' alignContent='center'>
      <Skeleton variant='rectangular' height={40.5} sx={{ marginBottom: '1px' }} />
      {[...Array(30)].map((_, i) => (
        <Box
          sx={{
            width: '100%',
            flex: 1,
            alignSelf: 'center',
          }}
          key={i}
        >
          <Skeleton variant='rectangular' height={58} sx={{ marginBottom: '1px' }} />
        </Box>
      ))}
    </Stack>
  );
};

export default QuestionListSkeleton;
