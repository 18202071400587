import { SvgIcon } from '@mui/material';
import React from 'react';
import { ReactComponent as NoResultsPic } from 'assets/no-evaluations.svg';

interface SvgProps {
  width: string;
  height: string;
}

export const NoResultsSvg: React.FC<SvgProps> = ({ width, height }) => {
  return <SvgIcon sx={{ width, height }} component={NoResultsPic} viewBox='0 0 370 370' />;
};

export default NoResultsSvg;
