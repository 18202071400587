/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios, { ResponseType } from 'axios';
import { appSettings } from '../../config/appSettings';
import { useMsalUtils } from 'auth/useMsalUtils';

export interface IUseApi {
  getEvaluationsFiltersCall: () => Promise<EvaluationFiltersState>;
  getEvaluationsCall: (params: any) => Promise<IPaginatedGetModel<EvaluationsState[]>>;
  getCompaniesCall: () => Promise<FilterCompanyModel[]>;
  getProjectsOfCompanyCall: (companyId: string) => Promise<EvaluationCompanyModel[]>;
  getPhasesOfProjectsCall: () => Promise<IProjectPhaseGetModel[]>;
  getPhasesOfProjectsForCreateEvalCall: (evalTypeId: number) => Promise<IProjectPhaseGetModel[]>;
  getSuppliersOfCompanyProjectCall: (clientId: string, projectId: string) => Promise<ISupplierCategoryGetModel[]>;
  getSupplierCategoriesCall: () => Promise<ISupplierCategoryGetModel[]>;
  getSupplierCategoriesForCreateEvalCall: (projectPhaseId: number) => Promise<ISupplierCategoryGetModel[]>;
  getProjectMembersCall: (clientId: string, projectId: string, evalId: string) => Promise<EvaluationRecipientObject[]>;
  getEvaluationQuestionsCall: (
    evaluationTypeId: string,
    projectPhaseId: string,
    supplierCategoryId: SupplierCategoriesArrayObject,
  ) => Promise<EvaluationQuestionObject[]>;
  getEvaluationQuestionsCallForProject: (evaluationTypeId: string, projectPhaseId: string) => Promise<EvaluationQuestionObject[]>;
  getEvaluationQuestionsCallEdit: (evaluationId: number) => Promise<EvaluationQuestionObject[]>;
  deleteEvaluationCall: (evaluationId: any) => Promise<void>;
  createEvaluationCall: (params: any) => Promise<void>;
  updateEvaluationCall: (params: any, evaluationId: any) => Promise<void>;
  getQuestionCategoriesCall: () => Promise<QuestionCategory[]>;
  getQuestionsCall: (params: any) => Promise<IPaginatedGetModel<QuestionState[]>>;
  updateQuestionCall: (questionItem: QuestionStatePutMethodItem, questionId: number) => Promise<void>;
  createQuestionCategoryCall: (questionName: string) => Promise<QuestionCategory>;
  createQuestionCall: (params: any) => Promise<QuestionState[]>;
  deleteQuestionCall: (questionId: any) => Promise<void>;
}

interface IAxiosConfig {
  baseURL: string;
  responseType: ResponseType;
}

const axiosConfig: IAxiosConfig = {
  baseURL: `${appSettings.api.host}/v1`,
  responseType: 'json',
};

export const useApi = (): IUseApi => {
  const { getAccesstoken } = useMsalUtils();

  const getEvaluationsFiltersCall = async (): Promise<EvaluationFiltersState> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<EvaluationFiltersState>(`/filters`, {
      ...axiosConfig,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const getEvaluationsCall = async (params: any): Promise<IPaginatedGetModel<EvaluationsState[]>> => {
    const cleanedParams = cleanParamsFromDefaultValues(params);
    const URLsearchParams = createURLParamsFromJSON(cleanedParams);
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<IPaginatedGetModel<EvaluationsState[]>>(`/evaluations${URLsearchParams}`, {
      ...axiosConfig,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const getCompaniesCall = async (): Promise<FilterCompanyModel[]> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<FilterCompanyModel[]>(`/companies`, {
      ...axiosConfig,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const getProjectsOfCompanyCall = async (projectId: string): Promise<EvaluationCompanyModel[]> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<EvaluationCompanyModel[]>(`/companies/${projectId}/projects`, {
      ...axiosConfig,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const getPhasesOfProjectsCall = async (): Promise<IProjectPhaseGetModel[]> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<IProjectPhaseGetModel[]>(`/projectphases`, {
      ...axiosConfig,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const getPhasesOfProjectsForCreateEvalCall = async (evalTypeId: number): Promise<IProjectPhaseGetModel[]> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<IProjectPhaseGetModel[]>(`/projectphases?withActiveQuestions=true&evaluationTypeId=${evalTypeId}`, {
      ...axiosConfig,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const getSuppliersOfCompanyProjectCall = async (clientId: string, projectId: string): Promise<ISupplierCategoryGetModel[]> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<ISupplierCategoryGetModel[]>(`/companies/${clientId}/projects/${projectId}/suppliers`, {
      ...axiosConfig,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const getSupplierCategoriesCall = async (): Promise<ISupplierCategoryGetModel[]> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<ISupplierCategoryGetModel[]>(`/suppliercategories`, {
      ...axiosConfig,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const getSupplierCategoriesForCreateEvalCall = async (projectPhaseId: number): Promise<ISupplierCategoryGetModel[]> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<ISupplierCategoryGetModel[]>(
      `/suppliercategories?withActiveQuestions=true&projectPhaseId=${projectPhaseId}`,
      {
        ...axiosConfig,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  };

  const getProjectMembersCall = async (clientId: string, projectId: string, evalId: string): Promise<EvaluationRecipientObject[]> => {
    const routeToUse =
      evalId?.length > 0
        ? `/companies/${clientId}/projects/${projectId}/members?evaluationId=${evalId}`
        : `/companies/${clientId}/projects/${projectId}/members`;

    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<EvaluationRecipientObject[]>(routeToUse, {
      ...axiosConfig,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const getEvaluationQuestionsCall = async (
    evaluationTypeId: string,
    projectPhaseId: string,
    supplierCategoryIds: SupplierCategoriesArrayObject,
  ): Promise<EvaluationQuestionObject[]> => {
    const URLsearchParams = createURLParamsFromJSON(supplierCategoryIds);
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<EvaluationQuestionObject[]>(
      `/questions/evaluationquestions${URLsearchParams}&projectPhaseId=${projectPhaseId}&evaluationTypeId=${evaluationTypeId}`,
      {
        ...axiosConfig,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  };

  const getEvaluationQuestionsCallForProject = async (
    evaluationTypeId: string,
    projectPhaseId: string,
  ): Promise<EvaluationQuestionObject[]> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<EvaluationQuestionObject[]>(
      `/questions/evaluationquestions?projectPhaseId=${projectPhaseId}&evaluationTypeId=${evaluationTypeId}`,
      {
        ...axiosConfig,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  };

  const getEvaluationQuestionsCallEdit = async (evaluationId: number): Promise<EvaluationQuestionObject[]> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<EvaluationQuestionObject[]>(`/evaluations/${evaluationId}/questions`, {
      ...axiosConfig,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const deleteEvaluationCall = async (evaluationId: string): Promise<void> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    await axios.delete(`/evaluations/${evaluationId}`, {
      ...axiosConfig,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const createEvaluationCall = async (params: any): Promise<void> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    await axios.post(`/evaluations`, params, {
      ...axiosConfig,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const updateEvaluationCall = async (params: any, evaluationId: any): Promise<void> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    await axios.put(`/evaluations/${evaluationId}`, params, {
      ...axiosConfig,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const getQuestionCategoriesCall = async (): Promise<QuestionCategory[]> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    const response = await axios.get<QuestionCategory[]>(`/questions/questioncategories`, {
      ...axiosConfig,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const getQuestionsCall = async (params: any): Promise<IPaginatedGetModel<QuestionState[]>> => {
    const cleanedParams = cleanParamsFromDefaultValues(params);
    const URLsearchParams = createURLParamsFromJSON(cleanedParams);
    const token = await getAccesstoken(appSettings.auth.apiScopes);

    const response = await axios.get<IPaginatedGetModel<QuestionState[]>>(`/questions${URLsearchParams}`, {
      ...axiosConfig,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const updateQuestionCall = async (params: QuestionStatePutMethodItem, questionId: number): Promise<void> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    await axios.put<QuestionStatePutMethodItem>(`/questions/${questionId}`, params, {
      ...axiosConfig,
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const createQuestionCategoryCall = async (questionName: string): Promise<QuestionCategory | any> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    await axios.post<QuestionCategory>(
      `/questions/${questionName}`,
      {},
      {
        ...axiosConfig,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  };

  const createQuestionCall = async (params: CreateQuestionPayloadModel): Promise<QuestionCategory | any> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    await axios.post<CreateQuestionPayloadModel>(`/questions`, params, {
      ...axiosConfig,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const deleteQuestionCall = async (questionId: string): Promise<void> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);
    await axios.delete(`/questions/${questionId}`, {
      ...axiosConfig,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  return {
    getEvaluationsFiltersCall,
    getEvaluationsCall,
    getCompaniesCall,
    getProjectsOfCompanyCall,
    getPhasesOfProjectsCall,
    getSuppliersOfCompanyProjectCall,
    getSupplierCategoriesCall,
    getSupplierCategoriesForCreateEvalCall,
    getProjectMembersCall,
    getEvaluationQuestionsCall,
    getEvaluationQuestionsCallForProject,
    getEvaluationQuestionsCallEdit,
    deleteEvaluationCall,
    createEvaluationCall,
    updateEvaluationCall,
    getQuestionCategoriesCall,
    getQuestionsCall,
    updateQuestionCall,
    createQuestionCategoryCall,
    createQuestionCall,
    deleteQuestionCall,
    getPhasesOfProjectsForCreateEvalCall,
  };
};

const cleanParamsFromDefaultValues = (jsonObj: any): any => {
  const cleanedFromDefaultValues = Object.entries(jsonObj).reduce((accumulator: any, [key, value]: any) => {
    if (
      ((value instanceof Array && value?.length > 0) || typeof value === 'string' || typeof value === 'number') &&
      typeof value !== 'undefined' &&
      typeof key !== 'undefined'
    ) {
      const newItem = { [key]: value };
      return Object.assign(accumulator, newItem);
    } else {
      return accumulator;
    }
  }, {});

  return cleanedFromDefaultValues;
};

const createURLParamsFromJSON = (paramsObject: any): any => {
  return Object.entries(paramsObject).reduce((accumulator: any, [key, value]: any) => {
    if (value instanceof Array && value?.length > 0) {
      let stringUrl = '';
      value.forEach((arrItem) => {
        if (accumulator?.length === 0 && stringUrl?.length === 0) {
          stringUrl = `${stringUrl}?${key}=${arrItem}`;
        } else {
          stringUrl = `${stringUrl}&${key}=${arrItem}`;
        }
      });
      return `${accumulator}${stringUrl}`;
    } else {
      if (accumulator?.length === 0) {
        return `${accumulator}?${key}=${value}`;
      }
      return `${accumulator}&${key}=${value}`;
    }
  }, '');
};
