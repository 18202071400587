export const SET_EVALUATION_FILTERS = 'SET_EVALUATION_FILTERS';
export const SET_PAGINATED_EVALUATIONS = 'SET_PAGINATED_EVALUATIONS';
export const SET_SELECTED_EVALUATION_FILTERS = 'SET_SELECTED_EVALUATION_FILTERS';
// Evaluation filters
export const SET_EVAL_FILTER_DATE_FROM = 'SET_EVAL_FILTER_DATE_FROM';
export const SET_EVAL_FILTER_DATE_TO = 'SET_EVAL_FILTER_DATE_TO';
export const SET_EVAL_FILTER_SCOPE = 'SET_EVAL_FILTER_SCOPE';
export const SET_EVAL_FILTER_OWNERSHIP = 'SET_EVAL_FILTER_OWNERSHIP';
export const SET_EVAL_FILTER_COMPANIES = 'SET_EVAL_FILTER_COMPANIES';
export const SET_EVAL_FILTER_PHASE = 'SET_EVAL_FILTER_PHASE';
export const SET_EVAL_FILTER_STATUSES = 'SET_EVAL_FILTER_STATUSES';
export const SET_EVAL_FILTER_SUPPLIER_STATUSES = 'SET_EVAL_FILTER_SUPPLIER_STATUSES';
export const SET_EVAL_FILTER_PAGE = 'SET_EVAL_FILTER_PAGE';
export const MANUALLY_REFRESH_EVALUATIONS_LIST = 'MANUALLY_REFRESH_EVALUATIONS_LIST';
export const SET_EVAL_FILTER_TEXT_SEARCH = 'SET_EVAL_FILTER_TEXT_SEARCH';
export const RESET_ALL_EVAL_FILTERS = 'RESET_ALL_EVAL_FILTERS';
export const SET_LOADING_FILTERS = 'SET_LOADING_FILTERS';

export interface PaginatedEvaluationsAction {
  type: typeof SET_PAGINATED_EVALUATIONS;
  data: PaginatedEvaluationsState;
}

export interface EvaluationsFilterAction {
  type: typeof SET_EVALUATION_FILTERS;
  data: EvaluationFiltersState;
}

export interface SelectedEvaluationsFilterAction {
  type: typeof SET_SELECTED_EVALUATION_FILTERS;
  data: SelectedFiltersState;
}

export interface SelectedEvalFilterDateFromAction {
  type: typeof SET_EVAL_FILTER_DATE_FROM;
  data: string | null;
}

export interface SelectedEvalFilterDateToAction {
  type: typeof SET_EVAL_FILTER_DATE_TO;
  data: string | null;
}

export interface SelectedEvalFilterScopeAction {
  type: typeof SET_EVAL_FILTER_SCOPE;
  data: EvaluationTypeGetModel[];
}

export interface SelectedEvalFilterOwnershipAction {
  type: typeof SET_EVAL_FILTER_OWNERSHIP;
  data: IEvaluationOwnersGetModel | null;
}

export interface SelectedEvalFilterCompaniesAction {
  type: typeof SET_EVAL_FILTER_COMPANIES;
  data: FilterCompanyModel[];
}

export interface SelectedEvalFilterPhaseAction {
  type: typeof SET_EVAL_FILTER_PHASE;
  data: IProjectPhaseGetModel[];
}

export interface SelectedEvalFilterStatusesAction {
  type: typeof SET_EVAL_FILTER_STATUSES;
  data: AccordionCheckBoxItem[];
}

export interface SelectedEvalFilterSupplierStatusesAction {
  type: typeof SET_EVAL_FILTER_SUPPLIER_STATUSES;
  data: ISupplierCategoryGetModel[];
}

export interface SelectedEvalFilterPageAction {
  type: typeof SET_EVAL_FILTER_PAGE;
  data: number;
}

export interface SelectedEvalFilterTextSearchAction {
  type: typeof SET_EVAL_FILTER_TEXT_SEARCH;
  data: string;
}

export interface ManuallyResetEvaluationsListAction {
  type: typeof MANUALLY_REFRESH_EVALUATIONS_LIST;
  data: boolean;
}

export interface ResetAllEvaluationsFiltersAction {
  type: typeof RESET_ALL_EVAL_FILTERS;
}

export interface SetLoadingFiltersAction {
  type: typeof SET_LOADING_FILTERS;
  data: boolean;
}

export function setEvaluationFilters(data: EvaluationFiltersState): EvaluationsFilterAction {
  return {
    type: SET_EVALUATION_FILTERS,
    data,
  };
}

export function setPaginatedEvaluations(data: PaginatedEvaluationsState): PaginatedEvaluationsAction {
  return {
    type: SET_PAGINATED_EVALUATIONS,
    data,
  };
}

export function setSelectedEvaluationFilters(data: SelectedFiltersState): SelectedEvaluationsFilterAction {
  return {
    type: SET_SELECTED_EVALUATION_FILTERS,
    data,
  };
}

export function setSelectedEvaluationFilterDateFrom(data: string | null): SelectedEvalFilterDateFromAction {
  return {
    type: SET_EVAL_FILTER_DATE_FROM,
    data,
  };
}

export function setSelectedEvaluationFilterDateTo(data: string | null): SelectedEvalFilterDateToAction {
  return {
    type: SET_EVAL_FILTER_DATE_TO,
    data,
  };
}

export function setSelectedEvaluationFilterScope(data: EvaluationTypeGetModel[]): SelectedEvalFilterScopeAction {
  return {
    type: SET_EVAL_FILTER_SCOPE,
    data,
  };
}

export function setSelectedEvaluationFilterOwnership(data: IEvaluationOwnersGetModel | null): SelectedEvalFilterOwnershipAction {
  return {
    type: SET_EVAL_FILTER_OWNERSHIP,
    data,
  };
}

export function setSelectedEvaluationFilterCompanies(data: FilterCompanyModel[]): SelectedEvalFilterCompaniesAction {
  return {
    type: SET_EVAL_FILTER_COMPANIES,
    data,
  };
}

export function setSelectedEvaluationFilterPhase(data: IProjectPhaseGetModel[]): SelectedEvalFilterPhaseAction {
  return {
    type: SET_EVAL_FILTER_PHASE,
    data,
  };
}

export function setSelectedEvaluationFilterStatuses(data: AccordionCheckBoxItem[]): SelectedEvalFilterStatusesAction {
  return {
    type: SET_EVAL_FILTER_STATUSES,
    data,
  };
}

export function setSelectedEvaluationFilterSupplierStatuses(data: ISupplierCategoryGetModel[]): SelectedEvalFilterSupplierStatusesAction {
  return {
    type: SET_EVAL_FILTER_SUPPLIER_STATUSES,
    data,
  };
}

export function setSelectedEvaluationFilterPage(data: number): SelectedEvalFilterPageAction {
  return {
    type: SET_EVAL_FILTER_PAGE,
    data,
  };
}

export function setSelectedEvaluationFilterSearchText(data: string): SelectedEvalFilterTextSearchAction {
  return {
    type: SET_EVAL_FILTER_TEXT_SEARCH,
    data,
  };
}

export function setManuallyRefreshEvaluationsList(data: boolean): ManuallyResetEvaluationsListAction {
  return {
    type: MANUALLY_REFRESH_EVALUATIONS_LIST,
    data,
  };
}

export function resetAllEvaluationsFilters(): ResetAllEvaluationsFiltersAction {
  return {
    type: RESET_ALL_EVAL_FILTERS,
  };
}

export function setLoadingFilters(data: boolean): SetLoadingFiltersAction {
  return {
    type: SET_LOADING_FILTERS,
    data,
  };
}
