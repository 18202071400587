/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedTypeAction,
  setSelectedCompanyAction,
  setSelectedProjectAction,
  setSelectedProjectPhaseAction,
  setDateFromAction,
  setDateToAction,
  setSelectedSupplierIdsAction,
  setEvaluationSummaryAction,
  setSelectedSuppliersWithCatsAction,
  setSelectedRespondersAction,
} from 'redux/actions/evaluationForm';

import {
  evalFormSelectedTypeSelector,
  selectedCompanySelector,
  evalFormProjectSelector,
  evalFormProjectPhaseSelector,
  evalFormDateFromSelector,
  evalFormDateToSelector,
  evalFormSuppliersIdsSelector,
  evalFormselectedSuppliersWithCatsSelector,
  evalFormEvaluationSummarySelector,
  evalFormSelectedRespondersSelector,
} from 'util/selectors';
import { useApi } from 'hooks/useApi/useApi';
import moment from 'moment';
import EvaluationSetupStep from './Steps/EvaluationSetupStep';
import SupplierSelectStep from './Steps/SupplierSelectStep';
import SelectSupplierCategoryStep from './Steps/SelectSupplierCategoriesStep';
import SelectRecipientsStep from './Steps/SelectRecipientsStep';
import TotalSummaryStep from './Steps/TotalSummaryStep';

interface CreateEvaluationFormProps {
  currentStep: number;
  setDisableNextButton: Function;
}

const CreateEvaluationForm: React.FC<CreateEvaluationFormProps> = ({
  currentStep,
  setDisableNextButton,
}: CreateEvaluationFormProps): JSX.Element | null => {
  const { getSuppliersOfCompanyProjectCall, getSupplierCategoriesForCreateEvalCall } = useApi();
  const dispatch = useDispatch();
  const [allSuppliers, setAllSuppliers] = useState<ISupplierCategoryGetModel[]>([]);
  const [supplierCategories, setSupplierCategories] = useState<ISupplierCategoryGetModel[]>([]);

  // step 1
  const selectedType = useSelector(evalFormSelectedTypeSelector);
  const setSelectedType = (val: IEvaluationTypeGetModel): void => {
    dispatch(setSelectedTypeAction(val));
  };
  const selectedCompany = useSelector(selectedCompanySelector);
  const setSelectedCompany = (val: string): void => {
    dispatch(setSelectedCompanyAction(val));
  };
  const selectedProject = useSelector(evalFormProjectSelector);
  const setSelectedProject = (val: string): void => {
    dispatch(setSelectedProjectAction(val));
  };
  const selectedProjectPhase = useSelector(evalFormProjectPhaseSelector);
  const setSelectedProjectPhase = (val: string): void => {
    dispatch(setSelectedProjectPhaseAction(val));
  };
  const dateFrom = useSelector(evalFormDateFromSelector);
  const setDateFrom = (val: string): void => {
    const startOfDay = moment(val).set({ hour: 0, minute: 0 }).format();
    dispatch(setDateFromAction(startOfDay));
  };
  const dateTo = useSelector(evalFormDateToSelector);
  const setDateTo = (val: string): void => {
    const lastHour = moment(val).set({ hour: 23, minute: 59 }).format();
    dispatch(setDateToAction(lastHour));
  };

  // step 2
  const selectedSuppliersIds = useSelector(evalFormSuppliersIdsSelector);
  const setSelectedSuppliersIds = (val: string[]): void => {
    dispatch(setSelectedSupplierIdsAction(val));
  };
  // step 3
  const selectedSuppliersWithCats = useSelector(evalFormselectedSuppliersWithCatsSelector);
  const setSelectedSuppliersWithCats = (val: ISupplierCategoryPostModel[]): void => {
    dispatch(setSelectedSuppliersWithCatsAction(val));
  };
  // step 4
  const evaluationSummary = useSelector(evalFormEvaluationSummarySelector);
  const setEvaluationSummary = (val: EvaluationSummaryObject): void => {
    dispatch(setEvaluationSummaryAction(val));
  };

  const selectedResponders = useSelector(evalFormSelectedRespondersSelector);
  const setSelectedResponders = (val: EvaluationRecipientObject[]): void => {
    dispatch(setSelectedRespondersAction(val));
  };

  const checkIfAllCatsHaveSupplierIds = (): boolean => {
    let foundMatch = false;
    if (selectedSuppliersIds?.length > 0 && selectedSuppliersWithCats?.length > 0) {
      selectedSuppliersIds.forEach((id) => {
        const matchFound = selectedSuppliersWithCats.find((item) => item?.supplierId === id);
        if (matchFound != null) {
          foundMatch = true;
        } else {
          foundMatch = false;
        }
      });
    }
    return foundMatch;
  };

  useEffect(() => {
    if (currentStep === 1) {
      if (
        selectedType?.id === null ||
        selectedCompany?.length < 1 ||
        selectedProject?.length < 1 ||
        selectedProjectPhase?.length < 1 ||
        dateFrom === null ||
        dateTo === null
      ) {
        setDisableNextButton(true);
      } else if (dateFrom !== null && dateTo !== null) {
        const dateToIsAfterDateFrom = moment(dateTo).isAfter(moment(dateFrom));
        if (!dateToIsAfterDateFrom) {
          setDisableNextButton(true);
        } else {
          setDisableNextButton(false);
        }
      } else {
        setDisableNextButton(false);
      }
    } else if (currentStep === 2) {
      if (selectedSuppliersIds?.length === 0) {
        setDisableNextButton(true);
      } else {
        setDisableNextButton(false);
      }
    } else if (currentStep === 3) {
      const hasAllCatsAType = checkIfAllCatsHaveSupplierIds();
      if (selectedSuppliersWithCats?.length === 0) {
        setDisableNextButton(true);
      } else if (!hasAllCatsAType) {
        setDisableNextButton(true);
      } else {
        setDisableNextButton(false);
      }
    } else if (currentStep === 4) {
      if (selectedResponders?.length === 0) {
        setDisableNextButton(true);
      } else {
        setDisableNextButton(false);
      }
    } else if (currentStep === 5) {
      setDisableNextButton(false);
    }
  }, [
    selectedType,
    selectedCompany,
    selectedProject,
    selectedProjectPhase,
    dateFrom,
    dateTo,
    currentStep,
    selectedSuppliersIds,
    selectedSuppliersWithCats,
    selectedResponders,
  ]);

  useEffect(() => {
    if (
      typeof selectedCompany === 'string' &&
      selectedCompany?.length > 0 &&
      typeof selectedProject === 'string' &&
      selectedProject?.length > 0
    ) {
      //  setLoading(true);
      getSuppliersOfCompanyProjectCall(selectedCompany, selectedProject)
        .then((response) => {
          setAllSuppliers(response);
        })
        .catch((err) => {
          if (typeof err?.response?.data?.detail === 'string') {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error('Klarte ikke å hente leverandører.');
          }
        })
        .finally(() => {
          //  setTimeout(() => setLoading(false), 500);
        });
    }
  }, [selectedCompany, selectedProject]);

  useEffect(() => {
    if (typeof selectedProjectPhase === 'number') {
      getSupplierCategoriesForCreateEvalCall(selectedProjectPhase)
        .then((response) => {
          setSupplierCategories(response);
        })
        .catch((err) => {
          if (typeof err?.response?.data?.detail === 'string') {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error('Klarte ikke å hente kategorier.');
          }
        });
    }
  }, [selectedProjectPhase]);

  const renderCurrentCardContent = (): JSX.Element | null => {
    switch (currentStep) {
      case 1:
        return (
          <div className='evalCardContentWrap'>
            <EvaluationSetupStep
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              selectedProjectPhase={selectedProjectPhase}
              setSelectedProjectPhase={setSelectedProjectPhase}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
              setSelectedSuppliersIds={setSelectedSuppliersIds}
              evaluationSummary={evaluationSummary}
              setEvaluationSummary={setEvaluationSummary}
            />
          </div>
        );
      case 2:
        return (
          <SupplierSelectStep
            selectedSuppliersIds={selectedSuppliersIds}
            setSelectedSuppliersIds={setSelectedSuppliersIds}
            allSuppliers={allSuppliers}
            setSelectedSuppliersWithCats={setSelectedSuppliersWithCats}
            selectedSuppliersWithCats={selectedSuppliersWithCats}
          />
        );
      case 3:
        return (
          <SelectSupplierCategoryStep
            selectedSuppliersIds={selectedSuppliersIds}
            allSuppliers={allSuppliers}
            selectedSuppliersWithCats={selectedSuppliersWithCats}
            setSelectedSuppliersWithCats={setSelectedSuppliersWithCats}
            supplierCategories={supplierCategories}
          />
        );
      case 4:
        return (
          <SelectRecipientsStep
            showDates
            showPhase
            isEditForm={false}
            evaluationSummary={evaluationSummary}
            dateFrom={dateFrom}
            dateTo={dateTo}
            selectedResponders={selectedResponders}
            setSelectedResponders={setSelectedResponders}
          />
        );
      case 5:
        return (
          <TotalSummaryStep
            evaluationSummary={evaluationSummary}
            dateFrom={dateFrom}
            dateTo={dateTo}
            selectedType={selectedType}
            selectedProjectPhase={selectedProjectPhase}
            selectedSuppliersWithCats={selectedSuppliersWithCats}
            allSuppliers={allSuppliers}
            supplierCategories={supplierCategories}
          />
        );
      default:
        return null;
    }
  };

  return <div className='evalCardContentContainer'>{renderCurrentCardContent()}</div>;
};

export default CreateEvaluationForm;
