import { useTheme, ButtonBase, useMediaQuery, Modal, Typography, Box, Button, TextField, Checkbox } from '@mui/material';
import { useState, useEffect } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import HandymanIcon from '@mui/icons-material/Handyman';
import { toast } from 'react-toastify';
import { useApi } from 'hooks/useApi/useApi';
import AddIcon from '@mui/icons-material/Add';
import BackDropLoading from 'components/shared/BackdropLoading';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import {
  projectQuestionsFilters,
  availableSupplierCategoriesSelector,
  availableProjectPhasesSelector,
  supplierQuestionsFilters,
} from 'util/selectors';
import {
  setAllQuestionFilters,
  SetResetProjectQuestionsListManually,
  setAllQuestionFiltersSupplier,
  SetResetSupplierQuestionsListManually,
} from 'redux/actions/questions';
import { Skeleton } from '@mui/lab';
import { projectTypeId, supplierTypeId } from 'util/constants';
import DeleteModal from 'components/shared/DeleteModal';

interface QuestionModalProps {
  open: boolean;
  setOpen: Function;
  activeEvalObj?: QuestionState | null;
  setActiveEvalObj: Function;
  isProject: boolean;
}

interface AutoCompleteCatsModel {
  inputValue?: string;
  label: string | undefined;
  id?: number | string;
}

const QuestionFormModal: React.FC<QuestionModalProps> = ({
  open,
  setOpen,
  activeEvalObj,
  isProject,
  setActiveEvalObj,
}: QuestionModalProps): JSX.Element | null => {
  // div hooks etc
  const theme = useTheme();
  const isMdWidth = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const { createQuestionCategoryCall, getQuestionCategoriesCall, createQuestionCall, updateQuestionCall, deleteQuestionCall } = useApi();

  const avalableQuestionCategories = isProject ? useSelector(projectQuestionsFilters) : useSelector(supplierQuestionsFilters);
  const availableSupplierCategories = useSelector(availableSupplierCategoriesSelector);
  const availableProjectPhases = useSelector(availableProjectPhasesSelector);

  const [questionValue, setQuestionValue] = useState<string>('');

  const [selectedSuppCats, setSelectedSuppCats] = useState<ISupplierCategoryGetModel[]>([]);
  const [selectedProjectPhases, setSelectedProjectPhases] = useState<IProjectPhaseGetModel[]>([]);

  const [remappedAvailableQuestionCats, setRemappedQuestionCats] = useState<AutoCompleteCatsModel[]>([]);
  const [selectedQuestionsCat, setSelectedQuestionCat] = useState<AutoCompleteCatsModel | null>(null);
  const [showAddButton, setShowAddButton] = useState<boolean>(false);
  const [newCategoryName, setNewCategoryName] = useState<string>('');
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [loadingUpdateCats, setLoadingUpdateCats] = useState<boolean>(false);

  // edit form
  const [questionIdToEdit, setQuestionIdToEdit] = useState<number | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);

  const [validationFailed, setValidationFailed] = useState<boolean>(false);

  useEffect(() => {
    // form validation
    if (isProject) {
      if (
        selectedProjectPhases?.length > 0 &&
        typeof selectedQuestionsCat?.id === 'number' &&
        typeof questionValue === 'string' &&
        questionValue?.length > 0
      ) {
        setValidationFailed(false);
      } else {
        setValidationFailed(true);
      }
    } else {
      // supplier control
      if (
        selectedProjectPhases?.length > 0 &&
        typeof selectedQuestionsCat?.id === 'number' &&
        typeof questionValue === 'string' &&
        questionValue?.length > 0
      ) {
        setValidationFailed(false);
      } else {
        setValidationFailed(true);
      }
    }
  }, [selectedProjectPhases, selectedQuestionsCat, selectedSuppCats, questionValue]);

  useEffect(() => {
    if (avalableQuestionCategories?.length > 0) {
      const remappedValues = avalableQuestionCategories.map((item) => {
        return { ...item, label: item?.name };
      });
      setRemappedQuestionCats(remappedValues);
    }
  }, [avalableQuestionCategories]);

  useEffect(() => {
    // preset values if edit form
    if (typeof activeEvalObj?.id === 'number') {
      setQuestionIdToEdit(activeEvalObj.id);
    }
    if (typeof activeEvalObj?.value === 'string') {
      setQuestionValue(activeEvalObj.value);
    }
    if (activeEvalObj?.projectPhase !== undefined && activeEvalObj?.projectPhase?.length > 0) {
      setSelectedProjectPhases(activeEvalObj?.projectPhase);
    }

    if (typeof activeEvalObj?.questionCategory?.id === 'number') {
      const autoSelectobj = {
        id: activeEvalObj?.questionCategory?.id,
        name: activeEvalObj?.questionCategory?.name,
        label: activeEvalObj?.questionCategory?.name,
      };
      setSelectedQuestionCat(autoSelectobj);
    }

    if (activeEvalObj?.supplierCategory !== undefined && activeEvalObj?.supplierCategory?.length > 0) {
      setSelectedSuppCats(activeEvalObj?.supplierCategory);
    }
  }, [activeEvalObj]);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 560,
    bgcolor: theme.palette.text.secondary,
    boxShadow: 24,
  };

  const styleSmall = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: theme.palette.text.secondary,
    boxShadow: 24,
  };

  const resetForm = (): void => {
    setSelectedSuppCats([]);
    setSelectedProjectPhases([]);
    setQuestionValue('');
    setSelectedQuestionCat(null);
    setShowAddButton(false);
  };

  const findEvaluationIcon = (projectTypeId: number | undefined): JSX.Element | null => {
    if (projectTypeId === 1) {
      return <HandymanIcon fontSize='small' htmlColor={theme.palette.text.secondary} />;
    } else if (projectTypeId === 2) {
      return <SettingsIcon fontSize='small' htmlColor={theme.palette.text.secondary} />;
    } else {
      return null;
    }
  };

  const handleClose = (): void => {
    setOpen(false);
    resetForm();
    setActiveEvalObj(null);
  };

  const handleDelete = (): void => {
    setShowDeleteModal(true);
  };

  const fetchNewCategories = async (): Promise<void> => {
    setLoadingUpdateCats(true);
    getQuestionCategoriesCall()
      .then((response) => {
        if (isProject) {
          dispatch(setAllQuestionFilters(response));
        } else {
          dispatch(setAllQuestionFiltersSupplier(response));
        }
      })
      .catch((err) => {
        if (typeof err === 'string') {
          toast.error(err);
        } else {
          toast.error('Klarte ikke å hente oppdatert liste av spørsmål filtere.');
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoadingUpdateCats(false);
        }, 500);
      });
  };

  const handleAddNewCatory = async (): Promise<void> => {
    setLoadingCreate(true);
    createQuestionCategoryCall(newCategoryName)
      .then(async () => {
        setShowAddButton(false);
        await fetchNewCategories();
        toast.success('Kategori ble opprettet.');
      })
      .catch((err) => {
        if (typeof err?.response?.data?.detail === 'string') {
          toast.error(err?.response?.data?.detail);
        } else {
          toast.error('Klarte ikke å opprette spørsmålkategori');
        }
      })
      .finally(() => {
        setTimeout(() => setLoadingCreate(false), 500);
      });
  };

  const renderFormHeader = (): JSX.Element | null => {
    return (
      <div className='evaluationTitleModal'>
        <div></div>
        <div className='iconAndTitle'>
          <div className='evaluationIconContainer'>{findEvaluationIcon(isProject ? projectTypeId : supplierTypeId)}</div>
          <Typography
            variant='body1'
            align='center'
            color='text.secondary'
            sx={{ textTransform: 'uppercase', fontSize: '0.9rem', padding: 0, margin: 0, lineHeight: '30px' }}
          >
            {activeEvalObj !== null && activeEvalObj !== undefined ? 'Rediger spørsmål' : 'Legg til spørsmål'}
          </Typography>
        </div>

        <ButtonBase onClick={() => handleClose()}>
          <div className='evalModalCloseButton'>
            <CloseIcon fontSize='medium' htmlColor={theme.palette.text.secondary} />
          </div>
        </ButtonBase>
      </div>
    );
  };

  const handleQValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setQuestionValue(event.target.value);
  };

  const handleCreateQuestion = (): void => {
    const params = {
      value: questionValue,
      evaluationTypeId: isProject ? projectTypeId : supplierTypeId,
      questionCategoryId: selectedQuestionsCat?.id,
      questionProjectPhaseIds: selectedProjectPhases.map((item) => item?.id),
      questionSupplierCategoryIds: selectedSuppCats.map((item) => item?.id),
    };

    const projectParams = {
      value: questionValue,
      evaluationTypeId: isProject ? projectTypeId : supplierTypeId,
      questionCategoryId: selectedQuestionsCat?.id,
      questionProjectPhaseIds: selectedProjectPhases.map((item) => item?.id),
    };
    setLoadingCreate(true);
    createQuestionCall(isProject ? projectParams : params)
      .then(() => {
        handleClose();
        if (isProject) {
          dispatch(SetResetProjectQuestionsListManually(true));
        } else {
          dispatch(SetResetSupplierQuestionsListManually(true));
        }
      })
      .catch((err) => {
        if (typeof err === 'string') {
          toast.error(err);
        } else {
          toast.error('Klarte ikke å opprette spørsmål.');
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoadingCreate(false);
        }, 500);
      });
  };

  const handleEditQuestion = (): void => {
    setLoadingCreate(true);
    if (typeof questionIdToEdit === 'number') {
      const projectParams = {
        questionCategoryId: selectedQuestionsCat?.id,
        isActive: activeEvalObj?.isActive === true ? activeEvalObj.isActive : false,
        questionSupplierCategoryIds: selectedSuppCats.map((item) => item.id),
        questionProjectPhaseIds: selectedProjectPhases.map((item) => item.id),
        selectedQuestionsCat: selectedQuestionsCat?.id,
      };

      updateQuestionCall(projectParams, questionIdToEdit)
        .then(() => {
          setTimeout(() => {
            setLoadingCreate(false);
          }, 500);
          handleClose();
          toast.success('Spørsmål ble oppdatert.');
          if (isProject) {
            dispatch(SetResetProjectQuestionsListManually(true));
          } else {
            dispatch(SetResetSupplierQuestionsListManually(true));
          }
        })
        .catch((err) => {
          if (typeof err?.response?.data?.detail === 'string') {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error('Klarte ikke å oppdatere spørsmål.');
          }
        })
        .finally(() => {
          setTimeout(() => {
            setLoadingCreate(false);
          }, 500);
        });
    }
  };

  const deleteAction = (): void => {
    if (activeEvalObj?.id !== null) {
      setLoadingDelete(true);
      deleteQuestionCall(activeEvalObj?.id.toString())
        .then(() => {
          toast.success('Spørsmålet ble fjernet.');
          setShowDeleteModal(false);
          handleClose();

          if (isProject) {
            dispatch(SetResetProjectQuestionsListManually(true));
          } else {
            dispatch(SetResetSupplierQuestionsListManually(true));
          }
        })
        .catch((err) => {
          if (typeof err?.response?.data?.detail === 'string') {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error('Klarte ikke å slette spørsmålet.');
          }
        })
        .finally(() => {
          setTimeout(() => setLoadingDelete(false), 500);
        });
    }
  };

  const renderFormContent = (): JSX.Element | null => {
    const filter = createFilterOptions<AutoCompleteCatsModel>();

    const _renderCheckBoxes = (
      arrayList: QuestionCategory[],
      setSelectedState: Function,
      selectedState: QuestionCategory[],
    ): JSX.Element[] | null => {
      if (arrayList === undefined || arrayList?.length === 0) {
        return null;
      }

      const _distinctValues = [...selectedState];
      _distinctValues.push(...arrayList.filter((item) => !_distinctValues.some((value) => value.id === item.id)));
      const _sortedDistinctValues = _distinctValues.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      });

      return _sortedDistinctValues.map((item: QuestionCategory) => {
        const _isValueMarked = (idToFind: number): boolean => {
          const foundItem = selectedState.find((itemSearch: QuestionCategory) => itemSearch?.id === idToFind);
          if (typeof foundItem !== 'undefined') {
            return true;
          }
          return false;
        };

        const _handleClick = (idClicked: number): void => {
          const foundItem = selectedState.find((itemSearch: QuestionCategory) => itemSearch?.id === idClicked);
          if (typeof foundItem !== 'undefined') {
            // item needs to be filtered out
            const newList = selectedState.filter((itemSearch: QuestionCategory) => itemSearch?.id !== idClicked);
            setSelectedState(newList);
          } else {
            const itemToAdd = arrayList.find((itemFromAll) => itemFromAll.id === idClicked);
            if (typeof itemToAdd !== 'undefined') {
              setSelectedState([...selectedState, itemToAdd]);
            }
          }
        };

        return (
          <ButtonBase key={item?.id} className='checkBoxItem' onClick={() => _handleClick(item.id)}>
            <Checkbox
              checked={_isValueMarked(item.id)}
              value={item.id}
              sx={{
                color: theme?.palette?.primary?.main,
              }}
            />
            <Typography sx={{ padding: 0, margin: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              {item?.name}
            </Typography>
          </ButtonBase>
        );
      });
    };

    return (
      <div>
        <BackDropLoading open={loadingCreate} />
        <Typography sx={{ textDecoration: 'underline', fontSize: '1.1rem', fontWeight: 'bold' }}>Spørsmålstekst*</Typography>
        <TextField
          id='question_value'
          label='Spørsmål'
          type='text'
          variant='standard'
          className='qValueInput'
          value={questionValue}
          onChange={handleQValueChange}
          disabled={typeof activeEvalObj?.id === 'number'}
        />
        <div className='questionCreateModalContainer'>
          <div className={isMdWidth ? 'topQWrapper' : 'topQWrapperMobile'}>
            <div className='topLeftContainer'>
              <div className='questionFormPhasePickContainer'>
                <Typography sx={{ textDecoration: 'underline', fontSize: '1.1rem', fontWeight: 'bold' }}>
                  Kobles til prosjektfase*
                </Typography>
              </div>
              <div className='questionModalTopBoxContainer'>
                {_renderCheckBoxes(availableProjectPhases, setSelectedProjectPhases, selectedProjectPhases)}
              </div>
            </div>

            {!isProject ? (
              <div className='topRightContainer'>
                <div className='questionFormPhasePickContainer'>
                  <Typography sx={{ textDecoration: 'underline', fontSize: '1.1rem', fontWeight: 'bold' }}>
                    Kobles til leverandørtype*
                  </Typography>
                </div>
                <div className='questionModalTopBoxContainer'>
                  {_renderCheckBoxes(availableSupplierCategories, setSelectedSuppCats, selectedSuppCats)}
                </div>
              </div>
            ) : null}
          </div>
          <div className='bottomQModalContainer'>
            <Typography sx={{ textDecoration: 'underline', fontSize: '1.1rem', fontWeight: 'bold' }}>Kategori*</Typography>
            {loadingUpdateCats ? (
              <Skeleton variant='rectangular' height={48} sx={{ width: '50%' }} />
            ) : (
              <Autocomplete
                value={selectedQuestionsCat}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (typeof option?.inputValue === 'string') {
                    return option.inputValue;
                  }
                  if (typeof option?.label === 'string') {
                    return option.label;
                  }
                  return '';
                }}
                onChange={(event, newValue: any) => {
                  if (typeof newValue?.inputValue === 'string') {
                    // add a new value to API
                    setNewCategoryName(newValue.inputValue);
                    setShowAddButton(true);
                  } else {
                    // select existing value
                    setShowAddButton(false);

                    setSelectedQuestionCat(newValue);
                    setNewCategoryName('');
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.label);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      label: `Legg in "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id='create-q-category'
                options={remappedAvailableQuestionCats}
                renderOption={(props, option) => <li {...props}>{option?.label}</li>}
                freeSolo
                sx={{ width: '50%' }}
                renderInput={(params) => <TextField {...params} label='Velg kategori' variant='standard' />}
              />
            )}

            {showAddButton && newCategoryName?.length > 0 ? (
              <ButtonBase
                className='addCatButtonStyle'
                onClick={() => {
                  void (async () => {
                    await handleAddNewCatory();
                  })();
                }}
              >
                <div className='addQuestionTitleAndIconContainer'>
                  <AddIcon fontSize='small' sx={{ fontSize: '14px' }} />
                  {`Legg til "${newCategoryName}" `}
                </div>
              </ButtonBase>
            ) : null}
          </div>
        </div>
        <div className='questionCreateButtonContainer'>
          <div>
            <Button variant='outlined' onClick={typeof activeEvalObj?.id === 'number' ? () => handleDelete() : () => handleClose()}>
              {typeof activeEvalObj?.id === 'number' ? 'Slett spørsmål' : 'Avbryt'}
            </Button>
            <Button
              onClick={typeof activeEvalObj?.id === 'number' ? () => handleEditQuestion() : () => handleCreateQuestion()}
              variant='contained'
              className='saveButtonStyle'
              disabled={validationFailed}
            >
              {typeof activeEvalObj?.id === 'number' ? 'Lagre endringene' : 'Lagre'}
            </Button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <DeleteModal
        loadingDelete={loadingDelete}
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onDeleteAction={deleteAction}
        titleString='Slett spørsmål'
        questionString='Er du sikker på at du ønsker å slette spørsmålet?'
      />
      <Modal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box sx={isMdWidth ? style : styleSmall}>
          <div className='questModalContainer'>
            {renderFormHeader()}
            <div className='modalContentWrapperQ'>{renderFormContent()}</div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default QuestionFormModal;
