import { Typography, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'hooks/useApi/useApi';
import RenderEvaluationSummary from '../RenderEvaluationSummary';
import '../../styles.scss';

interface TotalSummaryStepProps {
  evaluationSummary?: EvaluationSummaryObject;
  dateFrom: string | null;
  dateTo: string | null;
  selectedType: IEvaluationTypeGetModel | undefined;
  selectedProjectPhase: string;
  selectedSuppliersWithCats: ISupplierCategoryPostModel[];
  allSuppliers: ISupplierCategoryGetModel[];
  supplierCategories?: ISupplierCategoryGetModel[];
  editId?: number;
}

const TotalSummaryStep: React.FC<TotalSummaryStepProps> = ({
  evaluationSummary,
  dateFrom,
  dateTo,
  selectedType,
  selectedProjectPhase,
  selectedSuppliersWithCats,
  allSuppliers,
  supplierCategories,
  editId,
}: TotalSummaryStepProps): JSX.Element | null => {
  const [loadingQuestions, setLoadingQuestions] = useState<boolean>(false);
  const [allQuestions, setAllQuestions] = useState<EvaluationQuestionObject[]>([]);
  const [supplierTypes, setSupplierTypes] = useState<string[]>([]);

  const { getEvaluationQuestionsCall, getEvaluationQuestionsCallEdit } = useApi();
  const theme = useTheme();
  const isMdWidth = useMediaQuery(theme.breakpoints.up('md'));

  const getCategoryQuestions = (supplierCategoryId: any): void => {
    if (
      typeof selectedType?.id !== 'undefined' &&
      selectedType?.id > 0 &&
      typeof selectedProjectPhase !== 'undefined' &&
      supplierCategoryId?.supplierCategoryId?.length > 0
    ) {
      setLoadingQuestions(true);

      if (typeof editId === 'number') {
        getEvaluationQuestionsCallEdit(editId)
          .then((response) => {
            setAllQuestions(response);
          })
          .catch((err) => {
            if (typeof err?.response?.data?.detail === 'string') {
              toast.error(err?.response?.data?.detail);
            } else {
              toast.error('Klarte ikke å hente spørsmål.');
            }
          })
          .finally(() => {
            setLoadingQuestions(false);
          });
      } else {
        const selectedTypeString = selectedType?.id.toString();

        getEvaluationQuestionsCall(selectedTypeString, selectedProjectPhase, supplierCategoryId)
          .then((response) => {
            setAllQuestions(response);
          })
          .catch((err) => {
            if (typeof err?.response?.data?.detail === 'string') {
              toast.error(err?.response?.data?.detail);
            } else {
              toast.error('Klarte ikke å hente spørsmål.');
            }
          })
          .finally(() => {
            setLoadingQuestions(false);
          });
      }
    }
  };

  useEffect(() => {
    if (selectedSuppliersWithCats?.length > 0) {
      const findAllCatIds = selectedSuppliersWithCats.reduce((accumulator: string[], currentItem: ISupplierCategoryPostModel) => {
        if (!accumulator.includes(currentItem.supplierCategoryId.toString())) {
          return accumulator.concat(currentItem.supplierCategoryId.toString());
        } else {
          return accumulator;
        }
      }, []);
      setSupplierTypes(findAllCatIds);
    }
  }, [selectedSuppliersWithCats]);

  useEffect(() => {
    if (supplierTypes?.length > 0) {
      const supplierCategoryId = { supplierCategoryId: supplierTypes };
      getCategoryQuestions(supplierCategoryId);
    }
  }, [supplierTypes]);

  const findSupplierName = (supplierId: string): string | null => {
    if (typeof supplierId !== 'undefined' && allSuppliers?.length > 0) {
      const matchFound = allSuppliers.find((supplier) => supplier?.id.toString() === supplierId);
      if (typeof matchFound !== 'undefined') {
        return matchFound?.name;
      } else {
        return null;
      }
    }
    return null;
  };

  const findQuestionsOfSupplierCategory = (categoryId: number): JSX.Element | null => {
    if (allQuestions?.length > 0 && typeof categoryId !== 'undefined') {
      const filteredCatQuestions = allQuestions.filter((catItem) => {
        const hasQuestionChosenCategory = catItem?.supplierCategory.filter((item: any) => {
          if (item.id === categoryId) {
            return true;
          } else {
            return false;
          }
        });
        if (hasQuestionChosenCategory !== null && hasQuestionChosenCategory?.length > 0) {
          return true;
        } else {
          return false;
        }
      });
      if (filteredCatQuestions?.length > 0) {
        return (
          <ul className=''>
            {filteredCatQuestions.map((item) => {
              return (
                <li key={item.id}>
                  <div>
                    <Typography sx={{ fontSize: '14px', lineHeight: '26px', letterSpacing: '0.46px' }}>{item.value}</Typography>
                  </div>
                </li>
              );
            })}
          </ul>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  const findSuppCatName = (catId: string): JSX.Element | null => {
    if (catId?.length > 0 && supplierCategories !== undefined && supplierCategories?.length > 0) {
      const matchFound = supplierCategories?.find((item) => item?.id.toString() === catId);
      if (matchFound !== undefined) {
        return (
          <Typography sx={{ paddingLeft: '5px', lineHeight: '26px', letterSpacing: '0.46px', textTransform: 'uppercase' }}>
            ({matchFound?.name})
          </Typography>
        );
      }
      return null;
    }
    return null;
  };

  const renderStepContent = (): JSX.Element | null => {
    if (loadingQuestions) {
      return <Skeleton variant='rectangular' height={210} />;
    } else if (selectedSuppliersWithCats?.length > 0) {
      return (
        <div className={isMdWidth ? 'questionsListContainer' : 'questionsListContainerMobile'}>
          {selectedSuppliersWithCats.map((supplierItem) => {
            return (
              <div key={`${supplierItem?.supplierCategoryId}-${supplierItem?.supplierId}`}>
                <div className='questionListSupplierNameContainer'>
                  <Typography sx={{ lineHeight: '26px', letterSpacing: '0.46px', textTransform: 'uppercase' }}>
                    {findSupplierName(supplierItem?.supplierId)}
                  </Typography>
                  {findSuppCatName(supplierItem?.supplierCategoryId.toString())}
                </div>
                <div>{findQuestionsOfSupplierCategory(supplierItem?.supplierCategoryId)}</div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className='evalRecipientsSummary'>
      <RenderEvaluationSummary showDates showPhase evaluationSummary={evaluationSummary} dateFrom={dateFrom} dateTo={dateTo} />
      <Typography
        sx={{
          marginBottom: 0,
          fontWeight: 700,
          letterSpacing: '0.46px',
          lineHeight: '26px',
          marginTop: '15px',
        }}
      >
        Evalueringen inneholder:
      </Typography>
      {renderStepContent()}
    </div>
  );
};

export default TotalSummaryStep;
