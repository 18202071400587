import { Box, Container, Typography, useTheme } from '@mui/material';
import React from 'react';
import ErrorBoundary from './ErrorBoundary';
import PageHeader from './PageHeader';
import SidebarMenu from './SidebarMenu';
import ErrorIcon from '@mui/icons-material/Error';
import { AuthenticatedTemplate } from '@azure/msal-react';

interface PageLayoutProps {
  children?: React.ReactNode;
  hideSidebar: boolean;
}

export const PageLayout = ({ children, hideSidebar }: PageLayoutProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Container
      maxWidth={false}
      sx={{
        px: { xs: 0 },
        display: 'grid',
        gridTemplateColumns: { xs: '0 1fr 0', lg: '200px 1fr 200px' },
        gridTemplateRows: 'min-content 1fr',
        gap: '0px 0px',
        backgroundColor: theme.palette.primary.main,
      }}
    >
      {/* Topbar */}
      <Box
        sx={{
          gridArea: { xs: '1 / 1 / 1 / 4', lg: '1 / 1 / 2 / 3' },
          pl: { xs: 1, lg: 2 },
          pr: { xs: 1, lg: 0 },
        }}
      >
        <PageHeader />
      </Box>

      {/* Sidebar */}
      {!hideSidebar ? (
        <Box
          sx={{
            gridArea: '2 / 1 / 3 / 2',
            display: { xs: 'none', lg: 'block' },
            px: 2,
          }}
        >
          <AuthenticatedTemplate>
            <SidebarMenu />
          </AuthenticatedTemplate>
        </Box>
      ) : null}

      {/* Padding left */}
      <Box
        sx={{
          gridArea: '1 / 1 / 3 / 2',
          display: { xs: 'none', lg: 'block' },
        }}
      ></Box>

      {/* Padding right */}
      <Box
        sx={{
          gridArea: '1 / 3 / 3 / 4',
          display: { xs: 'none', xl: 'block' },
        }}
      ></Box>

      {/* Content */}
      <Box
        sx={{
          gridArea: { xs: '2 / 1 / 2 / 4', lg: '2 / 2 / 3 / 3' },
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box
          sx={{
            px: { xs: 1, md: 2 },
            py: { xs: 3, md: 7 },
          }}
        >
          <ErrorBoundary
            component={
              <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <ErrorIcon sx={{ mr: 1 }} fontSize='small' htmlColor={theme.palette.error.main} />
                <Typography variant='body1' color='text.primary'>
                  Noe gikk galt...
                </Typography>
              </Box>
            }
          >
            {children}
          </ErrorBoundary>
        </Box>
      </Box>
    </Container>
  );
};

export default PageLayout;
