import { useTheme, ButtonBase, useMediaQuery, Modal, Typography, Box, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import HandymanIcon from '@mui/icons-material/Handyman';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import {
  evaluationFiltersSelector,
  evalFormSelectedTypeSelector,
  selectedCompanySelector,
  evalFormProjectSelector,
  evalFormProjectPhaseSelector,
  evalFormDateFromSelector,
  evalFormDateToSelector,
  evalFormselectedSuppliersWithCatsSelector,
  evalFormSelectedRespondersSelector,
  evalFormAllAvailableRecipientsSelector,
} from 'util/selectors';
import {
  setSelectedTypeAction,
  resetEvaluationFormAction,
  setSelectedSuppliersWithCatsAction,
  setSelectedRespondersAction,
} from 'redux/actions/evaluationForm';

import EditEvaluationForm from './EditEvaluationForm';
import CreateSupplierEvaluationForm from './CreateSupplierEvaluationForm';
import CreateProjectEvaluationForm from './CreateProjectEvaluationForm';
import { useApi } from 'hooks/useApi/useApi';
import { toast } from 'react-toastify';
import { setManuallyRefreshEvaluationsList } from 'redux/actions/evaluations';
import DeleteModal from 'components/shared/DeleteModal';

interface EvaluationModalProps {
  open: boolean;
  setOpen: Function;
  activeEvalObj?: EvaluationsState | null;
  setActiveEvalObj: Function;
  currentStep: number;
  setCurrentStep: Function;
}

const EvaluationFormModal: React.FC<EvaluationModalProps> = ({
  open,
  setOpen,
  activeEvalObj,
  setActiveEvalObj,
  currentStep,
  setCurrentStep,
}: EvaluationModalProps): JSX.Element | null => {
  // all filters data
  const evaluationFilters = useSelector(evaluationFiltersSelector);
  // div hooks etc
  const theme = useTheme();
  const isMdWidth = useMediaQuery(theme.breakpoints.up('md'));
  const isSmWidth = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch();
  const { evaluationTypes } = evaluationFilters;
  const { deleteEvaluationCall, createEvaluationCall, updateEvaluationCall } = useApi();
  // local state
  const [selectedEvalType, setSelectedEvalType] = useState<{ id?: number; name?: string } | null>(null);
  const [totalSteps, setTotalSteps] = useState<number>(5);
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [showSelectAllRecipientsButton, setShowSelectAllRecipientsButton] = useState<boolean>(false);

  const handleSetManuallyRefreshEvalList = (val: boolean): void => {
    dispatch(setManuallyRefreshEvaluationsList(val));
  };

  const setSelectedSuppliersWithCats = (val: ISupplierCategoryPostModel[]): void => {
    dispatch(setSelectedSuppliersWithCatsAction(val));
  };

  // evalution form values
  const selectedType = useSelector(evalFormSelectedTypeSelector);
  const selectedCompany = useSelector(selectedCompanySelector);
  const selectedProject = useSelector(evalFormProjectSelector);
  const selectedProjectPhase = useSelector(evalFormProjectPhaseSelector);
  const dateFrom = useSelector(evalFormDateFromSelector);
  const dateTo = useSelector(evalFormDateToSelector);
  const selectedSuppliersWithCats = useSelector(evalFormselectedSuppliersWithCatsSelector);
  const selectedResponders = useSelector(evalFormSelectedRespondersSelector);
  const allAvailableRecipients = useSelector(evalFormAllAvailableRecipientsSelector);

  const setSelectedType = (val: IEvaluationTypeGetModel): void => {
    dispatch(setSelectedTypeAction(val));
  };

  const handleClose = (): void => {
    setOpen(false);
    // reset all values from evaluation form
    setActiveEvalObj(null);
    dispatch(resetEvaluationFormAction());
    setCurrentStep(1);
  };

  useEffect(() => {
    if (selectedEvalType === null && evaluationTypes != null && evaluationTypes?.length > 0) {
      setSelectedEvalType(evaluationTypes[0]);
    }
  }, [evaluationTypes]);

  useEffect(() => {
    // if project type is selected - there cannot be selectedSuppliersWithCats
    if (selectedSuppliersWithCats?.length > 0 && selectedType?.id === 2) {
      setSelectedSuppliersWithCats([]);
    }
  }, [selectedSuppliersWithCats, selectedType]);

  useEffect(() => {
    // edit form and new project forms has 3 steps
    if (activeEvalObj !== null || selectedType?.id === 2) {
      setTotalSteps(3);
    } else {
      // new form for Supplier evaluation has 5 steps
      setTotalSteps(5);
    }
  }, [activeEvalObj, selectedType]);

  // preset evaluation type if it is undefined
  useEffect(() => {
    if (evaluationTypes !== undefined && evaluationTypes?.length > 0 && typeof selectedType === 'undefined') {
      setSelectedType(evaluationTypes[0]);
    }
  }, [selectedType, evaluationTypes]);

  useEffect(() => {
    // set state to show or hide select all recipients button
    if (typeof activeEvalObj?.id === 'number') {
      //  this means edit
      if (currentStep === 2) {
        setShowSelectAllRecipientsButton(true);
      } else {
        setShowSelectAllRecipientsButton(false);
      }
    } else {
      // this mean create new
      if (selectedType?.id === 2 && currentStep === 2) {
        // project type
        setShowSelectAllRecipientsButton(true);
      } else if (selectedType?.id === 1 && currentStep === 4) {
        // evaluation type
        setShowSelectAllRecipientsButton(true);
      } else {
        setShowSelectAllRecipientsButton(false);
      }
    }
  }, [activeEvalObj, currentStep, selectedType]);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 560,
    bgcolor: theme.palette.text.secondary,
    boxShadow: 24,
  };

  const styleSmall = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: theme.palette.text.secondary,
    boxShadow: 24,
  };

  const findEvaluationIcon = (projectTypeId: number | undefined): JSX.Element | null => {
    if (projectTypeId === 1) {
      return <HandymanIcon fontSize='small' htmlColor={theme.palette.text.secondary} />;
    } else if (projectTypeId === 2) {
      return <SettingsIcon fontSize='small' htmlColor={theme.palette.text.secondary} />;
    } else {
      return null;
    }
  };

  const renderFormHeader = (): JSX.Element | null => {
    return (
      <div className='evaluationTitleModal'>
        <div></div>
        <div className='iconAndTitle'>
          <div className='evaluationIconContainer'>{findEvaluationIcon(selectedEvalType?.id)}</div>
          <Typography
            variant='body1'
            align='center'
            color='text.secondary'
            sx={{ textTransform: 'uppercase', fontSize: '0.9rem', padding: 0, margin: 0, lineHeight: '30px' }}
          >
            {activeEvalObj !== null ? 'Rediger evaluering' : 'Opprett ny evaluering'}
          </Typography>
        </div>

        <ButtonBase onClick={() => handleClose()}>
          <div className='evalModalCloseButton'>
            <CloseIcon fontSize='medium' htmlColor={theme.palette.text.secondary} />
          </div>
        </ButtonBase>
      </div>
    );
  };

  const renderStepIndicator = (): JSX.Element | null => {
    // render more steps if it is a new evaluation
    const handleClickOnPreviousStep = (clickedIndex: number): void => {
      const clickedStepperNumber = clickedIndex + 1;
      if (clickedStepperNumber < currentStep) {
        setCurrentStep(clickedStepperNumber);
      }
    };

    return (
      <div className='stepperIndicatorContainer'>
        {[...Array(totalSteps)].map((_, i) => {
          const isDotMarked = i + 1 <= currentStep;
          return (
            <ButtonBase key={i} className='stepperIconContainer' onClick={() => handleClickOnPreviousStep(i)}>
              {isDotMarked ? (
                <CircleIcon fontSize='small' htmlColor={theme.palette.text.primary} sx={{ p: '2px' }} />
              ) : (
                <CircleOutlinedIcon fontSize='small' htmlColor={theme.palette.text.primary} sx={{ p: '2px' }} />
              )}
            </ButtonBase>
          );
        })}
      </div>
    );
  };

  const createNewEvaluationForm = (): void => {
    setDisableNextButton(true);
    const payload = {
      evaluationTypeId: selectedType?.id,
      clientId: selectedCompany,
      projectId: selectedProject,
      projectPhaseId: selectedProjectPhase,
      evaluationStart: dateFrom,
      evaluationEnd: dateTo,
      suppliers: selectedSuppliersWithCats,
      responders: selectedResponders,
    };
    createEvaluationCall(payload)
      .then(() => {
        toast.success('Evaluering ble opprettet.');
        handleClose();
        // fetch fresh data
        handleSetManuallyRefreshEvalList(true);
        // dispatch(setSelectedEvaluationFilterPage(1));
      })
      .catch((err) => {
        if (typeof err?.response?.data?.detail === 'string') {
          toast.error(err?.response?.data?.detail);
        } else {
          toast.error('Klarte ikke å opprette evalueringen.');
        }
      })
      .finally(() => {
        setTimeout(() => setDisableNextButton(false), 500);
      });
  };

  const handleUpdateEvaluation = (): void => {
    if (activeEvalObj?.id !== null) {
      setDisableNextButton(true);
      const payload = {
        evaluationEnd: dateTo,
        responders: selectedResponders,
      };
      updateEvaluationCall(payload, activeEvalObj?.id)
        .then(() => {
          toast.success('Evaluering ble oppdatert.');
          handleClose();
          // fetch fresh data
          handleSetManuallyRefreshEvalList(true);
        })
        .catch((err) => {
          if (typeof err?.response?.data?.detail === 'string') {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error('Klarte ikke å oppdatere evalueringen.');
          }
        })
        .finally(() => {
          setTimeout(() => setDisableNextButton(false), 500);
        });
    }
  };

  const handleNextClick = (): void => {
    const nextValue = currentStep + 1;
    if (activeEvalObj !== null) {
      if (nextValue <= totalSteps) {
        setCurrentStep(nextValue);
      } else {
        handleUpdateEvaluation();
      }
    } else {
      // create new form
      if (nextValue <= totalSteps) {
        setCurrentStep(nextValue);
      } else {
        createNewEvaluationForm();
      }
    }
  };

  const handlePreviousClick = (): void => {
    const nextValue = currentStep - 1;
    if (nextValue >= 1) {
      setCurrentStep(nextValue);
    }
  };

  const handleDelete = (): void => {
    setShowDeleteModal(true);
  };

  const deleteAction = (): void => {
    if (activeEvalObj?.id !== null) {
      setLoadingDelete(true);
      deleteEvaluationCall(activeEvalObj?.id.toString())
        .then(() => {
          toast.success('Evaluering ble fjernet.');
          setShowDeleteModal(false);
          handleClose();
          // fetch fresh data
          handleSetManuallyRefreshEvalList(true);
        })
        .catch((err) => {
          if (typeof err?.response?.data?.detail === 'string') {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error('Klarte ikke å slette evalueringen.');
          }
        })
        .finally(() => {
          setTimeout(() => setLoadingDelete(false), 500);
        });
    }
  };

  const handleSelectAllReciepients = (): void => {
    if (allAvailableRecipients?.length > 0) {
      dispatch(setSelectedRespondersAction(allAvailableRecipients));
    }
  };

  const renderStepperButtons = (): JSX.Element | null => {
    // render more steps if it is a new evaluation
    const findTitleForFinalStep = (): string => {
      return activeEvalObj !== null ? 'LAGRE' : 'OPPRETT';
    };

    const renderBackButton = (): JSX.Element | null => {
      if (activeEvalObj !== null && currentStep === 1) {
        return (
          <Button variant='outlined' onClick={() => handleDelete()}>
            {'SLETT EVALUERING'}
          </Button>
        );
      } else if (activeEvalObj !== null) {
        return (
          <Button variant='outlined' onClick={() => handlePreviousClick()}>
            {'TILBAKE'}
          </Button>
        );
      } else if (activeEvalObj === null && currentStep !== 1) {
        return (
          <Button variant='outlined' onClick={() => handlePreviousClick()}>
            {'TILBAKE'}
          </Button>
        );
      } else {
        return null;
      }
    };

    return (
      <div className={isSmWidth ? 'stepperButtonsContainer' : 'stepperButtonsContainerMobile'}>
        {showSelectAllRecipientsButton ? (
          <div className='select-all-button'>
            <Button
              disabled={allAvailableRecipients?.length === 0}
              variant='contained'
              color='primary'
              onClick={() => handleSelectAllReciepients()}
            >
              Velg alle mottakere
            </Button>
          </div>
        ) : (
          <div />
        )}
        <div className='right-side-buttons'>
          <div className='stepperBackButtonContainer'>{renderBackButton()}</div>
          <Button variant='contained' color='primary' onClick={() => handleNextClick()} disabled={disableNextButton}>
            {currentStep === totalSteps ? findTitleForFinalStep() : 'NESTE'}
          </Button>
        </div>
      </div>
    );
  };

  const renderFormModalContent = (): JSX.Element | null => {
    if (activeEvalObj === null && selectedType?.id === 2) {
      return <CreateProjectEvaluationForm currentStep={currentStep} setDisableNextButton={setDisableNextButton} />;
    } else if (activeEvalObj === null && selectedType?.id === 1) {
      return <CreateSupplierEvaluationForm currentStep={currentStep} setDisableNextButton={setDisableNextButton} />;
    } else if (activeEvalObj !== null) {
      return <EditEvaluationForm activeEvalObj={activeEvalObj} currentStep={currentStep} setDisableNextButton={setDisableNextButton} />;
    } else {
      return null;
    }
  };

  return (
    <div>
      <DeleteModal
        loadingDelete={loadingDelete}
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        onDeleteAction={deleteAction}
        titleString='Slett evaluering'
        questionString='Er du sikker på at du ønsker å slette denne evalueringen?'
      />
      <Modal open={open} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box sx={isMdWidth ? style : styleSmall}>
          <div className='evalModalContainer'>
            {renderFormHeader()}
            <div className='modalContentWrapper'>
              <div className='evalModalContentContainer'>{renderStepIndicator()}</div>
              <div className='evalFormModalContentContainer'>{renderFormModalContent()}</div>
              <div className='evalModalButtonContainer'>{renderStepperButtons()}</div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EvaluationFormModal;
