import {
  SET_EVALUATION_FILTERS,
  SET_PAGINATED_EVALUATIONS,
  SET_SELECTED_EVALUATION_FILTERS,
  SET_EVAL_FILTER_DATE_FROM,
  SET_EVAL_FILTER_DATE_TO,
  SET_EVAL_FILTER_SCOPE,
  SET_EVAL_FILTER_OWNERSHIP,
  SET_EVAL_FILTER_COMPANIES,
  SET_EVAL_FILTER_PHASE,
  SET_EVAL_FILTER_STATUSES,
  SET_EVAL_FILTER_SUPPLIER_STATUSES,
  SET_EVAL_FILTER_PAGE,
  MANUALLY_REFRESH_EVALUATIONS_LIST,
  SET_EVAL_FILTER_TEXT_SEARCH,
  RESET_ALL_EVAL_FILTERS,
  SET_LOADING_FILTERS,
  PaginatedEvaluationsAction,
  EvaluationsFilterAction,
  SelectedEvaluationsFilterAction,
  SelectedEvalFilterDateFromAction,
  SelectedEvalFilterDateToAction,
  SelectedEvalFilterScopeAction,
  SelectedEvalFilterOwnershipAction,
  SelectedEvalFilterCompaniesAction,
  SelectedEvalFilterPhaseAction,
  SelectedEvalFilterStatusesAction,
  SelectedEvalFilterSupplierStatusesAction,
  SelectedEvalFilterPageAction,
  ManuallyResetEvaluationsListAction,
  SelectedEvalFilterTextSearchAction,
  ResetAllEvaluationsFiltersAction,
  SetLoadingFiltersAction,
} from 'redux/actions/evaluations';

const initialState = {
  evaluationFilters: {
    companies: [] as FilterCompanyModel[],
    evaluationOwners: [] as IEvaluationOwnersGetModel[],
    evaluationTypes: [] as EvaluationTypeGetModel[],
    projectPhases: [] as IProjectPhaseGetModel[],
    statuses: [] as IFiltersStatusesGetModel[],
    supplierCategories: [] as ISupplierCategoryGetModel[],
  },
  paginatedEvaluations: {
    pageNumber: 1,
    totalPages: 0,
    pageSize: 8,
    totalRecords: 0,
    data: [],
  },
  selectedFilters: {
    dateFrom: null,
    dateTo: null,
    selectedScope: [] as EvaluationTypeGetModel[],
    selectedOwnership: null,
    selectedCompanies: [] as FilterCompanyModel[],
    selectedPhases: [] as IProjectPhaseGetModel[],
    selectedStatuses: [] as AccordionCheckBoxItem[],
    selectedSupplierStatuses: [] as ISupplierCategoryGetModel[],
    page: 1,
    searchText: '',
    loading: false,
  },
  manuallyRefreshEvaluationsList: false,
};

export default function (
  state = initialState,
  action:
    | PaginatedEvaluationsAction
    | EvaluationsFilterAction
    | SelectedEvaluationsFilterAction
    | SelectedEvalFilterDateFromAction
    | SelectedEvalFilterDateToAction
    | SelectedEvalFilterScopeAction
    | SelectedEvalFilterOwnershipAction
    | SelectedEvalFilterCompaniesAction
    | SelectedEvalFilterPhaseAction
    | SelectedEvalFilterStatusesAction
    | SelectedEvalFilterSupplierStatusesAction
    | SelectedEvalFilterPageAction
    | ManuallyResetEvaluationsListAction
    | SelectedEvalFilterTextSearchAction
    | ResetAllEvaluationsFiltersAction
    | SetLoadingFiltersAction,
): EvaluationStateOutter {
  switch (action.type) {
    case SET_EVALUATION_FILTERS:
      return {
        ...state,
        evaluationFilters: action.data,
      };
    case SET_PAGINATED_EVALUATIONS:
      return {
        ...state,
        paginatedEvaluations: action.data,
      };
    case SET_SELECTED_EVALUATION_FILTERS:
      return {
        ...state,
        selectedFilters: action.data,
      };
    case SET_EVAL_FILTER_DATE_FROM:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          dateFrom: action.data,
        },
      };
    case SET_EVAL_FILTER_DATE_TO:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          dateTo: action.data,
        },
      };
    case SET_EVAL_FILTER_SCOPE:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          selectedScope: action.data,
        },
      };
    case SET_EVAL_FILTER_OWNERSHIP:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          selectedOwnership: action.data,
        },
      };

    case SET_EVAL_FILTER_COMPANIES:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          selectedCompanies: action.data,
        },
      };

    case SET_EVAL_FILTER_PHASE:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          selectedPhases: action.data,
        },
      };

    case SET_EVAL_FILTER_STATUSES:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          selectedStatuses: action.data,
        },
      };

    case SET_EVAL_FILTER_SUPPLIER_STATUSES:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          selectedSupplierStatuses: action.data,
        },
      };

    case SET_EVAL_FILTER_PAGE:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          page: action.data,
        },
      };

    case MANUALLY_REFRESH_EVALUATIONS_LIST:
      return {
        ...state,
        manuallyRefreshEvaluationsList: action.data,
      };

    case SET_EVAL_FILTER_TEXT_SEARCH:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          searchText: action.data,
        },
      };
    case RESET_ALL_EVAL_FILTERS:
      return {
        ...state,
        selectedFilters: initialState.selectedFilters,
      };

    case SET_LOADING_FILTERS:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          loading: action.data,
        },
      };
    default:
      return state;
  }
}
