import React from 'react';
import { Stack, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as NotFoundSvg } from 'assets/icons/not-found.svg';
import { PageLayout } from 'components';

export const Page403 = (): JSX.Element => {
  return (
    <PageLayout hideSidebar={false}>
      <Stack spacing={2} sx={{ alignItems: 'center', px: { xs: 1, sm: 0 } }}>
        <SvgIcon
          sx={{
            width: { xs: '100%', sm: '252px' },
            height: { xs: '100%', sm: '314px' },
          }}
          component={NotFoundSvg}
          viewBox='0 0 231 171'
        />
        <Typography variant='body1' align='center' sx={{ fontWeight: 700 }}>
          Du har ikke tilgang til denne siden! Vennligst be en administrator tildele deg riktig rolle.
        </Typography>
      </Stack>
    </PageLayout>
  );
};
