/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { PageLayout } from 'components';
import ReactLoading from 'react-loading';
import { Box } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import appSettings from 'config/appSettings';

/**
 * @description This page will redirect
 * the user and force a login.
 * Before redirect, local storage will
 * be cleared. This page could be used
 * to clear any invalid state in local
 * storage.
 *
 * After redirect, user will be redirected
 * to /
 *
 * You can useNavigate/<Link> to send the
 * user to this page.
 */
export const LoginPage = (): JSX.Element => {
  const { instance } = useMsal();

  useEffect(() => {
    // force clear
    localStorage.clear();

    void instance
      .loginRedirect({
        authority: appSettings.auth.authority,
        scopes: appSettings.auth.loginScopes,
        redirectUri: appSettings.auth.redirectUrl,
        redirectStartPage: '/',
      })
      .catch();
  }, []);
  return (
    <PageLayout hideSidebar>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <ReactLoading type='spin' height={150} width={150} />
      </Box>
    </PageLayout>
  );
};

export default LoginPage;
