/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTheme, Typography, useMediaQuery, Chip, FormControl, MenuItem } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { evaluationFiltersSelector } from 'util/selectors';
import CloseIcon from '@mui/icons-material/Close';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import '../../styles.scss';

interface SupplierSelectStepProps {
  selectedSuppliersIds: string[];
  allSuppliers: ISupplierCategoryGetModel[];
  selectedSuppliersWithCats: ISupplierCategoryPostModel[];
  setSelectedSuppliersWithCats: Function;
  supplierCategories: ISupplierCategoryGetModel[];
}

const SelectSupplierCategoriesStep: React.FC<SupplierSelectStepProps> = ({
  selectedSuppliersIds,
  allSuppliers,
  selectedSuppliersWithCats,
  setSelectedSuppliersWithCats,
  supplierCategories,
}: SupplierSelectStepProps): JSX.Element | null => {
  const inputMaxWidth = 280;
  const theme = useTheme();
  const isMdWidth = useMediaQuery(theme.breakpoints.up('md'));

  const [selectedSuppliers, setSelectedSuppliers] = useState<ISupplierCategoryGetModel[]>([]);

  const evaluationFilters = useSelector(evaluationFiltersSelector);
  const allSupplierCats = evaluationFilters?.supplierCategories;

  useEffect(() => {
    if (allSuppliers?.length > 0 && selectedSuppliersIds?.length > 0) {
      const suppliersArray = allSuppliers.filter((suppItem) => {
        if (selectedSuppliersIds.includes(suppItem?.id.toString())) {
          return suppItem;
        }
        return false;
      });
      setSelectedSuppliers(suppliersArray);
    }
  }, [allSuppliers, selectedSuppliersIds]);

  const handleAddSupplierChange = (event: SelectChangeEvent, supplierId: string): void => {
    const newItemObject = {
      supplierCategoryId: event.target.value,
      supplierId,
    };
    setSelectedSuppliersWithCats([...selectedSuppliersWithCats, newItemObject]);
  };

  const listSupplierCats = (supplierId: string): JSX.Element | null => {
    const supplierCategoriesForMapping = selectedSuppliersWithCats.filter((supplierItem) => supplierItem.supplierId === supplierId);

    const _handleDelete = (supplierCatId: number, supplierId: string): void => {
      const filteredObjects = selectedSuppliersWithCats.filter((suppCatItem) => {
        if (suppCatItem?.supplierId.toString() === supplierId) {
          if (suppCatItem?.supplierCategoryId === supplierCatId) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      });
      setSelectedSuppliersWithCats(filteredObjects);
    };

    const _findCategoryName = (categoryId: number): string => {
      if (categoryId !== null && typeof allSupplierCats !== 'undefined' && allSupplierCats?.length > 0) {
        const foundCategory = allSupplierCats.find((catItem) => catItem?.id === categoryId);
        if (foundCategory != null) {
          return foundCategory?.name;
        } else {
          return '-';
        }
      }
      return '-';
    };

    return (
      <div className='evalCatsChipContainer'>
        {supplierCategoriesForMapping.map((supplierItemObj) => {
          return (
            <div key={supplierItemObj?.supplierCategoryId} className='evalChipItem'>
              <Chip
                onDelete={() => _handleDelete(supplierItemObj?.supplierCategoryId, supplierItemObj?.supplierId)}
                onClick={() => _handleDelete(supplierItemObj?.supplierCategoryId, supplierItemObj?.supplierId)}
                sx={{ borderRadius: 0 }}
                label={_findCategoryName(supplierItemObj?.supplierCategoryId)}
                deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const listUnusedCategories = (supplierId: number): JSX.Element | null => {
    const supplierSelectedCats = selectedSuppliersWithCats.filter((suppObj) => suppObj.supplierId === supplierId.toString());
    const arrayOfSelectedSelectedCats = supplierSelectedCats.map((item) => item?.supplierCategoryId);
    const unselectedCategories = supplierCategories.filter((catItem) => !arrayOfSelectedSelectedCats.includes(catItem?.id));

    const _renderPlaceHolderInput = (): JSX.Element => {
      return (
        <FormControl variant='standard' sx={{ m: 1, minWidth: 120, maxWidth: inputMaxWidth }}>
          <Select disabled labelId='create-eval-placeholder' id='create-eval-placeholder'></Select>
        </FormControl>
      );
    };

    if (unselectedCategories?.length > 0) {
      return (
        <Select
          labelId='create-eval-suppliercats'
          id='create-eval-suppliercats'
          onChange={(event) => handleAddSupplierChange(event, supplierId.toString())}
          value=''
        >
          {unselectedCategories?.map((supplierItem) => {
            return (
              <MenuItem key={supplierItem?.id} value={supplierItem.id}>
                {supplierItem?.name}
              </MenuItem>
            );
          })}
        </Select>
      );
    }
    return _renderPlaceHolderInput();
  };

  return (
    <div>
      <Typography sx={{ margin: '8px', marginBottom: 0, marginTop: '15px', letterSpacing: '0.46px', lineHeight: '26px' }}>
        VELG KATEGORIER*
      </Typography>
      <div className={isMdWidth ? 'evalCatsContainer' : 'evalCatsContainerMobile'}>
        {selectedSuppliers.map((supplierItem) => {
          return (
            <div key={supplierItem?.id}>
              <Typography
                sx={{
                  margin: '8px',
                  marginBottom: 0,
                  marginTop: '15px',
                  letterSpacing: '0.46px',
                  lineHeight: '26px',
                  textTransform: 'uppercase',
                }}
              >
                {supplierItem?.name}
              </Typography>
              <FormControl variant='standard' sx={{ m: 1, minWidth: 120, maxWidth: inputMaxWidth, width: '100%' }}>
                {listUnusedCategories(supplierItem?.id)}
              </FormControl>
              {listSupplierCats(supplierItem?.id.toString())}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectSupplierCategoriesStep;
