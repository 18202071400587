export const evaluationFiltersSelector = (state: {
  reducer: {
    evaluations: {
      evaluationFilters: EvaluationFiltersState;
    };
  };
}): EvaluationFiltersState => {
  return state?.reducer?.evaluations?.evaluationFilters;
};

export const paginatedEvaluationsSelector = (state: {
  reducer: {
    evaluations: {
      paginatedEvaluations: PaginatedEvaluationsState;
    };
  };
}): PaginatedEvaluationsState => {
  return state?.reducer?.evaluations?.paginatedEvaluations;
};

export const manuallyRefreshEvaluationsListSelector = (state: {
  reducer: {
    evaluations: {
      manuallyRefreshEvaluationsList: boolean;
    };
  };
}): boolean => {
  return state?.reducer?.evaluations?.manuallyRefreshEvaluationsList;
};

// Selected filters
export const selectedDateFromState = (state: {
  reducer: {
    evaluations: {
      selectedFilters: SelectedFiltersState;
    };
  };
}): string | null => {
  return state?.reducer?.evaluations?.selectedFilters?.dateFrom;
};

export const selectedDateToState = (state: {
  reducer: {
    evaluations: {
      selectedFilters: SelectedFiltersState;
    };
  };
}): string | null => {
  return state?.reducer?.evaluations?.selectedFilters?.dateTo;
};

export const selectedScopeState = (state: {
  reducer: {
    evaluations: {
      selectedFilters: SelectedFiltersState;
    };
  };
}): EvaluationTypeGetModel[] => {
  return state?.reducer?.evaluations?.selectedFilters?.selectedScope;
};

export const selectedOwnershipState = (state: {
  reducer: {
    evaluations: {
      selectedFilters: SelectedFiltersState;
    };
  };
}): IEvaluationOwnersGetModel | null => {
  return state?.reducer?.evaluations?.selectedFilters?.selectedOwnership;
};

export const selectedStateOfSelectedCompanies = (state: {
  reducer: {
    evaluations: {
      selectedFilters: SelectedFiltersState;
    };
  };
}): FilterCompanyModel[] => {
  return state?.reducer?.evaluations?.selectedFilters?.selectedCompanies;
};

export const selectedPhasesState = (state: {
  reducer: {
    evaluations: {
      selectedFilters: SelectedFiltersState;
    };
  };
}): IProjectPhaseGetModel[] => {
  return state?.reducer?.evaluations?.selectedFilters?.selectedPhases;
};

export const selectedStatusesState = (state: {
  reducer: {
    evaluations: {
      selectedFilters: SelectedFiltersState;
    };
  };
}): AccordionCheckBoxItem[] => {
  return state?.reducer?.evaluations?.selectedFilters?.selectedStatuses;
};

export const selectedSupplierStatusesState = (state: {
  reducer: {
    evaluations: {
      selectedFilters: SelectedFiltersState;
    };
  };
}): ISupplierCategoryGetModel[] => {
  return state?.reducer?.evaluations?.selectedFilters?.selectedSupplierStatuses;
};

export const selectedPageState = (state: {
  reducer: {
    evaluations: {
      selectedFilters: SelectedFiltersState;
    };
  };
}): number => {
  return state?.reducer?.evaluations?.selectedFilters?.page;
};

export const selectedTextSearchState = (state: {
  reducer: {
    evaluations: {
      selectedFilters: SelectedFiltersState;
    };
  };
}): string | undefined => {
  return state?.reducer?.evaluations?.selectedFilters?.searchText;
};

export const loadingFiltersState = (state: {
  reducer: {
    evaluations: {
      selectedFilters: SelectedFiltersState;
    };
  };
}): boolean => {
  return state?.reducer?.evaluations?.selectedFilters?.loading;
};

// evaluation Form
export const evalFormSelectedTypeSelector = (state: {
  reducer: {
    evaluationForm: {
      selectedType: IEvaluationTypeGetModel;
    };
  };
}): IEvaluationTypeGetModel => {
  return state?.reducer?.evaluationForm?.selectedType;
};

export const selectedCompanySelector = (state: {
  reducer: {
    evaluationForm: {
      selectedCompany: string;
    };
  };
}): string => {
  return state?.reducer?.evaluationForm?.selectedCompany;
};

export const evalFormProjectSelector = (state: {
  reducer: {
    evaluationForm: {
      selectedProject: string;
    };
  };
}): string => {
  return state?.reducer?.evaluationForm?.selectedProject;
};

export const evalFormProjectPhaseSelector = (state: {
  reducer: {
    evaluationForm: {
      selectedProjectPhase: string;
    };
  };
}): string => {
  return state?.reducer?.evaluationForm?.selectedProjectPhase;
};

export const evalFormDateFromSelector = (state: {
  reducer: {
    evaluationForm: {
      dateFrom: string;
    };
  };
}): string => {
  return state?.reducer?.evaluationForm?.dateFrom;
};

export const evalFormDateToSelector = (state: {
  reducer: {
    evaluationForm: {
      dateTo: string;
    };
  };
}): string => {
  return state?.reducer?.evaluationForm?.dateTo;
};

export const evalFormSuppliersIdsSelector = (state: {
  reducer: {
    evaluationForm: {
      selectedSuppliersIds: string[];
    };
  };
}): string[] => {
  return state?.reducer?.evaluationForm?.selectedSuppliersIds;
};

export const evalFormselectedSuppliersWithCatsSelector = (state: {
  reducer: {
    evaluationForm: {
      selectedSuppliersWithCats: ISupplierCategoryPostModel[];
    };
  };
}): ISupplierCategoryPostModel[] => {
  return state?.reducer?.evaluationForm?.selectedSuppliersWithCats;
};

export const evalFormSelectedSuppliersWithCatsSelector = (state: {
  reducer: {
    evaluationForm: {
      selectedSuppliersWithCats: ISupplierCategoryPostModel[];
    };
  };
}): ISupplierCategoryPostModel[] => {
  return state?.reducer?.evaluationForm?.selectedSuppliersWithCats;
};

export const evalFormEvaluationSummarySelector = (state: {
  reducer: {
    evaluationForm: {
      evaluationSummary: EvaluationSummaryObject;
    };
  };
}): EvaluationSummaryObject => {
  return state?.reducer?.evaluationForm?.evaluationSummary;
};

export const evalFormSelectedRespondersSelector = (state: {
  reducer: {
    evaluationForm: {
      selectedResponders: EvaluationRecipientObject[];
    };
  };
}): EvaluationRecipientObject[] => {
  return state?.reducer?.evaluationForm?.selectedResponders;
};

export const evalFormAllAvailableRecipientsSelector = (state: {
  reducer: {
    evaluationForm: {
      allRecipients: EvaluationRecipientObject[];
    };
  };
}): EvaluationRecipientObject[] => {
  return state?.reducer?.evaluationForm?.allRecipients;
};

// Questions

export const loadingProjectQuestionsFiltersState = (state: {
  reducer: {
    questions: {
      projects: {
        loadingQuestions: boolean;
      };
    };
  };
}): boolean => {
  return state?.reducer?.questions?.projects?.loadingQuestions;
};

export const projectQuestionsFilters = (state: {
  reducer: {
    questions: {
      projects: {
        questionFilters: QuestionCategory[];
      };
    };
  };
}): QuestionCategory[] => {
  return state?.reducer?.questions?.projects?.questionFilters;
};

export const availableSupplierCategoriesSelector = (state: {
  reducer: {
    questions: {
      availableSupplierCategories: ISupplierCategoryGetModel[];
    };
  };
}): ISupplierCategoryGetModel[] => {
  return state?.reducer?.questions?.availableSupplierCategories;
};

export const availableProjectPhasesSelector = (state: {
  reducer: {
    questions: {
      availalbleProjectPhases: IProjectPhaseGetModel[];
    };
  };
}): IProjectPhaseGetModel[] => {
  return state?.reducer?.questions?.availalbleProjectPhases;
};

export const selectedProjectQuestionFilterPhasesSelector = (state: {
  reducer: {
    questions: {
      projects: {
        selectedFilters: {
          selectedPhases: IProjectPhaseGetModel[];
        };
      };
    };
  };
}): IProjectPhaseGetModel[] => {
  return state?.reducer?.questions?.projects?.selectedFilters?.selectedPhases;
};

export const selectedProjectQuestionFilterSupplierTypesSelector = (state: {
  reducer: {
    questions: {
      projects: {
        selectedFilters: {
          selectedSupplierTypes: ISupplierCategoryGetModel[];
        };
      };
    };
  };
}): ISupplierCategoryGetModel[] => {
  return state?.reducer?.questions?.projects?.selectedFilters?.selectedSupplierTypes;
};

export const selectedProjectQuestionFilterQuestionCatsSelector = (state: {
  reducer: {
    questions: {
      projects: {
        selectedFilters: {
          selectedQuestionCats: QuestionCategory[];
        };
      };
    };
  };
}): QuestionCategory[] => {
  return state?.reducer?.questions?.projects?.selectedFilters?.selectedQuestionCats;
};

// Project question list
export const projectQuestionsPageNumberSelector = (state: {
  reducer: {
    questions: {
      projects: {
        paginatedQuestions: {
          pageNumber: number;
        };
      };
    };
  };
}): number => {
  return state?.reducer?.questions?.projects?.paginatedQuestions?.pageNumber;
};

export const projectQuestionsDataSelector = (state: {
  reducer: {
    questions: {
      projects: {
        paginatedQuestions: {
          data: QuestionState[];
        };
      };
    };
  };
}): QuestionState[] => {
  return state?.reducer?.questions?.projects?.paginatedQuestions?.data;
};

export const paginatedQuestionsSelector = (state: {
  reducer: {
    questions: {
      projects: {
        paginatedQuestions: PaginatedQuestionsState;
      };
    };
  };
}): PaginatedQuestionsState => {
  return state?.reducer?.questions?.projects?.paginatedQuestions;
};

export const loadingProjectQuestionDataSelector = (state: {
  reducer: {
    questions: {
      projects: {
        loadingQuestionData: boolean;
      };
    };
  };
}): boolean => {
  return state?.reducer?.questions?.projects?.loadingQuestionData;
};

export const resetProjectQuestionListManuallySelector = (state: {
  reducer: {
    questions: {
      projects: {
        resetQuestionList: boolean;
      };
    };
  };
}): boolean => {
  return state?.reducer?.questions?.projects?.resetQuestionList;
};

// Supplier questions list

export const supplierQuestionsPageNumberSelector = (state: {
  reducer: {
    questions: {
      suppliers: {
        paginatedQuestions: {
          pageNumber: number;
        };
      };
    };
  };
}): number => {
  return state?.reducer?.questions?.suppliers?.paginatedQuestions?.pageNumber;
};

export const supplierQuestionsDataSelector = (state: {
  reducer: {
    questions: {
      suppliers: {
        paginatedQuestions: {
          data: QuestionState[];
        };
      };
    };
  };
}): QuestionState[] => {
  return state?.reducer?.questions?.suppliers?.paginatedQuestions?.data;
};

export const paginatedQuestionsSelectorSupplier = (state: {
  reducer: {
    questions: {
      suppliers: {
        paginatedQuestions: PaginatedQuestionsState;
      };
    };
  };
}): PaginatedQuestionsState => {
  return state?.reducer?.questions?.suppliers?.paginatedQuestions;
};

export const loadingSupplierQuestionDataSelector = (state: {
  reducer: {
    questions: {
      suppliers: {
        loadingQuestionData: boolean;
      };
    };
  };
}): boolean => {
  return state?.reducer?.questions?.suppliers?.loadingQuestionData;
};

export const resetSupplierQuestionListManuallySelector = (state: {
  reducer: {
    questions: {
      suppliers: {
        resetQuestionList: boolean;
      };
    };
  };
}): boolean => {
  return state?.reducer?.questions?.suppliers?.resetQuestionList;
};

export const supplierQuestionsFilters = (state: {
  reducer: {
    questions: {
      suppliers: {
        questionFilters: QuestionCategory[];
      };
    };
  };
}): QuestionCategory[] => {
  return state?.reducer?.questions?.suppliers?.questionFilters;
};

export const loadingSupplierQuestionsFiltersState = (state: {
  reducer: {
    questions: {
      suppliers: {
        loadingQuestions: boolean;
      };
    };
  };
}): boolean => {
  return state?.reducer?.questions?.suppliers?.loadingQuestions;
};

export const selectedSupplierQuestionFilterPhasesSelector = (state: {
  reducer: {
    questions: {
      suppliers: {
        selectedFilters: {
          selectedPhases: IProjectPhaseGetModel[];
        };
      };
    };
  };
}): IProjectPhaseGetModel[] => {
  return state?.reducer?.questions?.suppliers?.selectedFilters?.selectedPhases;
};

export const selectedSupplierQuestionFilterSupplierTypesSelector = (state: {
  reducer: {
    questions: {
      suppliers: {
        selectedFilters: {
          selectedSupplierTypes: ISupplierCategoryGetModel[];
        };
      };
    };
  };
}): ISupplierCategoryGetModel[] => {
  return state?.reducer?.questions?.suppliers?.selectedFilters?.selectedSupplierTypes;
};

export const selectedSupplierQuestionFilterQuestionCatsSelector = (state: {
  reducer: {
    questions: {
      suppliers: {
        selectedFilters: {
          selectedQuestionCats: QuestionCategory[];
        };
      };
    };
  };
}): QuestionCategory[] => {
  return state?.reducer?.questions?.suppliers?.selectedFilters?.selectedQuestionCats;
};
