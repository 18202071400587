import { ILoggerCallback, LogLevel } from '@azure/msal-browser';
import { ICustomProperties, SeverityLevel } from '@microsoft/applicationinsights-web';
import appSettings from 'config/appSettings';
import { appInsights } from 'telemetry/appInsights';

export const log = {
  debug: (message: string, props?: ICustomProperties) => {
    if (['debug'].includes(appSettings.logging.logLevel)) {
      console.debug(message);
      appInsights.trackTrace({ message, severityLevel: SeverityLevel.Verbose }, props);
    }
  },
  info: (message: string, props?: ICustomProperties) => {
    if (['debug', 'information'].includes(appSettings.logging.logLevel)) {
      console.info(message);
      appInsights.trackTrace({ message, severityLevel: SeverityLevel.Information }, props);
    }
  },
  warn: (message: string, props?: ICustomProperties) => {
    if (['debug', 'information', 'warning'].includes(appSettings.logging.logLevel)) {
      console.warn(message);
      appInsights.trackTrace({ message, severityLevel: SeverityLevel.Warning }, props);
    }
  },
  error: (message: string, props?: ICustomProperties) => {
    if (['debug', 'information', 'warning', 'error'].includes(appSettings.logging.logLevel)) {
      console.error(message);
      appInsights.trackTrace({ message, severityLevel: SeverityLevel.Error }, props);
    }
  },
};

/**
 * @description MSAL compatible logger
 */
export const msalLoggerCallback: ILoggerCallback = (level: LogLevel, message: string, containsPii: boolean): void => {
  switch (level) {
    case LogLevel.Error:
      log.error(message, { containsPii });
      return;
    case LogLevel.Info:
      log.info(message, { containsPii });
      return;
    case LogLevel.Verbose:
      log.debug(message, { containsPii });
      return;
    case LogLevel.Warning:
      log.warn(message, { containsPii });
  }
};
