import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { AppRole } from './msalConfig';

export interface AuthorizedTemplateProps {
  roles: AppRole[];
}

/**
 * @description This component will show/hide children based on roles.
 * If the user has ANY of the roles required, the children will be shown.
 */
export const AuthorizedTemplate = ({ roles, children }: PropsWithChildren<AuthorizedTemplateProps>): React.ReactElement | null => {
  const { instance } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const currentAccount = instance.getActiveAccount();

  useEffect(() => {
    if (currentAccount?.idTokenClaims?.roles !== undefined) {
      const intersection = roles.filter((role) => currentAccount?.idTokenClaims?.roles?.includes(role));

      if (intersection.length > 0) {
        setIsAuthorized(true);
      }
    }
  }, [instance, currentAccount]);

  if (isAuthorized) return <>{children}</>;
  return null;
};

export default AuthorizedTemplate;
