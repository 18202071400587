import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import { PageLayout } from 'components';
import {
  selectedDateFromState,
  selectedDateToState,
  selectedScopeState,
  selectedOwnershipState,
  selectedStateOfSelectedCompanies,
  selectedPhasesState,
  selectedStatusesState,
  selectedSupplierStatusesState,
  selectedTextSearchState,
  loadingFiltersState,
} from 'util/selectors';
import RenderFilters from 'components/Evaluations/Filters/RenderFilters';
import RenderFilterAccordion from 'components/shared/RenderFilterAccordion';
import RenderEvaluations from 'components/Evaluations/RenderEvaluations';
import EvaluationFormModal from 'components/Evaluations/FormModal/EvaluationFormModal';
import AddIcon from '@mui/icons-material/Add';
import './styles.scss';
import EvaluationsFiltersSummary from 'components/Evaluations/Filters/EvaluationsFiltersSummary';
import EvaluationsMenuSkeleton from 'components/Evaluations/EvaluationsMenuSkeleton';

export const EvaluationPage = (): JSX.Element => {
  const [showEvaluationFormModal, setShowEvaluationFormModal] = useState(false);
  const [activeEvalObj, setActiveEvalObj] = useState<EvaluationsState | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(1);

  const [showFiltersAccordion, setShowFiltersAccordion] = useState<boolean>(false);
  const [showChosenFiltersAccordion, setShowChosenFiltersAccordion] = useState<boolean>(false);
  const [numberOfFilters, setNumberOfFilters] = useState<number>(0);

  // filters
  const dateFrom = useSelector(selectedDateFromState);
  const dateTo = useSelector(selectedDateToState);
  const selectedScope = useSelector(selectedScopeState);
  const selectedOwnership = useSelector(selectedOwnershipState);
  const selectedCompanies = useSelector(selectedStateOfSelectedCompanies);
  const selectedPhases = useSelector(selectedPhasesState);
  const selectedStatuses = useSelector(selectedStatusesState);
  const selectedSupplierStatuses = useSelector(selectedSupplierStatusesState);
  const selectedTextSeach = useSelector(selectedTextSearchState);
  const isLoadingFilters = useSelector(loadingFiltersState);

  useEffect(() => {
    let totalNumberOfFilters = 0;
    if (selectedTextSeach !== null && typeof selectedTextSeach !== 'undefined' && selectedTextSeach?.length > 0) {
      totalNumberOfFilters += 1;
    }
    if (dateFrom !== null) {
      totalNumberOfFilters += 1;
    }
    if (dateTo !== null) {
      totalNumberOfFilters += 1;
    }
    if (selectedScope?.length > 0) {
      totalNumberOfFilters += selectedScope?.length;
    }
    if (typeof selectedOwnership?.key !== 'undefined') {
      totalNumberOfFilters += 1;
    }
    if (selectedCompanies?.length > 0) {
      totalNumberOfFilters += selectedCompanies?.length;
    }
    if (selectedPhases?.length > 0) {
      totalNumberOfFilters += selectedPhases?.length;
    }
    if (selectedStatuses?.length > 0) {
      totalNumberOfFilters += selectedStatuses?.length;
    }
    if (selectedSupplierStatuses?.length > 0) {
      totalNumberOfFilters += selectedSupplierStatuses?.length;
    }

    setNumberOfFilters(totalNumberOfFilters);
  }, [
    dateFrom,
    dateTo,
    selectedScope,
    selectedCompanies,
    selectedPhases,
    selectedOwnership,
    selectedStatuses,
    selectedSupplierStatuses,
    selectedTextSeach,
  ]);

  const handleEditEvalClick = (showModalBoolean: boolean, evalObject: EvaluationsState | null): void => {
    setCurrentStep(1);
    setShowEvaluationFormModal(showModalBoolean);
    if (typeof evalObject?.id === 'undefined') {
      setActiveEvalObj(null);
    } else {
      setActiveEvalObj(evalObject);
    }
  };

  const renderEvalMenu = (): JSX.Element => {
    if (isLoadingFilters) {
      return (
        <div className='filtersWrapper'>
          <EvaluationsMenuSkeleton />
        </div>
      );
    } else {
      return (
        <div className='filtersWrapper'>
          <div className='evalMenuItem'>
            <Button variant='contained' className='addEvaluationButton' onClick={() => setShowEvaluationFormModal(true)}>
              <AddIcon fontSize='small' />
              Opprett ny evaluering
            </Button>
          </div>
          <div className='evalMenuItem'>
            <RenderFilterAccordion
              accordionTitle={numberOfFilters > 0 ? `VALGTE FILTRE (${numberOfFilters})` : 'VALGTE FILTRE'}
              accordionId='Eval-chosen-filters'
              content={<EvaluationsFiltersSummary />}
              open={showChosenFiltersAccordion}
              setOpen={setShowChosenFiltersAccordion}
            />
          </div>
          <div className='evalMenuItem'>
            <RenderFilterAccordion
              accordionTitle='FILTRERING'
              accordionId='Eval-filters'
              content={<RenderFilters setShowEvaluationFormModal={handleEditEvalClick} />}
              open={showFiltersAccordion}
              setOpen={setShowFiltersAccordion}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <PageLayout hideSidebar={false}>
      <EvaluationFormModal
        activeEvalObj={activeEvalObj}
        setActiveEvalObj={setActiveEvalObj}
        open={showEvaluationFormModal}
        setOpen={setShowEvaluationFormModal}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
      <Box
        sx={{
          px: { md: 0, lg: '20px' },
          gridTemplateColumns: { xs: '1fr 1fr' },
          gap: '20px 20px',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        {renderEvalMenu()}
        <div className='flex2'>
          <RenderEvaluations setShowEvaluationFormModal={handleEditEvalClick} />
        </div>
      </Box>
    </PageLayout>
  );
};
