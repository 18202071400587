import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { useNavigate, Link } from 'react-router-dom';
import { useTheme, AppBar, Box, Toolbar, ButtonBase, useMediaQuery } from '@mui/material';
import BackeLogo from 'assets/logos/backe.png';
import SidebarMenu from './SidebarMenu';
import ProfileMenu from 'components/PageLayout/ProfileMenu';
import './styles.scss';
import { AuthenticatedTemplate } from '@azure/msal-react';

export const PageHeader = (): JSX.Element => {
  const [showDrawer, setShowDrawer] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isLgWidth = useMediaQuery(theme.breakpoints.up('lg'));

  const toggleDrawer = (): void => {
    if (!isLgWidth) {
      setShowDrawer(!showDrawer);
    } else {
      navigate('/');
    }
  };

  const renderLogo = (): JSX.Element => {
    return (
      <>
        <Box
          sx={{
            flexGrow: 0,
            display: { xs: 'none' },
          }}
        >
          <Link
            to='/'
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src={BackeLogo}
              alt='Backe logo'
              style={{
                maxHeight: '45px',
                width: 'auto',
                height: 'auto',
              }}
            />
          </Link>
        </Box>
        <Box
          sx={{
            flexGrow: 0,
            display: { md: 'flex' },
          }}
        >
          <ButtonBase onClick={() => toggleDrawer()}>
            <img
              src={BackeLogo}
              alt='Backe logo'
              style={{
                maxHeight: '45px',
                width: 'auto',
                height: 'auto',
              }}
            />
          </ButtonBase>
        </Box>
      </>
    );
  };

  return (
    <AppBar position='static' elevation={0}>
      <Drawer anchor='left' open={showDrawer} onClose={() => toggleDrawer()}>
        <div className='drawerStyle'>
          <SidebarMenu />
        </div>
      </Drawer>
      <Toolbar disableGutters>
        {renderLogo()}
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 0, minWidth: 0 }}>
          <AuthenticatedTemplate>
            <ProfileMenu />
          </AuthenticatedTemplate>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default PageHeader;
