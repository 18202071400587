import axios, { ResponseType } from 'axios';
import { log } from 'telemetry/logger';
import { useMsalUtils } from 'auth/useMsalUtils';

export interface IUseGraph {
  getProfilePicture: () => Promise<Blob>;
  getUserForEvaluations: (searchString: string) => Promise<any>;
  getProfilePictureUrl: () => Promise<string>;
}

interface IAxiosConfig {
  baseURL: string;
  responseType: ResponseType;
}

const axiosConfig: IAxiosConfig = {
  baseURL: `https://graph.microsoft.com`,
  responseType: 'json',
};

let profilePicture: string | undefined;

export const useGraph = (): IUseGraph => {
  const { getAccesstoken } = useMsalUtils();

  const getProfilePicture = async (): Promise<Blob> => {
    const token = await getAccesstoken(['https://graph.microsoft.com/.default']);
    const response = await axios.get<Blob>(`/v1.0/me/photos/48x48/$value`, {
      ...axiosConfig,
      responseType: 'blob',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'image/jpg',
      },
    });

    return response.data;
  };

  const getUserForEvaluations = async (searchString: string): Promise<Blob> => {
    const token = await getAccesstoken(['https://graph.microsoft.com/.default']);
    const response = await axios.get<any>(`/v1.0/users?$search="displayName:${searchString}"&$orderby=displayName&$count=true`, {
      ...axiosConfig,
      responseType: 'json',
      method: 'GET',
      headers: {
        ConsistencyLevel: 'eventual',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  };

  const getProfilePictureUrlInternal = async (): Promise<string> => {
    const token = await getAccesstoken(['https://graph.microsoft.com/.default']);
    const response = await axios.get<Blob>(`/v1.0/me/photos/48x48/$value`, {
      ...axiosConfig,
      responseType: 'blob',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'image/jpg',
      },
    });

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(response.data);
    return imageUrl;
  };

  const getProfilePictureUrl = async (): Promise<string> => {
    if (profilePicture !== undefined) return profilePicture;

    try {
      profilePicture = await getProfilePictureUrlInternal();
    } catch (error) {
      profilePicture = '';
      log.debug('Unable to fetch profile picture for user. This would happen if the user is a guest user');
    }

    return profilePicture;
  };

  return {
    getProfilePicture,
    getUserForEvaluations,
    getProfilePictureUrl,
  };
};
