import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useApi } from 'hooks/useApi/useApi';
import { Button, TextField } from '@mui/material';
import {
  setEvaluationFilters,
  setSelectedEvaluationFilterDateFrom,
  setSelectedEvaluationFilterDateTo,
  setSelectedEvaluationFilterOwnership,
  setSelectedEvaluationFilterScope,
  setSelectedEvaluationFilterCompanies,
  setSelectedEvaluationFilterPhase,
  setSelectedEvaluationFilterStatuses,
  setSelectedEvaluationFilterSupplierStatuses,
  setSelectedEvaluationFilterPage,
  setSelectedEvaluationFilterSearchText,
  setManuallyRefreshEvaluationsList,
  setLoadingFilters,
} from 'redux/actions/evaluations';
import {
  evaluationFiltersSelector,
  selectedDateFromState,
  selectedDateToState,
  selectedScopeState,
  selectedOwnershipState,
  selectedStateOfSelectedCompanies,
  selectedPhasesState,
  selectedStatusesState,
  selectedSupplierStatusesState,
  selectedTextSearchState,
  loadingFiltersState,
} from 'util/selectors';
import { toast } from 'react-toastify';
import FiltersSkeleton from './FiltersSkeleton';
import { RadioAccordion, CheckBoxAccordion } from 'components';
import '../styles.scss';
import { remapDataToCheckBoxItems } from 'util/functions';
import { dateFormat } from 'util/constants';
import { usePrevious } from 'hooks/usePrevious';

interface RenderFiltersProps {
  setShowEvaluationFormModal: Function;
}

const RenderFilters: React.FC<RenderFiltersProps> = ({ setShowEvaluationFormModal }: RenderFiltersProps) => {
  // hooks etc
  const dispatch = useDispatch();
  const { getEvaluationsFiltersCall } = useApi();
  // show accordions
  const [showOwnershipAccordion, setshowOwnershipAccordion] = useState(false);
  const [showEvalAccordion, setShowEvalAccordion] = useState(false);
  const [showCompaniesAccordion, setShowCompaniesAccordion] = useState(false);
  const [showPhaseAccordion, setShowPhaseAccordion] = useState(false);
  const [showStatusAccordion, setShowStatusAccordion] = useState(false);
  const [showSupplierAccordion, setShowSupplierAccordion] = useState(false);

  // all filters data
  const evaluationFilters = useSelector(evaluationFiltersSelector);
  // filters
  const dateFrom = useSelector(selectedDateFromState);
  const dateTo = useSelector(selectedDateToState);
  const selectedScope = useSelector(selectedScopeState);
  const selectedOwnership = useSelector(selectedOwnershipState);
  const selectedCompanies = useSelector(selectedStateOfSelectedCompanies);
  const selectedPhases = useSelector(selectedPhasesState);
  const selectedStatuses = useSelector(selectedStatusesState);
  const selectedSupplierStatuses = useSelector(selectedSupplierStatusesState);
  const selectedTextSeachStatus = useSelector(selectedTextSearchState);
  const isLoadingFilters = useSelector(loadingFiltersState);

  const [remappedStatuses, setRemappedStatuses] = useState<AccordionCheckBoxItem[]>([]);
  // date picker state
  const [showDateFromPicker, setShowDateFromPicker] = useState<boolean>(false);
  const [showDateToPicker, setShowDateToPicker] = useState<boolean>(false);

  const setSelectedScope = (val: EvaluationTypeGetModel[]): void => {
    dispatch(setSelectedEvaluationFilterScope(val));
    setSelectedPage(1);
  };
  const setSelectedOwnership = (val: IEvaluationOwnersGetModel | null): void => {
    dispatch(setSelectedEvaluationFilterOwnership(val));
    setSelectedPage(1);
  };
  const setSelectedCompanies = (val: FilterCompanyModel[]): void => {
    dispatch(setSelectedEvaluationFilterCompanies(val));
    setSelectedPage(1);
  };
  const setSelectedPhases = (val: IProjectPhaseGetModel[]): void => {
    dispatch(setSelectedEvaluationFilterPhase(val));
    setSelectedPage(1);
  };
  const setSelectedStatuses = (val: AccordionCheckBoxItem[]): void => {
    dispatch(setSelectedEvaluationFilterStatuses(val));
    setSelectedPage(1);
  };
  const setSelectedSupplierStatuses = (val: ISupplierCategoryGetModel[]): void => {
    dispatch(setSelectedEvaluationFilterSupplierStatuses(val));
    setSelectedPage(1);
  };

  const prevSearchText = usePrevious(selectedTextSeachStatus);

  const handleDateFromChange = (val: string | null): void => {
    const formattedDate = moment(val).format();
    if (formattedDate !== 'Invalid date') {
      dispatch(setSelectedEvaluationFilterDateFrom(formattedDate));
      setSelectedPage(1);
    } else {
      dispatch(setSelectedEvaluationFilterDateFrom(null));
    }
  };

  const handleDateToChange = (val: string | null): void => {
    const formattedDate = moment(val).format();

    if (formattedDate !== 'Invalid date') {
      dispatch(setSelectedEvaluationFilterDateTo(formattedDate));
      setSelectedPage(1);
    } else {
      dispatch(setSelectedEvaluationFilterDateTo(null));
    }
  };

  const setSelectedPage = (val: number): void => {
    dispatch(setSelectedEvaluationFilterPage(val));
  };

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setSelectedEvaluationFilterSearchText(event.target.value));
    setSelectedPage(1);
  };

  const handleSearchClickAction = (): void => {
    dispatch(setManuallyRefreshEvaluationsList(true));
  };

  const handleResetDates = (): void => {
    dispatch(setSelectedEvaluationFilterDateTo(null));
    dispatch(setSelectedEvaluationFilterDateFrom(null));
    setSelectedPage(1);
  };

  useEffect(() => {
    // remap data to checkbox accordion data format
    if (evaluationFilters?.statuses !== undefined && evaluationFilters?.statuses?.length > 0) {
      const remappedValues = remapDataToCheckBoxItems(evaluationFilters.statuses);
      setRemappedStatuses(remappedValues);
    }
  }, [evaluationFilters.statuses]);

  useEffect(() => {
    // debounced search for text
    if (prevSearchText?.length !== selectedTextSeachStatus?.length) {
      const debouncedTextSearch = setTimeout(() => {
        handleSearchClickAction();
      }, 1000);

      return () => clearTimeout(debouncedTextSearch);
    }
  }, [selectedTextSeachStatus, prevSearchText]);

  useEffect(() => {
    // statuses indicate that we have fetched filters
    if (evaluationFilters?.statuses?.length === 0) {
      dispatch(setLoadingFilters(true));
      getEvaluationsFiltersCall()
        .then((response) => {
          dispatch(setEvaluationFilters(response));
        })
        .catch((err) => {
          if (typeof err === 'string') {
            toast.error(err);
          } else {
            toast.error('Klarte ikke å hente filtrer til evalueringer.');
          }
        })
        .finally(() => {
          setTimeout(() => dispatch(setLoadingFilters(false)), 500);
        });
    }
  }, [evaluationFilters]);

  if (isLoadingFilters) {
    return <FiltersSkeleton />;
  }

  return (
    <div className='filtersContainer'>
      <div className='searchContainer'>
        <TextField
          id='general-search'
          label='Søk'
          type='search'
          variant='standard'
          className='searchTextInput'
          value={selectedTextSeachStatus}
          onChange={handleSearchTextChange}
        />
        <Button variant='contained' className='searchButton' onClick={handleSearchClickAction}>
          Søk
        </Button>
      </div>
      <div className='filterItemContainer'>
        <DesktopDatePicker
          open={showDateFromPicker}
          inputFormat={dateFormat}
          label='Dato fra'
          value={dateFrom}
          maxDate={dateTo !== null ? moment(dateTo).format() : null}
          onOpen={() => setShowDateFromPicker(true)}
          onClose={() => setShowDateFromPicker(false)}
          onChange={(newValue) => {
            handleDateFromChange(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              variant='standard'
              className='dateSelector'
              onClick={() => setShowDateFromPicker(true)}
            />
          )}
        />
      </div>
      <div className='filterItemContainer'>
        <DesktopDatePicker
          open={showDateToPicker}
          inputFormat={dateFormat}
          onOpen={() => setShowDateToPicker(true)}
          onClose={() => setShowDateToPicker(false)}
          label='Dato til'
          minDate={dateFrom !== null ? moment(dateFrom).format() : null}
          value={dateTo}
          onChange={(newValue) => {
            handleDateToChange(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              variant='standard'
              className='dateSelector'
              onClick={() => setShowDateToPicker(true)}
            />
          )}
        />
      </div>
      <div className='evalFiltersResetDatesbtnContainer'>
        <Button
          sx={{ padding: 0, paddingRight: '5px', paddingLeft: '5px' }}
          size='small'
          variant='outlined'
          className=''
          disabled={dateTo === null && dateFrom === null}
          onClick={() => handleResetDates()}
        >
          NULLSTILL
        </Button>
      </div>
      <div className='filterItemContainer'>
        <RadioAccordion
          open={showOwnershipAccordion}
          setOpen={setshowOwnershipAccordion}
          accordionTitle='Vis evalueringer'
          radioObjectsArr={evaluationFilters?.evaluationOwners}
          accordionId='ownership-accord'
          selectedValue={selectedOwnership}
          setSelectedValue={setSelectedOwnership}
        />
      </div>
      <div className='filterItemContainer'>
        <CheckBoxAccordion
          open={showCompaniesAccordion}
          setOpen={setShowCompaniesAccordion}
          accordionTitle='Selskap'
          checkBoxObjectsArr={evaluationFilters?.companies}
          accordionId='companies-accord'
          selectedValues={selectedCompanies}
          setSelectedValues={setSelectedCompanies}
        />
      </div>
      <div className='filterItemContainer'>
        <CheckBoxAccordion
          open={showEvalAccordion}
          setOpen={setShowEvalAccordion}
          accordionTitle='Evaluering'
          checkBoxObjectsArr={evaluationFilters?.evaluationTypes}
          accordionId='evalType-accord'
          selectedValues={selectedScope}
          setSelectedValues={setSelectedScope}
        />
      </div>
      <div className='filterItemContainer'>
        <CheckBoxAccordion
          open={showPhaseAccordion}
          setOpen={setShowPhaseAccordion}
          accordionTitle='Prosjektfase'
          checkBoxObjectsArr={evaluationFilters?.projectPhases}
          accordionId='phase-accord'
          selectedValues={selectedPhases}
          setSelectedValues={setSelectedPhases}
        />
      </div>
      <div className='filterItemContainer'>
        <CheckBoxAccordion
          open={showStatusAccordion}
          setOpen={setShowStatusAccordion}
          accordionTitle='Status'
          checkBoxObjectsArr={remappedStatuses}
          accordionId='status-accord'
          selectedValues={selectedStatuses}
          setSelectedValues={setSelectedStatuses}
        />
      </div>
      <div className='filterItemContainer'>
        <CheckBoxAccordion
          open={showSupplierAccordion}
          setOpen={setShowSupplierAccordion}
          accordionTitle='Leverandørtype'
          checkBoxObjectsArr={evaluationFilters?.supplierCategories}
          accordionId='supplier-accord'
          selectedValues={selectedSupplierStatuses}
          setSelectedValues={setSelectedSupplierStatuses}
        />
      </div>
    </div>
  );
};

export default RenderFilters;
