/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { AppRole } from 'auth/msalConfig';
import { Box } from '@mui/material';
import { PageLayout } from 'components';
import ReactLoading from 'react-loading';

export const HomePage: React.FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const currentAccount = instance.getActiveAccount();

  useEffect(() => {
    const roles = currentAccount?.idTokenClaims?.roles;

    // if the only role you have is QuestionAdmin, navigate to questions.
    // All other cases, go to evaluations.
    if (!!roles && roles.length === 1 && roles.includes(AppRole.QuestionAdmin)) {
      navigate('/supplier');
      return;
    }

    navigate('/evaluations');
  }, []);

  return (
    <PageLayout hideSidebar={false}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <ReactLoading type='spin' height={150} width={150} />
      </Box>
    </PageLayout>
  );
};
