/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedTypeAction,
  setSelectedCompanyAction,
  setSelectedProjectAction,
  setSelectedProjectPhaseAction,
  setDateFromAction,
  setDateToAction,
  setEvaluationSummaryAction,
  setSelectedRespondersAction,
} from 'redux/actions/evaluationForm';

import {
  evalFormSelectedTypeSelector,
  selectedCompanySelector,
  evalFormProjectSelector,
  evalFormProjectPhaseSelector,
  evalFormDateFromSelector,
  evalFormDateToSelector,
  evalFormselectedSuppliersWithCatsSelector,
  evalFormEvaluationSummarySelector,
  evalFormSelectedRespondersSelector,
} from 'util/selectors';
import moment from 'moment';
import EvaluationSetupStep from './Steps/EvaluationSetupStep';
import SelectRecipientsStep from './Steps/SelectRecipientsStep';
import TotalSummaryStepProject from './Steps/TotalSummaryStepProject';

interface CreateEvaluationFormProps {
  currentStep: number;
  setDisableNextButton: Function;
}

const CreateEvaluationForm: React.FC<CreateEvaluationFormProps> = ({
  currentStep,
  setDisableNextButton,
}: CreateEvaluationFormProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const [allSuppliers, setAllSuppliers] = useState<ISupplierCategoryGetModel[]>([]);

  // step 1
  const selectedType = useSelector(evalFormSelectedTypeSelector);
  const setSelectedType = (val: IEvaluationTypeGetModel): void => {
    dispatch(setSelectedTypeAction(val));
  };
  const selectedCompany = useSelector(selectedCompanySelector);
  const setSelectedCompany = (val: string): void => {
    dispatch(setSelectedCompanyAction(val));
  };
  const selectedProject = useSelector(evalFormProjectSelector);
  const setSelectedProject = (val: string): void => {
    dispatch(setSelectedProjectAction(val));
  };
  const selectedProjectPhase = useSelector(evalFormProjectPhaseSelector);
  const setSelectedProjectPhase = (val: string): void => {
    dispatch(setSelectedProjectPhaseAction(val));
  };
  const dateFrom = useSelector(evalFormDateFromSelector);
  const setDateFrom = (val: string): void => {
    const startOfDay = moment(val).set({ hour: 0, minute: 0 }).format();
    dispatch(setDateFromAction(startOfDay));
  };
  const dateTo = useSelector(evalFormDateToSelector);
  const setDateTo = (val: string): void => {
    const lastHour = moment(val).set({ hour: 23, minute: 59 }).format();
    dispatch(setDateToAction(lastHour));
  };

  // step 3
  const selectedSuppliersWithCats = useSelector(evalFormselectedSuppliersWithCatsSelector);

  // step 4
  const evaluationSummary = useSelector(evalFormEvaluationSummarySelector);
  const setEvaluationSummary = (val: EvaluationSummaryObject): void => {
    dispatch(setEvaluationSummaryAction(val));
  };

  const selectedResponders = useSelector(evalFormSelectedRespondersSelector);
  const setSelectedResponders = (val: EvaluationRecipientObject[]): void => {
    dispatch(setSelectedRespondersAction(val));
  };

  useEffect(() => {
    if (currentStep === 1) {
      if (
        selectedType?.id === null ||
        selectedCompany?.length < 1 ||
        selectedProject?.length < 1 ||
        selectedProjectPhase?.length < 1 ||
        dateFrom === null ||
        dateTo === null
      ) {
        setDisableNextButton(true);
      } else if (dateFrom !== null && dateTo !== null) {
        const dateToIsAfterDateFrom = moment(dateTo).isAfter(moment(dateFrom));
        if (!dateToIsAfterDateFrom) {
          setDisableNextButton(true);
        } else {
          setDisableNextButton(false);
        }
      } else {
        setDisableNextButton(false);
      }
    } else if (currentStep === 2) {
      if (selectedResponders?.length === 0) {
        setDisableNextButton(true);
      } else {
        setDisableNextButton(false);
      }
    } else if (currentStep === 3) {
      setDisableNextButton(false);
    }
  }, [selectedType, selectedCompany, selectedProject, selectedProjectPhase, dateFrom, dateTo, currentStep, selectedResponders]);

  const renderCurrentCardContent = (): JSX.Element | null => {
    switch (currentStep) {
      case 1:
        return (
          <div className='evalCardContentWrap'>
            <EvaluationSetupStep
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
              selectedProjectPhase={selectedProjectPhase}
              setSelectedProjectPhase={setSelectedProjectPhase}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
              // setSelectedSuppliersIds={setSelectedSuppliersIds}
              evaluationSummary={evaluationSummary}
              setEvaluationSummary={setEvaluationSummary}
            />
          </div>
        );

      case 2:
        return (
          <SelectRecipientsStep
            showDates
            showPhase
            isEditForm={false}
            evaluationSummary={evaluationSummary}
            dateFrom={dateFrom}
            dateTo={dateTo}
            selectedResponders={selectedResponders}
            setSelectedResponders={setSelectedResponders}
          />
        );
      case 3:
        return (
          <TotalSummaryStepProject
            evaluationSummary={evaluationSummary}
            dateFrom={dateFrom}
            dateTo={dateTo}
            selectedType={selectedType}
            selectedProjectPhase={selectedProjectPhase}
            selectedSuppliersWithCats={selectedSuppliersWithCats}
            allSuppliers={allSuppliers}
          />
        );
      default:
        return null;
    }
  };

  return <div className='evalCardContentContainer'>{renderCurrentCardContent()}</div>;
};

export default CreateEvaluationForm;
