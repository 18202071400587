import {
  SET_SELECTED_TYPE,
  SET_SELECTED_COMPANY,
  SET_SELECTED_PROJECT,
  SET_SELECTED_PROJECT_PHASE,
  SET_SELECTED_DATE_FROM,
  SET_SELECTED_DATE_TO,
  SET_SELECTED_SUPPLIER_IDS,
  SET_SELECTED_SUPPLIER_WITH_CATS,
  SET_SELECTED_EVALUATION_SUMMARY,
  SET_SELECTED_RESPONDERS,
  RESET_EVALUATION_FORM,
  SET_ALL_AVAILABLE_RECIPIENTS,
  SetSelectedTypeActionType,
  SetSelectedCompanyActionType,
  SetSelectedProjectActionType,
  SetSelectedProjectPhaseActionType,
  SetSelectedDateFromActionType,
  SetSelectedDateToActionType,
  SetSelectedSupplierIdsActionType,
  SetEvaluationSummaryActionType,
  SetSelectedRespondersActionType,
  SetSelectedSuppliersWithCatsActionType,
  ResetEvaluationFormActionType,
  SetAllAvailableRecipientsActionType,
} from 'redux/actions/evaluationForm';

const initialState = {
  // step 1
  selectedType: undefined,
  selectedCompany: '',
  selectedProject: '',
  selectedProjectPhase: '',
  dateFrom: null,
  dateTo: null,
  // step 2
  selectedSuppliersIds: [],
  // step 3
  selectedSuppliersWithCats: [],
  // step 4
  evaluationSummary: {},
  selectedResponders: [],
  // common
  allRecipients: [],
};

export default function (
  state = initialState,
  action:
    | SetSelectedTypeActionType
    | SetSelectedCompanyActionType
    | SetSelectedProjectActionType
    | SetSelectedProjectPhaseActionType
    | SetSelectedDateFromActionType
    | SetSelectedDateToActionType
    | SetSelectedSupplierIdsActionType
    | SetEvaluationSummaryActionType
    | SetSelectedRespondersActionType
    | SetSelectedSuppliersWithCatsActionType
    | ResetEvaluationFormActionType
    | SetAllAvailableRecipientsActionType,
): ReduxEvaluationFormStateModel {
  switch (action.type) {
    case SET_SELECTED_TYPE:
      return {
        ...state,
        selectedType: action.data,
      };
    case SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.data,
      };
    case SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: action.data,
      };
    case SET_SELECTED_PROJECT_PHASE:
      return {
        ...state,
        selectedProjectPhase: action.data,
      };
    case SET_SELECTED_DATE_FROM:
      return {
        ...state,
        dateFrom: action.data,
      };
    case SET_SELECTED_DATE_TO:
      return {
        ...state,
        dateTo: action.data,
      };
    case SET_SELECTED_SUPPLIER_IDS:
      return {
        ...state,
        selectedSuppliersIds: action.data,
      };
    case SET_SELECTED_SUPPLIER_WITH_CATS:
      return {
        ...state,
        selectedSuppliersWithCats: action.data,
      };
    case SET_SELECTED_EVALUATION_SUMMARY:
      return {
        ...state,
        evaluationSummary: action.data,
      };
    case SET_SELECTED_RESPONDERS:
      return {
        ...state,
        selectedResponders: action.data,
      };
    case RESET_EVALUATION_FORM:
      return initialState;

    case SET_ALL_AVAILABLE_RECIPIENTS:
      return {
        ...state,
        allRecipients: action.data,
      };
    default:
      return state;
  }
}
