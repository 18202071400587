/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import { InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import {
  AccountIdentifiers,
  IMsalContext,
  MsalAuthenticationResult,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import React, { PropsWithChildren, useState, useMemo, useEffect } from 'react';
import { AppRole } from './msalConfig';

export type MsalAuthorizationProps = PropsWithChildren<
  AccountIdentifiers & {
    interactionType: InteractionType;
    authenticationRequest?: PopupRequest | RedirectRequest | SsoSilentRequest;
    loadingComponent?: React.ElementType<IMsalContext> | React.ElementType;
    authenticationErrorComponent?: React.ElementType<MsalAuthenticationResult> | React.ElementType;
    authorizationErrorComponent?: React.ElementType<MsalAuthenticationResult> | React.ElementType;
    roles: AppRole[];
  }
>;

/**
 * @description This is a fork of the official MsalAuthenticationTemplate component. This component will trigger
 * authentication, and it will check if the user has ANY of the required roles before rendering children.
 *
 * If you have a route accessible by ANY authenticated user (no role checking required),
 * you will instead use the MsalAuthenticationTemplate.
 *
 * Source for MsalAuthenticationTemplate: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/src/components/MsalAuthenticationTemplate.tsx
 * Office sample for checking roles: https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/blob/main/5-AccessControl/1-call-api-roles/SPA/src/components/RouteGuard.jsx
 */
export function MsalAuthorizationTemplate({
  interactionType,
  username,
  homeAccountId,
  localAccountId,
  authenticationRequest,
  loadingComponent: LoadingComponent,
  authenticationErrorComponent: AuthenticationErrorComponent,
  authorizationErrorComponent: AuthorizationErrorComponent,
  roles,
  children,
}: MsalAuthorizationProps): React.ReactElement | null {
  const accountIdentifier: AccountIdentifiers = useMemo(() => {
    return {
      username,
      homeAccountId,
      localAccountId,
    };
  }, [username, homeAccountId, localAccountId]);
  const context = useMsal();
  const msalAuthResult = useMsalAuthentication(interactionType, authenticationRequest, accountIdentifier);
  const isAuthenticated = useIsAuthenticated(accountIdentifier);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [authorizationInProgress, setAuthorizationInProgress] = useState<boolean>(true);
  const currentAccount = context.instance.getActiveAccount();

  useEffect(() => {
    if (!!currentAccount && !!roles) {
      if (!!currentAccount?.idTokenClaims?.roles) {
        const intersection = roles.filter((role) => currentAccount?.idTokenClaims?.roles?.includes(role));

        if (intersection.length > 0) {
          setIsAuthorized(true);
        }

        setAuthorizationInProgress(false);
        return;
      }

      if (!currentAccount?.idTokenClaims?.roles) {
        setIsAuthorized(false);
        setAuthorizationInProgress(false);
      }
    }
  }, [context, currentAccount]);

  if (msalAuthResult.error && context.inProgress === InteractionStatus.None) {
    if (!!AuthenticationErrorComponent) {
      return <AuthenticationErrorComponent {...msalAuthResult} />;
    }

    throw msalAuthResult.error;
  }

  if (context.inProgress === InteractionStatus.None && !authorizationInProgress && !isAuthorized) {
    if (!!AuthorizationErrorComponent) {
      return <AuthorizationErrorComponent {...msalAuthResult} />;
    }
  }

  if (isAuthenticated) {
    if (isAuthorized) {
      return <React.Fragment>{children}</React.Fragment>;
    }
  }

  if (!!LoadingComponent && context.inProgress !== InteractionStatus.None) {
    return <LoadingComponent {...context} />;
  }

  if (!!LoadingComponent && authorizationInProgress) {
    return <LoadingComponent {...context} />;
  }

  return null;
}
