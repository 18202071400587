/* eslint-disable @typescript-eslint/no-unused-vars */

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import AccordionDetails from '@mui/material/AccordionDetails';
import './styles.scss';

interface FilterAccordionProps {
  accordionTitle: string;
  setOpen: Function;
  open: boolean;
  content: JSX.Element;
  accordionId: string;
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.text.secondary,
  padding: 0,
  margin: 0,
  border: 'none',
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreOutlinedIcon color='secondary' fontSize='small' sx={{ color: 'white' }} />} {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    margin: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'white',
    paddingRight: '15px',
    paddingLeft: '15px',
  },
  '& .MuiAccordionSummary-root': {
    padding: 0,
    margin: 0,
  },
}));

const RenderFilterAccordion: React.FC<FilterAccordionProps> = ({
  accordionTitle,
  setOpen,
  open,
  content,
  accordionId,
}: FilterAccordionProps): JSX.Element | null => {
  const toggleOpen = (): void => {
    setOpen(!open);
  };

  if (content === null) {
    return null;
  }

  return (
    <Accordion expanded={open} onChange={toggleOpen} className='accordionWrapperEvalSummary'>
      <AccordionSummary
        sx={{ minHeight: '40.5px', maxHeight: '40.5px' }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={accordionId}
        id={accordionId}
        className='accordionEvalSummary'
      >
        <Typography sx={{ color: 'white', fontSize: '0.875rem', textAlign: 'center', padding: 0, margin: 0, letterSpacing: '0.46px' }}>
          {accordionTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className='accordionContentEvalMenu'>{content}</AccordionDetails>
    </Accordion>
  );
};

export default RenderFilterAccordion;
