import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { backe } from 'styles/jsColors';

interface BackDropLoadingProps {
  open: boolean;
}

export default function BackDropLoading({ open }: BackDropLoadingProps): JSX.Element | null {
  if (!open) {
    return null;
  }
  return (
    <Backdrop sx={{ color: backe.blue, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <CircularProgress color='primary' />
    </Backdrop>
  );
}
