import { useTheme, Typography, useMediaQuery, ButtonBase } from '@mui/material';
import NoResultsSvg from 'assets/NoResultsIcon/NoResultsIcon';
import EditIcon from '@mui/icons-material/Edit';
import CustomSwitch from 'components/shared/CustomSwitch';

interface QuestionRenderProps {
  questionList: QuestionState[];
  handleToggleQItemAction: Function;
  handleEditQuestionAction: Function;
}

export const QuestionRender = ({
  questionList,
  handleToggleQItemAction,
  handleEditQuestionAction,
}: QuestionRenderProps): JSX.Element | null => {
  const theme = useTheme();
  const isMdWidth = useMediaQuery(theme.breakpoints.up('md'));

  if (questionList?.length === 0) {
    return (
      <div className='noResultsImgContainerQ'>
        <NoResultsSvg width={isMdWidth ? '370px' : '200px'} height={isMdWidth ? '370px' : '200px'} />
        <Typography sx={{ fontSize: '25px', letterSpacing: '0.46px', lineHeight: '26px', paddingTop: '30px' }}>
          Beklager, ingen treff
        </Typography>
      </div>
    );
  }

  const renderQuestions = (): JSX.Element[] => {
    return questionList.map((item) => {
      return (
        <div className='questionListItem' key={item?.id}>
          <div className='leftContainer'>
            <Typography>{item?.value}</Typography>
          </div>
          <div className='rightContainer'>
            <div className='swithchContainer'>
              <CustomSwitch checked={item?.isActive} onChange={() => handleToggleQItemAction(item.id, item)} />
            </div>
            <ButtonBase onClick={() => handleEditQuestionAction(item)} className='buttonContainer'>
              <EditIcon fontSize='medium' htmlColor={theme.palette.primary.main} />
            </ButtonBase>
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <div className='questionListHeader'>
        <div className='leftContainer'>
          <Typography
            variant='body1'
            align='center'
            color='text.secondary'
            sx={{ textTransform: 'uppercase', fontSize: '0.9rem', padding: 0, margin: 0, lineHeight: '30px' }}
          >
            Spørsmål
          </Typography>
        </div>
        <div className='rightContainer'>
          <Typography
            variant='body1'
            align='center'
            color='text.secondary'
            sx={{ textTransform: 'uppercase', fontSize: '0.9rem', padding: 0, margin: 0, lineHeight: '30px', flex: 1 }}
          >
            Aktiver
          </Typography>
          <Typography
            variant='body1'
            align='center'
            color='text.secondary'
            sx={{ textTransform: 'uppercase', fontSize: '0.9rem', padding: 0, margin: 0, lineHeight: '30px', paddingLeft: '3px', flex: 1 }}
          >
            Endre
          </Typography>
        </div>
      </div>
      <div>{renderQuestions()}</div>
    </div>
  );
};

export default QuestionRender;
