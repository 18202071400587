import { Typography } from '@mui/material';
import moment from 'moment';
import '../styles.scss';

interface RenderEvaluationSummaryProps {
  evaluationSummary?: EvaluationSummaryObject;
  dateFrom: string | null;
  dateTo: string | null;
  showDates: boolean;
  showPhase: boolean;
}

const RenderEvaluationsSummary: React.FC<RenderEvaluationSummaryProps> = ({
  evaluationSummary,
  dateFrom,
  dateTo,
  showDates,
  showPhase,
}: RenderEvaluationSummaryProps): JSX.Element | null => {
  return (
    <div>
      {typeof evaluationSummary?.companyName === 'string' && evaluationSummary?.companyName?.length > 1 ? (
        <div className='evalRecipientsLine'>
          <Typography sx={{ marginBottom: 0, marginTop: '10px', letterSpacing: '0.46px', lineHeight: '26px' }}>SELSKAP:</Typography>
          <Typography sx={{ marginLeft: '5px', marginBottom: 0, marginTop: '10px', letterSpacing: '0.46px', lineHeight: '26px' }}>
            {evaluationSummary?.companyName}
          </Typography>
        </div>
      ) : null}
      <div className='evalRecipientsLine'>
        <Typography sx={{ marginBottom: 0, marginTop: '10px', letterSpacing: '0.46px', lineHeight: '26px' }}>PROSJEKT:</Typography>
        <Typography sx={{ marginLeft: '5px', marginBottom: 0, marginTop: '10px', letterSpacing: '0.46px', lineHeight: '26px' }}>
          {evaluationSummary?.projectName}
        </Typography>
      </div>
      {showPhase ? (
        <div className='evalRecipientsLine'>
          <Typography sx={{ marginBottom: 0, marginTop: '10px', letterSpacing: '0.46px', lineHeight: '26px' }}>PROSJEKTFASE:</Typography>
          <Typography sx={{ marginLeft: '5px', marginBottom: 0, marginTop: '10px', letterSpacing: '0.46px', lineHeight: '26px' }}>
            {evaluationSummary?.phaseName}
          </Typography>
        </div>
      ) : null}
      {showDates ? (
        <div className='evalRecipientsLine'>
          <Typography sx={{ marginBottom: 0, marginTop: '10px', letterSpacing: '0.46px', lineHeight: '26px' }}>
            EVALUERINGSPERIODE:
          </Typography>
          {dateFrom !== null && dateTo !== null && dateFrom?.length > 0 && dateTo?.length > 0 ? (
            <Typography sx={{ marginLeft: '5px', marginBottom: 0, marginTop: '10px', letterSpacing: '0.46px', lineHeight: '26px' }}>
              {moment(dateFrom).format('DD/MM/YYYY')} - {moment(dateTo).format('DD/MM/YYYY')}
            </Typography>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default RenderEvaluationsSummary;
