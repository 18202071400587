import { Typography, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useApi } from 'hooks/useApi/useApi';
import SelectRecipientsStep from './Steps/SelectRecipientsStep';
import RenderEvaluationSummary from './RenderEvaluationSummary';
import moment from 'moment';
import {
  setSelectedCompanyAction,
  setSelectedProjectAction,
  setDateFromAction,
  setDateToAction,
  setEvaluationSummaryAction,
  setSelectedRespondersAction,
  setSelectedTypeAction,
  setSelectedSuppliersWithCatsAction,
  setSelectedProjectPhaseAction,
} from 'redux/actions/evaluationForm';

import {
  evalFormSelectedTypeSelector,
  selectedCompanySelector,
  evalFormProjectSelector,
  evalFormProjectPhaseSelector,
  evalFormDateFromSelector,
  evalFormDateToSelector,
  evalFormselectedSuppliersWithCatsSelector,
  evalFormEvaluationSummarySelector,
  evalFormSelectedRespondersSelector,
} from 'util/selectors';
import TotalSummaryStep from './Steps/TotalSummaryStep';
import TotalSummaryStepProject from './Steps/TotalSummaryStepProject';
import { dateFormat, dateFormatInitDate } from 'util/constants';

interface EditEvaluationFormProps {
  currentStep: number;
  activeEvalObj: EvaluationsState | undefined;
  setDisableNextButton: Function;
}

const EditEvaluationForm: React.FC<EditEvaluationFormProps> = ({
  currentStep,
  activeEvalObj,
  setDisableNextButton,
}: EditEvaluationFormProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const { getSuppliersOfCompanyProjectCall } = useApi();
  const [allSuppliers, setAllSuppliers] = useState<ISupplierCategoryGetModel[]>([]);
  const [isProjectEval, setIsProjectEval] = useState<boolean>(false);

  const [showDateToPicker, setShowDateToPicker] = useState<boolean>(false);

  // step 1
  const selectedType = useSelector(evalFormSelectedTypeSelector);
  const setSelectedType = (val: IEvaluationTypeGetModel): void => {
    dispatch(setSelectedTypeAction(val));
  };
  const selectedCompany = useSelector(selectedCompanySelector);
  const setSelectedCompany = (val: string): void => {
    dispatch(setSelectedCompanyAction(val));
  };
  const selectedProject = useSelector(evalFormProjectSelector);
  const setSelectedProject = (val: string): void => {
    dispatch(setSelectedProjectAction(val));
  };
  const selectedProjectPhase = useSelector(evalFormProjectPhaseSelector);
  const setSelectedProjectPhase = (val: string): void => {
    dispatch(setSelectedProjectPhaseAction(val));
  };

  const dateFrom = useSelector(evalFormDateFromSelector);
  const setDateFrom = (val: string): void => {
    dispatch(setDateFromAction(val));
  };
  const dateTo = useSelector(evalFormDateToSelector);
  const setDateTo = (val: string | null): void => {
    dispatch(setDateToAction(val));
  };

  // step 2
  const selectedSuppliersWithCats = useSelector(evalFormselectedSuppliersWithCatsSelector);
  const setSelectedSuppliersWithCats = (val: ISupplierCategoryPostModel[]): void => {
    dispatch(setSelectedSuppliersWithCatsAction(val));
  };
  // step 3
  const evaluationSummary = useSelector(evalFormEvaluationSummarySelector);
  const setEvaluationSummary = (val: EvaluationSummaryObject): void => {
    dispatch(setEvaluationSummaryAction(val));
  };

  const selectedResponders = useSelector(evalFormSelectedRespondersSelector);
  const setSelectedResponders = (val: EvaluationRecipientObject[]): void => {
    dispatch(setSelectedRespondersAction(val));
  };

  useEffect(() => {
    if (
      typeof selectedCompany === 'string' &&
      selectedCompany?.length > 0 &&
      typeof selectedProject === 'string' &&
      selectedProject?.length > 0
    ) {
      //  setLoading(true);
      getSuppliersOfCompanyProjectCall(selectedCompany, selectedProject)
        .then((response) => {
          setAllSuppliers(response);
        })
        .catch((err) => {
          if (typeof err?.response?.data?.detail === 'string') {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error('Klarte ikke å hente liste prosjektliste av leverandører.');
          }
        })
        .finally(() => {
          //  setTimeout(() => setLoading(false), 500);
        });
    }
  }, [selectedCompany, selectedProject]);

  useEffect(() => {
    if (currentStep === 1) {
      if (dateTo === null) {
        setDisableNextButton(true);
      } else if (dateFrom !== null && dateTo !== null) {
        const dateToIsAfterDateFrom = moment(dateTo).isAfter(moment(dateFrom));
        if (!dateToIsAfterDateFrom) {
          setDisableNextButton(true);
        } else {
          setDisableNextButton(false);
        }
      } else {
        setDisableNextButton(false);
      }
    } else if (currentStep === 2) {
      if (selectedResponders?.length === 0) {
        setDisableNextButton(true);
      } else {
        setDisableNextButton(false);
      }
    } else {
      setDisableNextButton(false);
    }
  }, [currentStep, dateFrom, dateTo, selectedResponders]);

  const handleDateFromChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (val: string | null): void => {
    const formattedDate = moment(val).format();
    if (formattedDate !== 'Invalid date') {
      const lastHour = moment(val).set({ hour: 23, minute: 59 }).format();
      setDateTo(lastHour);
    } else {
      setDateTo(null);
    }
  };

  useEffect(() => {
    if (activeEvalObj != null) {
      const summaryObj = {
        projectName: '',
        phaseName: '',
        companyName: '',
      };
      if (activeEvalObj?.evaluationType?.id === 2) {
        setIsProjectEval(true);
      }
      if (typeof activeEvalObj?.project?.name === 'string') {
        summaryObj.projectName = activeEvalObj?.project?.name;
      }
      if (typeof activeEvalObj?.supplier?.name === 'string') {
        summaryObj.companyName = activeEvalObj?.supplier?.name;
      }

      if (typeof activeEvalObj?.evaluationStatus?.description === 'string') {
        summaryObj.phaseName = activeEvalObj?.evaluationStatus?.description;
      }
      setEvaluationSummary(summaryObj);
      if (typeof activeEvalObj?.evaluationEnd === 'string') {
        setDateTo(moment.utc(activeEvalObj?.evaluationEnd).local().format(dateFormatInitDate));
      }
      if (typeof activeEvalObj?.evaluationStart === 'string') {
        setDateFrom(moment.utc(activeEvalObj?.evaluationStart).local().format(dateFormatInitDate));
      }
      if (activeEvalObj?.project?.projectId !== null) {
        setSelectedProject(activeEvalObj?.project?.projectId.toString());
      }
      if (activeEvalObj?.project?.clientId !== null) {
        setSelectedCompany(activeEvalObj?.project?.clientId.toString());
      }
      if (activeEvalObj?.evaluationType?.id !== null) {
        // set eval type
        setSelectedType(activeEvalObj?.evaluationType);
      }
      if (activeEvalObj?.projectPhase?.id !== null) {
        // set project phase
        setSelectedProjectPhase(activeEvalObj?.projectPhase?.id.toString());
      }

      if (
        activeEvalObj?.responders !== null &&
        typeof activeEvalObj?.responders?.length !== 'undefined' &&
        activeEvalObj?.responders?.length > 0
      ) {
        // set responders
        setSelectedResponders(activeEvalObj?.responders);
      }
      if (
        activeEvalObj?.suppliers !== null &&
        typeof activeEvalObj?.suppliers?.length !== 'undefined' &&
        activeEvalObj?.suppliers?.length > 0
      ) {
        // set cats
        setSelectedSuppliersWithCats(activeEvalObj?.suppliers);
      }
    }
  }, [activeEvalObj]);

  const renderSummaryStep = (): JSX.Element | null => {
    if (isProjectEval) {
      return (
        <TotalSummaryStepProject
          evaluationSummary={evaluationSummary}
          dateFrom={dateFrom}
          dateTo={dateTo}
          selectedType={selectedType}
          selectedProjectPhase={selectedProjectPhase}
          selectedSuppliersWithCats={selectedSuppliersWithCats}
          allSuppliers={allSuppliers}
          editId={activeEvalObj?.id}
        />
      );
    } else {
      return (
        <TotalSummaryStep
          evaluationSummary={evaluationSummary}
          dateFrom={dateFrom}
          dateTo={dateTo}
          selectedType={selectedType}
          selectedProjectPhase={selectedProjectPhase}
          selectedSuppliersWithCats={selectedSuppliersWithCats}
          allSuppliers={allSuppliers}
          editId={activeEvalObj?.id}
        />
      );
    }
  };

  const renderCurrentCardContent = (): JSX.Element | null => {
    switch (currentStep) {
      case 1:
        return (
          <div className='evalCardContentWrap'>
            <RenderEvaluationSummary
              showDates={false}
              showPhase={true}
              evaluationSummary={evaluationSummary}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
            <Typography sx={{ marginBottom: 0, marginTop: '15px', letterSpacing: '0.46px', lineHeight: '26px' }}>
              EVALUERINGSPERIODE*
            </Typography>
            <div className='editEvalDatesContainer'>
              <TextField
                className='dateSelectorCreate'
                value={typeof dateFrom === 'string' ? moment(dateFrom).format(dateFormat) : undefined}
                disabled
                onChange={handleDateFromChange}
                id='filter-date-create-from'
                label='Evaluering start'
                type='input'
                variant='standard'
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <DesktopDatePicker
                minDate={dateFrom !== null ? moment(dateFrom).format() : null}
                open={showDateToPicker}
                inputFormat={dateFormat}
                label='Evaluering slutt'
                onOpen={() => setShowDateToPicker(true)}
                onClose={() => setShowDateToPicker(false)}
                value={dateTo}
                onChange={(newValue) => {
                  handleDateToChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='standard'
                    className='dateSelectorCreate'
                    onClick={() => setShowDateToPicker(true)}
                  />
                )}
              />
            </div>
          </div>
        );
      case 2:
        return (
          <SelectRecipientsStep
            evaluationSummary={evaluationSummary}
            dateFrom={dateFrom}
            dateTo={dateTo}
            selectedResponders={selectedResponders}
            setSelectedResponders={setSelectedResponders}
            showPhase={true}
            showDates={true}
            isEditForm={true}
            evalId={activeEvalObj?.id}
          />
        );
      case 3:
        return renderSummaryStep();

      default:
        return null;
    }
  };

  return <div className='evalCardContentContainer'>{renderCurrentCardContent()}</div>;
};

export default EditEvaluationForm;
