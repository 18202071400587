import { StaticMenuItem } from 'react-app-env';

export interface AppSettings {
  environment: string;
  isDevelopment: boolean;
  isStaging: boolean;
  isProduction: boolean;
  auth: {
    clientId: string;
    authority: string;
    redirectUrl: string;
    postLogoutUrl: string;
    loginScopes: string[];
    apiScopes: string[];
  };
  appInsights: {
    [x: string]: string | undefined;
    connectionString: string;
  };
  logging: {
    logLevel: 'debug' | 'information' | 'warning' | 'error';
  };
  api: {
    host: string;
  };
  build: {
    sourceBranch: string;
    commit: string;
    buildId: string;
    buildNumber: string;
  };
  release: {
    releaseName: string;
  };
  staticMenuItems: StaticMenuItem[];
}

export const appSettings: AppSettings = {
  environment: window.AppSettings.environment,
  isDevelopment: window.AppSettings.environment === 'Development',
  isStaging: window.AppSettings.environment === 'Staging',
  isProduction: window.AppSettings.environment === 'Production',
  auth: {
    clientId: window.AppSettings.auth.clientId,
    authority: window.AppSettings.auth.authority,
    redirectUrl: window.AppSettings.auth.redirectUrl,
    postLogoutUrl: window.AppSettings.auth.logoutRedirectUrl,
    loginScopes: window.AppSettings.auth.loginScopes?.split(','),
    apiScopes: window.AppSettings.auth.apiScopes?.split(','),
  },
  appInsights: {
    connectionString: window.AppSettings.applicationInsights.connectionString,
  },
  logging: {
    logLevel: window.AppSettings.logging.logLevel,
  },
  api: {
    host: window.AppSettings.api.baseUrl,
  },
  build: {
    sourceBranch: window.AppSettings.build.sourceBranch,
    commit: window.AppSettings.build.commit,
    buildId: window.AppSettings.build.buildId,
    buildNumber: window.AppSettings.build.buildNumber,
  },
  release: {
    releaseName: window.AppSettings.release.releaseName,
  },
  staticMenuItems: window.AppSettings.staticMenuItems,
};

export default appSettings;
