import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme, Typography, ButtonBase, Box, useMediaQuery, Pagination } from '@mui/material';
import NoResultsSvg from 'assets/NoResultsIcon/NoResultsIcon';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import EvaluationsSkeleton from './EvaluationsSkeleton';
import moment from 'moment';
import { useApi } from 'hooks/useApi/useApi';
import SettingsIcon from '@mui/icons-material/Settings';
import HandymanIcon from '@mui/icons-material/Handyman';
import {
  selectedDateFromState,
  selectedDateToState,
  selectedScopeState,
  selectedOwnershipState,
  selectedStateOfSelectedCompanies,
  selectedPhasesState,
  selectedStatusesState,
  selectedSupplierStatusesState,
  paginatedEvaluationsSelector,
  selectedPageState,
  selectedTextSearchState,
  manuallyRefreshEvaluationsListSelector,
} from 'util/selectors';
import { setSelectedEvaluationFilterPage, setPaginatedEvaluations, setManuallyRefreshEvaluationsList } from 'redux/actions/evaluations';
import { toast } from 'react-toastify';
import './styles.scss';
import { dateFormatApi } from 'util/constants';

ChartJS.register(ArcElement, Tooltip);

interface RenderEvaluationsProps {
  setShowEvaluationFormModal: Function;
}

const RenderEvaluations: React.FC<RenderEvaluationsProps> = ({ setShowEvaluationFormModal }: RenderEvaluationsProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { getEvaluationsCall } = useApi();
  const paginatedEvaluations = useSelector(paginatedEvaluationsSelector);
  // filters
  const selectedScope = useSelector(selectedScopeState);
  const selectedOwnership = useSelector(selectedOwnershipState);
  const selectedCompanies = useSelector(selectedStateOfSelectedCompanies);
  const selectedPhases = useSelector(selectedPhasesState);
  const selectedStatuses = useSelector(selectedStatusesState);
  const selectedSupplierStatuses = useSelector(selectedSupplierStatusesState);
  const selectedDateFrom = useSelector(selectedDateFromState);
  const selectedDateTo = useSelector(selectedDateToState);
  const selectedPage = useSelector(selectedPageState);
  const selectedTextSeachStatus = useSelector(selectedTextSearchState);

  const setSelectedPage = (val: number): void => {
    dispatch(setSelectedEvaluationFilterPage(val));
  };

  const handleSetManuallyRefreshEvalList = (val: boolean): void => {
    dispatch(setManuallyRefreshEvaluationsList(val));
  };

  const theme = useTheme();
  const isMdWidth = useMediaQuery(theme.breakpoints.up('md'));
  const refreshEvalList = useSelector(manuallyRefreshEvaluationsListSelector);

  const params = {
    fromDate: selectedDateFrom !== null ? moment(selectedDateFrom).format(dateFormatApi) : null,
    toDate: selectedDateTo !== null ? moment(selectedDateTo).format(dateFormatApi) : null,
    evaluationOwner: selectedOwnership?.key,
    companyId: selectedCompanies.map((item) => item.id),
    evaluationTypeId: selectedScope.map((item) => item.id),
    evaluationStatus: selectedStatuses.map((item) => item.id),
    projectPhases: selectedPhases.map((item) => item.id),
    supplierCategoryId: selectedSupplierStatuses.map((item) => item.id),
    pageNumber: selectedPage,
    pageSize: 8,
    SeachString: selectedTextSeachStatus,
  };

  useEffect(() => {
    setLoading(true);
    getEvaluationsCall(params)
      .then((response) => {
        dispatch(setPaginatedEvaluations(response));
      })
      .catch((err) => {
        if (typeof err === 'string') {
          toast.error(err);
        } else {
          toast.error('Klarte ikke å hente evalueringer.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    selectedPage,
    selectedOwnership,
    selectedCompanies,
    selectedScope,
    selectedStatuses,
    selectedPhases,
    selectedSupplierStatuses,
    selectedDateFrom,
    selectedDateTo,
  ]);

  useEffect(() => {
    if (refreshEvalList) {
      setLoading(true);
      getEvaluationsCall(params)
        .then((response) => {
          dispatch(setPaginatedEvaluations(response));
        })
        .catch((err) => {
          if (typeof err === 'string') {
            toast.error(err);
          } else {
            toast.error('Klarte ikke å hente evalueringer på nytt.');
          }
        })
        .finally(() => {
          setLoading(false);
          handleSetManuallyRefreshEvalList(false);
        });
    }
  }, [refreshEvalList]);

  const renderDoughnut = (total: number, answered: number): JSX.Element | null => {
    const notAnswered = total - answered;
    if (!isNaN(total) && !isNaN(answered)) {
      return (
        <Doughnut
          width={120}
          height={120}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            cutout: 48,
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
          data={{
            labels: ['Ikke svart', 'Svart'],
            datasets: [
              {
                data: [notAnswered, answered],
                backgroundColor: [theme.palette.secondary.main, theme.palette.primary.main],
                borderColor: [theme.palette.secondary.main, theme.palette.primary.main],
                hoverOffset: 2,
                borderWidth: 0,
              },
            ],
          }}
        />
      );
    }
    return null;
  };

  const renderTextInsideDoghnut = (total: number, answered: number): JSX.Element | null => {
    if (!isNaN(total) && !isNaN(answered)) {
      return (
        <div style={{ position: 'absolute', width: '100%', top: '50%', left: 0, textAlign: 'center', marginTop: '-15px' }}>
          <span>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '1rem',
                lineHeight: '26px',
                letterSpacing: '0.46px',
              }}
            >{`${answered} / ${total} `}</Typography>
          </span>
        </div>
      );
    }
    return null;
  };

  const findEvaluationIcon = (projectTypeId: number): JSX.Element | null => {
    if (projectTypeId === 1) {
      return <HandymanIcon fontSize='small' htmlColor={theme.palette.text.secondary} />;
    } else if (projectTypeId === 2) {
      return <SettingsIcon fontSize='small' htmlColor={theme.palette.text.secondary} />;
    } else {
      return null;
    }
  };

  const renderEvaluationContent = (isProject: boolean, evaluationItem: EvaluationsState): JSX.Element | null => {
    if (isProject) {
      return (
        <div>
          <Typography
            sx={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '0.9rem', letterSpacing: '0.46px', lineHeight: '26px' }}
          >
            {evaluationItem?.project?.name}
          </Typography>

          <div className={isMdWidth ? 'evalContentContainer' : 'evalContentContainerSmall'}>
            <div className='evalTextContainer'>
              <div className='evalRowContainer'>
                <div className='evalBoxLeft'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>Prosjektfase:</Typography>
                </div>
                <div className='evalBoxMiddle'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>
                    {evaluationItem?.projectPhase?.name}
                  </Typography>
                </div>
              </div>

              <div className='evalRowContainer'>
                <div className='evalBoxLeft'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>Sluttdato:</Typography>
                </div>
                <div className='evalBoxMiddle'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>
                    {evaluationItem?.evaluationEnd !== null ? moment.utc(evaluationItem?.evaluationEnd).local().format('DD.MM.YYYY') : ''}
                  </Typography>
                </div>
              </div>

              <div className='evalRowContainer'>
                <div className='evalBoxLeft'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>Status:</Typography>
                </div>
                <div className='evalBoxMiddle'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>
                    {' '}
                    {evaluationItem?.evaluationStatus?.description}
                  </Typography>
                </div>
              </div>
            </div>
            <div className='evalPieContainer'>
              {renderDoughnut(evaluationItem?.respondersCount, evaluationItem?.completedRespondersCount)}
              {renderTextInsideDoghnut(evaluationItem?.respondersCount, evaluationItem?.completedRespondersCount)}
            </div>
          </div>
        </div>
      );
    } else {
      // else it is a supplier evaluation
      return (
        <div>
          <Typography
            sx={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '0.9rem', letterSpacing: '0.46px', lineHeight: '26px' }}
          >
            {evaluationItem?.supplier?.name}
          </Typography>

          <div className={isMdWidth ? 'evalContentContainer' : 'evalContentContainerSmall'}>
            <div className='evalTextContainer'>
              {typeof evaluationItem?.supplierCategory?.name === 'string' ? (
                <div className='evalRowContainer'>
                  <div className='evalBoxLeft'>
                    <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>Kategori:</Typography>
                  </div>
                  <div className='evalBoxMiddle'>
                    <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>
                      {evaluationItem?.supplierCategory?.name}
                    </Typography>
                  </div>
                </div>
              ) : null}

              <div className='evalRowContainer'>
                <div className='evalBoxLeft'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>Prosjekt:</Typography>
                </div>
                <div className='evalBoxMiddle'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>
                    {evaluationItem?.project?.name}
                  </Typography>
                </div>
              </div>

              <div className='evalRowContainer'>
                <div className='evalBoxLeft'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>Prosjektfase:</Typography>
                </div>
                <div className='evalBoxMiddle'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>
                    {evaluationItem?.projectPhase?.name}
                  </Typography>
                </div>
              </div>

              <div className='evalRowContainer'>
                <div className='evalBoxLeft'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>Sluttdato:</Typography>
                </div>
                <div className='evalBoxMiddle'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>
                    {evaluationItem?.evaluationEnd !== null ? moment.utc(evaluationItem?.evaluationEnd).local().format('DD.MM.YYYY') : ''}
                  </Typography>
                </div>
              </div>

              <div className='evalRowContainer'>
                <div className='evalBoxLeft'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>Status:</Typography>
                </div>
                <div className='evalBoxMiddle'>
                  <Typography sx={{ fontSize: '14px', letterSpacing: '0.46px', lineHeight: '26px' }}>
                    {evaluationItem?.evaluationStatus?.description}
                  </Typography>
                </div>
              </div>
            </div>
            <div className='evalPieContainer'>
              {renderDoughnut(evaluationItem?.respondersCount, evaluationItem?.completedRespondersCount)}
              {renderTextInsideDoghnut(evaluationItem?.respondersCount, evaluationItem?.completedRespondersCount)}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderEvalsList = (evalsArr: EvaluationsState[]): JSX.Element | null => {
    if (evalsArr?.length === 0) {
      return (
        <div className='noResultsImgContainer'>
          <NoResultsSvg width={isMdWidth ? '370px' : '200px'} height={isMdWidth ? '370px' : '200px'} />
          <Typography sx={{ fontSize: '25px', letterSpacing: '0.46px', lineHeight: '26px', paddingTop: '30px' }}>
            Beklager, ingen treff
          </Typography>
        </div>
      );
    }

    return (
      <div className='evaluationsWrapper'>
        {evalsArr.map((item) => {
          const isProject = item?.evaluationType?.id === 2;
          return (
            <div key={item.id} className='evaluationContainer'>
              <div className='evaluationTitle'>
                <div className='iconAndTitle'>
                  <div className='evaluationIconContainer'>{findEvaluationIcon(item?.evaluationType?.id)}</div>
                  <Typography variant='body1' align='center' color='text.secondary' sx={{ textTransform: 'uppercase', fontSize: '0.9rem' }}>
                    {item?.evaluationType?.name}
                  </Typography>
                </div>
                <ButtonBase onClick={() => setShowEvaluationFormModal(true, item)}>
                  <Typography sx={{ textDecoration: 'underline', fontSize: '0.9rem' }} color='text.secondary'>
                    Rediger
                  </Typography>
                </ButtonBase>
              </div>

              <Box
                sx={{
                  pl: { xs: 1, md: 6 },
                  pr: { xs: 1, md: 6 },
                  pt: { xs: 1, md: 2 },
                  pb: { xs: 1, md: 2 },
                }}
                className='evaluationInfoContainer'
              >
                {renderEvaluationContent(isProject, item)}
              </Box>
            </div>
          );
        })}
      </div>
    );
  };

  if (loading) {
    return <EvaluationsSkeleton />;
  }

  return (
    <div className='flex1'>
      {renderEvalsList(paginatedEvaluations?.data)}
      {paginatedEvaluations?.data?.length > 0 ? (
        <div className='paginationContainer'>
          <Pagination
            color='primary'
            count={paginatedEvaluations?.totalPages}
            page={selectedPage}
            onChange={(event, newPage) => {
              setSelectedPage(newPage);
            }}
            showFirstButton
            showLastButton
          />
        </div>
      ) : null}
    </div>
  );
};

export default RenderEvaluations;
