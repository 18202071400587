import { useTheme, ButtonBase, useMediaQuery, Modal, Typography, Box, Button } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';

interface DeleteModalProps {
  open: boolean;
  setOpen: Function;
  onDeleteAction: Function;
  loadingDelete: boolean;
  titleString: string;
  questionString: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  setOpen,
  onDeleteAction,
  loadingDelete,
  titleString,
  questionString,
}: DeleteModalProps): JSX.Element | null => {
  // div hooks etc
  const theme = useTheme();
  const isMdWidth = useMediaQuery(theme.breakpoints.up('md'));

  const handleClose = (): void => setOpen(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 420,
    bgcolor: theme.palette.text.secondary,
    boxShadow: 24,
  };

  const styleSmall = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: theme.palette.text.secondary,
    boxShadow: 24,
  };

  const renderFormHeader = (): JSX.Element | null => {
    return (
      <div className='evaluationTitleModal'>
        <div></div>
        <div className='iconAndTitle'>
          <div className='evaluationIconContainer'>
            <DeleteOutlineIcon fontSize='small' htmlColor={theme.palette.text.secondary} />
          </div>
          <Typography
            variant='body1'
            align='center'
            color='text.secondary'
            sx={{ textTransform: 'uppercase', fontSize: '0.9rem', padding: 0, margin: 0, lineHeight: '30px' }}
          >
            {titleString}
          </Typography>
        </div>

        <ButtonBase onClick={() => setOpen(false)}>
          <div className='evalModalCloseButton'>
            <CloseIcon fontSize='medium' htmlColor={theme.palette.text.secondary} />
          </div>
        </ButtonBase>
      </div>
    );
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box sx={isMdWidth ? style : styleSmall}>
          <div className='evalModalDeleteContainer'>
            {renderFormHeader()}
            <div className='deleteModalContentWrapper'>
              <div className='deleteTitle'>
                <Typography
                  sx={{ textAlign: 'center', paddingBottom: '24px', paddingTop: '24px', letterSpacing: '0.46px', lineHeight: '26px' }}
                >
                  {questionString}
                </Typography>
              </div>
              <div className='actionButtons'>
                <Button disabled={loadingDelete} onClick={handleClose} color='primary' variant='outlined' sx={{ margin: '4px' }}>
                  Avbryt
                </Button>
                <LoadingButton
                  loading={loadingDelete}
                  onClick={() => onDeleteAction()}
                  color='secondary'
                  variant='contained'
                  sx={{ margin: '4px' }}
                >
                  Slett
                </LoadingButton>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteModal;
