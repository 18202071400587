import { useTheme, ButtonBase, Typography, TextField, FormControl, MenuItem, Skeleton } from '@mui/material';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useApi } from 'hooks/useApi/useApi';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { evaluationFiltersSelector } from 'util/selectors';
import { setSelectedRespondersAction } from 'redux/actions/evaluationForm';
import { dateFormat } from 'util/constants';

interface EvaluationSetupProps {
  selectedType: IEvaluationTypeGetModel | undefined;
  setSelectedType: Function;
  selectedCompany: string;
  setSelectedCompany: Function;
  selectedProject: string;
  setSelectedProject: Function;
  selectedProjectPhase: string;
  setSelectedProjectPhase: Function;
  dateFrom: string;
  setDateFrom: Function;
  dateTo: string;
  setDateTo: Function;
  setSelectedSuppliersIds?: Function;
  evaluationSummary: EvaluationSummaryObject;
  setEvaluationSummary: Function;
}

const EvaluationSetupStep: React.FC<EvaluationSetupProps> = ({
  selectedType,
  setSelectedType,
  selectedCompany,
  setSelectedCompany,
  selectedProject,
  setSelectedProject,
  selectedProjectPhase,
  setSelectedProjectPhase,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  setSelectedSuppliersIds,
  evaluationSummary,
  setEvaluationSummary,
}: EvaluationSetupProps): JSX.Element | null => {
  const theme = useTheme();
  const evaluationFilters = useSelector(evaluationFiltersSelector);
  const { evaluationTypes } = evaluationFilters;
  const dispatch = useDispatch();
  const inputMaxWidth = 280;
  const { getCompaniesCall, getProjectsOfCompanyCall, getPhasesOfProjectsForCreateEvalCall } = useApi();

  const [allCompanies, setAllCompanies] = useState<FilterCompanyModel[]>([]);
  const [allProjects, setAllProjects] = useState<EvaluationCompanyModel[]>([]);
  const [allProjectPhases, setAllProjectPhases] = useState<IProjectPhaseGetModel[]>([]);
  const [loadingPhases, setLoadingProjectPhases] = useState<boolean>(false);
  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false);

  const [showDateFromPicker, setShowDateFromPicker] = useState<boolean>(false);
  const [showDateToPicker, setShowDateToPicker] = useState<boolean>(false);

  useEffect(() => {
    // get all companies for select
    if (typeof allCompanies === 'undefined' || allCompanies?.length === 0) {
      setLoadingCompanies(true);
      getCompaniesCall()
        .then((response) => {
          setAllCompanies(response);
        })
        .catch((err) => {
          if (typeof err?.response?.data?.detail === 'string') {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error('Klarte ikke å hente firmaliste.');
          }
        })
        .finally(() => {
          setTimeout(() => setLoadingCompanies(false), 500);
        });
    }
  }, [evaluationFilters]);

  useEffect(() => {
    // preset evaluation dates to default dates
    const dateNow = moment().format();
    const dateInTwoWeeks = moment().add('2', 'weeks').format();
    if (dateFrom === null) {
      setDateFrom(dateNow);
    }
    if (dateTo === null) {
      setDateTo(dateInTwoWeeks);
    }
  }, []);

  useEffect(() => {
    // get all projects of company
    if (selectedCompany?.length > 0) {
      setLoadingProjects(true);
      getProjectsOfCompanyCall(selectedCompany)
        .then((response) => {
          setAllProjects(response);
        })
        .catch((err) => {
          if (typeof err?.response?.data?.detail === 'string') {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error('Klarte ikke å hente prosjektliste av firma.');
          }
        })
        .finally(() => {
          setTimeout(() => setLoadingProjects(false), 500);
        });
    }
  }, [selectedCompany]);

  useEffect(() => {
    // get all project phases
    if (selectedProject?.length > 0 && typeof selectedType?.id === 'number') {
      setLoadingProjectPhases(true);
      getPhasesOfProjectsForCreateEvalCall(selectedType?.id)
        .then((response) => {
          setAllProjectPhases(response);
        })
        .catch((err) => {
          if (typeof err?.response?.data?.detail === 'string') {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error('Klarte ikke å hente  prosjektfaser.');
          }
        })
        .finally(() => {
          setTimeout(() => setLoadingProjectPhases(false), 500);
        });
    }
  }, [selectedProject, selectedType]);

  const handleClickType = (radioItem: IEvaluationTypeGetModel): void => {
    setSelectedType(radioItem);
  };

  const renderPlaceHolderInput = (): JSX.Element => {
    return (
      <FormControl variant='standard' sx={{ m: 1, minWidth: 120, maxWidth: inputMaxWidth }}>
        <Select disabled labelId='create-eval-placeholder' id='create-eval-placeholder'></Select>
      </FormControl>
    );
  };

  const handleDateFromChange = (val: string | null): void => {
    const formattedDate = moment(val).format();
    if (formattedDate !== 'Invalid date') {
      setDateFrom(formattedDate);
    } else {
      setDateFrom(null);
    }
  };

  const handleDateToChange = (val: string | null): void => {
    const formattedDate = moment(val).format();

    if (formattedDate !== 'Invalid date') {
      setDateTo(formattedDate);
    } else {
      setDateTo(null);
    }
  };

  const handleCompanyChange = (event: SelectChangeEvent): void => {
    setSelectedCompany(event.target.value);
    // reset projects and suppliers if they were selected
    setAllProjects([]);
    setSelectedProject('');
    if (setSelectedSuppliersIds != null) {
      setSelectedSuppliersIds([]);
    }

    // reset selected contact people
    dispatch(setSelectedRespondersAction([]));

    // add company to summary for step 4
    const companyFound = allCompanies.find((item) => item.id.toString() === event.target.value);
    if (companyFound !== null) {
      const newState = {
        ...evaluationSummary,
        companyName: companyFound?.name,
      };
      setEvaluationSummary(newState);
    }
  };

  const handleProjectChange = (event: SelectChangeEvent): void => {
    setSelectedProject(event.target.value);
    // add project to summary for step 4
    const projectFound = allProjects.find((item) => item?.projectId.toString() === event.target.value);
    if (projectFound !== null) {
      const newState = {
        ...evaluationSummary,
        projectName: projectFound?.name,
      };
      setEvaluationSummary(newState);
    }
  };

  const handleProjectPhaseChange = (event: SelectChangeEvent): void => {
    setSelectedProjectPhase(event.target.value);
    // add project phase name for step 4
    const phaseFound = allProjectPhases.find((item) => item?.id === parseInt(event.target.value));
    if (phaseFound !== null) {
      const newState = {
        ...evaluationSummary,
        phaseName: phaseFound?.name,
      };
      setEvaluationSummary(newState);
    }
  };

  const renderCompaniesSelect = (): JSX.Element => {
    if (loadingCompanies) {
      return <Skeleton variant='rectangular' height={32} sx={{ marginRight: '8px', marginTop: '15px', maxWidth: inputMaxWidth }} />;
    } else if (allCompanies?.length > 0) {
      return (
        <FormControl variant='standard' sx={{ m: 1, minWidth: 120, maxWidth: inputMaxWidth }}>
          <Select
            labelId='create-eval-company'
            id='create-eval-company'
            value={selectedCompany}
            onChange={handleCompanyChange}
            label='VELG SELSKAP*'
          >
            {allCompanies?.map((companyItem) => (
              <MenuItem key={companyItem?.id} value={companyItem.id}>
                {companyItem?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return renderPlaceHolderInput();
    }
  };

  const renderProjectsSelect = (): JSX.Element => {
    if (loadingProjects) {
      return <Skeleton variant='rectangular' height={32} sx={{ marginRight: '8px', marginTop: '15px', maxWidth: inputMaxWidth }} />;
    } else if (allProjects?.length > 0) {
      return (
        <FormControl variant='standard' sx={{ m: 1, minWidth: 120, maxWidth: inputMaxWidth }}>
          <Select
            labelId='create-eval-project'
            id='create-eval-project'
            value={selectedProject}
            onChange={handleProjectChange}
            label='VELG PROSJEKT*'
          >
            {allProjects?.map((projectItem) => (
              <MenuItem key={projectItem?.projectId} value={projectItem.projectId}>
                {projectItem?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return renderPlaceHolderInput();
    }
  };
  const renderPhasesSelect = (): JSX.Element => {
    if (loadingPhases) {
      return <Skeleton variant='rectangular' height={32} sx={{ marginRight: '8px', marginTop: '15px', maxWidth: inputMaxWidth }} />;
    } else if (allProjectPhases?.length > 0) {
      return (
        <FormControl variant='standard' sx={{ m: 1, minWidth: 120, maxWidth: inputMaxWidth }}>
          <Select
            labelId='create-eval-projectPh'
            id='create-eval-projectPh'
            value={selectedProjectPhase}
            onChange={handleProjectPhaseChange}
            label='VELG FASE*'
          >
            {allProjectPhases?.map((phaseItem) => (
              <MenuItem key={phaseItem?.id} value={phaseItem.id}>
                {phaseItem?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return renderPlaceHolderInput();
    }
  };

  return (
    <div className='evalItemInner'>
      <RadioGroup name='new-evaluation-form'>
        {evaluationTypes?.map((radioItem) => {
          return (
            <ButtonBase key={radioItem.id} onClick={() => handleClickType(radioItem)} className='radioButtonItemEval'>
              <FormControlLabel
                value={radioItem.id}
                control={<Radio sx={{ color: theme?.palette?.primary?.main }} checked={selectedType?.id === radioItem.id} />}
                label={radioItem.name}
                sx={{ textTransform: 'uppercase' }}
              />
            </ButtonBase>
          );
        })}
      </RadioGroup>
      <Typography sx={{ margin: '8px', marginBottom: 0, marginTop: '15px', letterSpacing: '0.46px', lineHeight: '26px' }}>
        VELG SELSKAP*
      </Typography>
      {renderCompaniesSelect()}
      <Typography sx={{ margin: '8px', marginBottom: 0, marginTop: '15px', letterSpacing: '0.46px', lineHeight: '26px' }}>
        VELG PROSJEKT*
      </Typography>
      {renderProjectsSelect()}
      <Typography sx={{ margin: '8px', marginBottom: 0, marginTop: '15px', letterSpacing: '0.46px', lineHeight: '26px' }}>
        VELG PROSJEKTFASE*
      </Typography>
      {renderPhasesSelect()}
      <Typography sx={{ margin: '8px', marginBottom: 0, marginTop: '15px', letterSpacing: '0.46px', lineHeight: '26px' }}>
        EVALUERINGSPERIODE*
      </Typography>
      <div className='createEvalDatesContainer'>
        <DesktopDatePicker
          open={showDateFromPicker}
          onOpen={() => setShowDateFromPicker(true)}
          onClose={() => setShowDateFromPicker(false)}
          inputFormat={dateFormat}
          label='Evaluering start'
          maxDate={dateTo !== null ? dateTo : null}
          value={dateFrom}
          minDate={moment().format()}
          onChange={(newValue) => {
            handleDateFromChange(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              variant='standard'
              className='dateSelectorCreate'
              onClick={() => setShowDateFromPicker(true)}
            />
          )}
        />
        <DesktopDatePicker
          open={showDateToPicker}
          onOpen={() => setShowDateToPicker(true)}
          onClose={() => setShowDateToPicker(false)}
          minDate={dateFrom !== null ? dateFrom : null}
          inputFormat={dateFormat}
          label='Evaluering slutt'
          value={dateTo}
          onChange={(newValue) => {
            handleDateToChange(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
              variant='standard'
              className='dateSelectorCreate'
              onClick={() => setShowDateToPicker(true)}
            />
          )}
        />
      </div>
    </div>
  );
};

export default EvaluationSetupStep;
