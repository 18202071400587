/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTheme, Typography, useMediaQuery, Skeleton, TextField, Button, Checkbox } from '@mui/material';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'hooks/useApi/useApi';
import { useGraph } from 'hooks/useGraph/useGraph';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles.scss';
import RenderEvaluationSummary from '../RenderEvaluationSummary';
import BackDropLoading from 'components/shared/BackdropLoading';
import ModalSelectMoreRecipients from './ModalSelectMoreRecipients';
import { evalFormAllAvailableRecipientsSelector, selectedCompanySelector, evalFormProjectSelector } from 'util/selectors';
import { setAllAvailableRecipientsAction } from 'redux/actions/evaluationForm';

interface EvaluationSummaryProps {
  evaluationSummary?: EvaluationSummaryObject;
  dateFrom: string | null;
  dateTo: string | null;
  selectedResponders: EvaluationRecipientObject[];
  setSelectedResponders: Function;
  showDates: boolean;
  showPhase: boolean;
  isEditForm: boolean;
  evalId?: number | undefined;
}

const SelectRecipientsStep: React.FC<EvaluationSummaryProps> = ({
  evaluationSummary,
  dateFrom,
  dateTo,
  selectedResponders,
  setSelectedResponders,
  showDates,
  showPhase,
  isEditForm,
  evalId,
}: EvaluationSummaryProps): JSX.Element | null => {
  const { getProjectMembersCall } = useApi();
  const { getUserForEvaluations } = useGraph();
  const theme = useTheme();
  const isMdWidth = useMediaQuery(theme.breakpoints.up('md'));
  const [loadingRecipients, setLoadingRecipients] = useState<boolean>(false);
  // user search from microsoft
  const [userNameToFind, setUserNameToFind] = useState<string | undefined>(undefined);
  const [loadingUserSearch, setLoadingUserSearch] = useState<boolean>(false);
  const [allUsersFromSearch, setAllUsersFromSearch] = useState<UserSearchFromMicrosoftObject[]>([]);
  const [showUserSearchSelectModal, setShowUserSearchSelectModal] = useState<boolean>(false);
  const [selectedUsersExtra, setSelectedUsersExtra] = useState<UserSearchFromMicrosoftObject[]>([]);

  // redux
  const dispatch = useDispatch();
  const allRecipients = useSelector(evalFormAllAvailableRecipientsSelector);

  const setAllRecipients = (val: EvaluationRecipientObject[]): void => {
    dispatch(setAllAvailableRecipientsAction(val));
  };

  const selectedCompany = useSelector(selectedCompanySelector);
  const selectedProject = useSelector(evalFormProjectSelector);

  const handleGetUsersCall = (): void => {
    if (typeof userNameToFind === 'string') {
      setLoadingUserSearch(true);
      getUserForEvaluations(userNameToFind)
        .then(({ value }) => {
          if (value?.length > 0) {
            setAllUsersFromSearch(value);
          } else {
            setAllUsersFromSearch([]);
          }
          setShowUserSearchSelectModal(true);
        })
        .catch((err) => {
          if (typeof err?.response?.data?.detail === 'string') {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error('Klarte ikke å hente bruker.');
          }
        })
        .finally(() => {
          setLoadingUserSearch(false);
        });
    }
  };

  useEffect(() => {
    // get members for select
    if (
      typeof selectedCompany !== 'undefined' &&
      selectedCompany?.length > 0 &&
      typeof selectedProject !== 'undefined' &&
      selectedProject?.length > 0 &&
      allRecipients?.length === 0
    ) {
      setLoadingRecipients(true);
      const evalIdStr = typeof evalId === 'number' ? evalId.toString() : '';
      getProjectMembersCall(selectedCompany, selectedProject, evalIdStr)
        .then((response: any) => {
          setAllRecipients(response);
        })
        .catch((err) => {
          if (typeof err?.response?.data?.detail === 'string') {
            toast.error(err?.response?.data?.detail);
          } else {
            toast.error('Klarte ikke å hente prosjektmedlemmer.');
          }
        })
        .finally(() => {
          setTimeout(() => setLoadingRecipients(false), 500);
        });
    }
  }, [selectedProject, selectedCompany]);

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUserNameToFind(event.target.value);
  };

  const handleSearchClickAction = (): void => {
    handleGetUsersCall();
  };

  const listRecipients = (): JSX.Element | null => {
    const _responderToggle = (userId: string, isMarked: boolean, userItem: EvaluationRecipientObject): void => {
      if (userId !== null && isMarked) {
        setSelectedResponders([...selectedResponders, userItem]);
      } else {
        const filteredResponders = selectedResponders.filter((item) => item?.userId !== userId);
        setSelectedResponders(filteredResponders);
      }
    };

    if (allRecipients?.length > 0) {
      return (
        <div>
          {allRecipients.map((item: EvaluationRecipientObject) => {
            const isItemChecked = selectedResponders.find((responderItem) => responderItem?.userId === item?.userId) != null;
            return (
              <div key={item?.userId} className='evalRecipientLine'>
                <Checkbox
                  sx={{ color: theme?.palette?.primary?.main }}
                  value={item?.userId}
                  onChange={({ target }) => _responderToggle(target.value, target.checked, item)}
                  checked={isItemChecked}
                />
                <Typography sx={{ alignSelf: 'center', marginLeft: '5px', marginBottom: 0, letterSpacing: '0.46px', lineHeight: '26px' }}>
                  {item?.displayName}
                </Typography>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const findStyleOfRecipientsBox = (): string => {
    if (isEditForm) {
      if (isMdWidth) {
        return 'evalRecipientsContainerEdit';
      } else {
        return 'evalRecipientsContainerMobileEdit';
      }
    } else if (!isEditForm && isMdWidth) {
      return 'evalRecipientsContainer';
    } else {
      return 'evalRecipientsContainerMobile';
    }
  };

  const renderStepContent = (): JSX.Element | null => {
    if (loadingRecipients) {
      return <Skeleton variant='rectangular' height={160} />;
    } else {
      return (
        <div className={findStyleOfRecipientsBox()}>
          <div>{listRecipients()}</div>
        </div>
      );
    }
  };

  const handleCloseExtraSearchModal = (): void => {
    setShowUserSearchSelectModal(false);
    setAllUsersFromSearch([]);
    setSelectedUsersExtra([]);
  };

  const handleAddExtraUsersToSelectedResponders = (MSUsers: UserSearchFromMicrosoftObject[]): void => {
    if (MSUsers?.length > 0) {
      const remappedUsers = MSUsers.map((userItem: UserSearchFromMicrosoftObject) => {
        return {
          userId: userItem.id,
          email: userItem?.mail,
          userPrincipalName: userItem?.userPrincipalName,
          displayName: userItem?.displayName,
        };
      });
      const updatedAllArray = allRecipients.concat(remappedUsers);
      setAllRecipients(updatedAllArray);
      const updatedSelectedRespondersArray = selectedResponders.concat(remappedUsers);
      setSelectedResponders(updatedSelectedRespondersArray);
      handleCloseExtraSearchModal();
    }
  };

  return (
    <div className='evalRecipientsSummary'>
      <BackDropLoading open={loadingUserSearch} />
      <ModalSelectMoreRecipients
        open={showUserSearchSelectModal}
        setOpen={handleCloseExtraSearchModal}
        allUsersFromSearch={allUsersFromSearch}
        setSelectedUsersExtra={setSelectedUsersExtra}
        selectedUsersExtra={selectedUsersExtra}
        handleAddExtraUsersToSelectedResponders={handleAddExtraUsersToSelectedResponders}
      />
      <RenderEvaluationSummary
        showDates={showDates}
        showPhase={showPhase}
        evaluationSummary={evaluationSummary}
        dateFrom={dateFrom}
        dateTo={dateTo}
      />
      <Typography sx={{ marginBottom: 0, marginTop: '15px', letterSpacing: '0.46px', lineHeight: '26px' }}>VELG MOTTAKERE*</Typography>
      <div className='evalSearchUserContainer'>
        <TextField
          id='user-search'
          label='Søk etter flere'
          type='search'
          variant='standard'
          className='searchField'
          value={userNameToFind}
          onChange={handleSearchTextChange}
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
        <div className='searchButtonContainer'>
          <Button
            variant='contained'
            className='searchButton'
            onClick={handleSearchClickAction}
            disabled={userNameToFind === undefined || (typeof userNameToFind === 'string' && userNameToFind?.length <= 2)}
          >
            Søk
          </Button>
        </div>
      </div>
      {renderStepContent()}
    </div>
  );
};

export default SelectRecipientsStep;
