import { Typography, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'hooks/useApi/useApi';
import RenderEvaluationSummary from '../RenderEvaluationSummary';
import '../../styles.scss';

interface TotalSummaryStepProps {
  evaluationSummary?: EvaluationSummaryObject;
  dateFrom: string | null;
  dateTo: string | null;
  selectedType: IEvaluationTypeGetModel | undefined;
  selectedProjectPhase: string;
  selectedSuppliersWithCats: ISupplierCategoryPostModel[];
  allSuppliers: ISupplierCategoryGetModel[];
  editId?: number;
}

const TotalSummaryStepProject: React.FC<TotalSummaryStepProps> = ({
  evaluationSummary,
  dateFrom,
  dateTo,
  selectedType,
  selectedProjectPhase,
  editId,
}: TotalSummaryStepProps): JSX.Element | null => {
  const [loadingQuestions, setLoadingQuestions] = useState<boolean>(false);
  const [allQuestions, setAllQuestions] = useState<EvaluationQuestionObject[]>([]);

  const { getEvaluationQuestionsCallForProject, getEvaluationQuestionsCallEdit } = useApi();
  const theme = useTheme();
  const isMdWidth = useMediaQuery(theme.breakpoints.up('md'));

  const getCategoryQuestions = (): void => {
    if (typeof selectedType?.id !== 'undefined' && selectedType?.id > 0 && typeof selectedProjectPhase !== 'undefined') {
      setLoadingQuestions(true);

      if (typeof editId === 'number') {
        getEvaluationQuestionsCallEdit(editId)
          .then((response) => {
            setAllQuestions(response);
          })
          .catch((err) => {
            if (typeof err?.response?.data?.detail === 'string') {
              toast.error(err?.response?.data?.detail);
            } else {
              toast.error('Klarte ikke å hente spørsmål.');
            }
          })
          .finally(() => {
            setLoadingQuestions(false);
          });
      } else {
        const selectedTypeString = selectedType?.id.toString();
        getEvaluationQuestionsCallForProject(selectedTypeString, selectedProjectPhase)
          .then((response) => {
            setAllQuestions(response);
          })
          .catch((err) => {
            if (typeof err?.response?.data?.detail === 'string') {
              toast.error(err?.response?.data?.detail);
            } else {
              toast.error('Klarte ikke å hente spørsmål.');
            }
          })
          .finally(() => {
            setLoadingQuestions(false);
          });
      }
    }
  };

  useEffect(() => {
    getCategoryQuestions();
  }, []);

  const renderStepContent = (): JSX.Element | null => {
    if (loadingQuestions) {
      return <Skeleton variant='rectangular' height={210} />;
    } else if (allQuestions?.length > 0) {
      return (
        <ul className={isMdWidth ? 'questionsListContainer' : 'questionsListContainerMobile'}>
          {allQuestions.map((item) => {
            return (
              <li key={item.id}>
                <div>
                  <Typography sx={{ fontSize: '14px', lineHeight: '26px', letterSpacing: '0.46px' }}>{item.value}</Typography>
                </div>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return null;
    }
  };

  return (
    <div className='evalRecipientsSummary'>
      <RenderEvaluationSummary showDates showPhase evaluationSummary={evaluationSummary} dateFrom={dateFrom} dateTo={dateTo} />
      <Typography
        sx={{
          marginBottom: 0,
          fontWeight: 700,
          letterSpacing: '0.46px',
          lineHeight: '26px',
          marginTop: '15px',
        }}
      >
        Evalueringen inneholder:
      </Typography>
      {renderStepContent()}
    </div>
  );
};

export default TotalSummaryStepProject;
