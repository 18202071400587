import { useDispatch } from 'react-redux';
import { Button, Chip, Typography, useTheme } from '@mui/material';
import '../styles.scss';
import CloseIcon from '@mui/icons-material/Close';

interface QuestionFiltersSummaryProps {
  isProjectPage: boolean;
  selectedQuestionPhases: IProjectPhaseGetModel[];
  selectedQuestionSuppTypes?: ISupplierCategoryGetModel[];
  selectedQuestionQTypes: QuestionCategory[];
  handleSelectedPhaseChange: Function;
  handleSelectedSuppTypesChange?: Function;
  handleSelectedQuestionCatsChange: Function;
  handleResetAllFilters: Function;
  avalableQuestionCategories: QuestionCategory[];
  availableSupplierCategories: ISupplierCategoryGetModel[];
  availableProjectPhases: IProjectPhaseGetModel[];
}

export const QuestionFiltersSummary = ({
  isProjectPage,
  selectedQuestionPhases,
  selectedQuestionSuppTypes,
  selectedQuestionQTypes,
  handleSelectedPhaseChange,
  handleSelectedSuppTypesChange,
  handleSelectedQuestionCatsChange,
  handleResetAllFilters,
  avalableQuestionCategories,
  availableSupplierCategories,
  availableProjectPhases,
}: QuestionFiltersSummaryProps): JSX.Element | null => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const renderSelectedPhasesChips = (): JSX.Element | null => {
    const allPhases = typeof availableProjectPhases !== 'undefined' && availableProjectPhases?.length > 0 ? availableProjectPhases : [];
    if (selectedQuestionPhases?.length === 0 || allPhases?.length === 0) {
      return null;
    } else {
      const listSelectedPhases = selectedQuestionPhases.map((phaseItem) => {
        const _phaseName = allPhases.find((phase) => phase?.id === phaseItem?.id);

        if (typeof _phaseName?.name === 'undefined') {
          return null;
        }

        const handleDeleteSelectedPhase = (phaseId: number): void => {
          const filteredPhases = selectedQuestionPhases.filter((item) => item.id !== phaseId);
          dispatch(handleSelectedPhaseChange(filteredPhases));
          // TO DO --- rerefsh question list
          // dispatch(setSelectedEvaluationFilterPage(1));
        };

        return (
          <Chip
            key={phaseItem?.id}
            onDelete={() => handleDeleteSelectedPhase(phaseItem?.id)}
            onClick={() => handleDeleteSelectedPhase(phaseItem?.id)}
            sx={{ borderRadius: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            label={_phaseName?.name}
            deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
            className='evalSummaryChipItem'
          />
        );
      });

      return <div className='evalFiltersSummaryCompaniesListContainer'>{listSelectedPhases}</div>;
    }
  };

  const renderDeleteAllFiltersButton = (): JSX.Element | null => {
    if (selectedQuestionPhases?.length === 0 && selectedQuestionSuppTypes?.length === 0 && selectedQuestionQTypes?.length === 0) {
      return (
        <Typography
          sx={{
            letterSpacing: '0.46px',
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}
        >
          Ingen filtre valgt
        </Typography>
      );
    }

    return (
      <div className='evalFiltersSummaryResetContainer'>
        <Button
          sx={{ padding: 0, paddingRight: '5px', paddingLeft: '5px' }}
          size='small'
          variant='outlined'
          className=''
          onClick={() => handleResetAllFilters()}
        >
          Fjern alle
        </Button>
      </div>
    );
  };

  const renderQuestionCategoriesChips = (): JSX.Element | null => {
    const allCats =
      typeof avalableQuestionCategories !== 'undefined' && avalableQuestionCategories?.length > 0 ? avalableQuestionCategories : [];

    if (selectedQuestionQTypes?.length === 0 || allCats?.length === 0) {
      return null;
    } else if (selectedQuestionQTypes !== undefined) {
      const listSelectedStatuses = selectedQuestionQTypes.map((categoryItem) => {
        const _catName = allCats.find((cat) => cat?.id === categoryItem?.id);

        if (typeof _catName?.name === 'undefined') {
          return null;
        }

        const handleDeleteCategory = (categoryId: number): void => {
          const filteredStatuses = selectedQuestionQTypes.filter((item) => item.id !== categoryId);
          dispatch(handleSelectedQuestionCatsChange(filteredStatuses));
          // dispatch(setSelectedEvaluationFilterPage(1));
        };

        return (
          <Chip
            key={_catName?.id}
            onDelete={() => handleDeleteCategory(_catName?.id)}
            onClick={() => handleDeleteCategory(_catName?.id)}
            sx={{ borderRadius: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            label={_catName?.name}
            deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
            className='evalSummaryChipItem'
          />
        );
      });

      return <div className='evalFiltersSummaryCompaniesListContainer'>{listSelectedStatuses}</div>;
    }
    return null;
  };

  const renderSelectedSuppliersStatusesChips = (): JSX.Element | null => {
    const allStatuses =
      typeof availableSupplierCategories !== 'undefined' && availableSupplierCategories?.length > 0 ? availableSupplierCategories : [];

    if (selectedQuestionSuppTypes?.length === 0 || allStatuses?.length === 0) {
      return null;
    } else if (selectedQuestionSuppTypes !== undefined && handleSelectedSuppTypesChange !== undefined) {
      const listSelectedStatuses = selectedQuestionSuppTypes.map((statusItem) => {
        const _statusName = allStatuses.find((status) => status?.id === statusItem?.id);

        if (typeof _statusName?.name === 'undefined') {
          return null;
        }

        const handleDeleteSupplierStatusDelete = (statusId: number): void => {
          const filteredStatuses = selectedQuestionSuppTypes.filter((item) => item.id !== statusId);
          dispatch(handleSelectedSuppTypesChange(filteredStatuses));
          // dispatch(setSelectedEvaluationFilterPage(1));
        };

        return (
          <Chip
            key={_statusName?.id}
            onDelete={() => handleDeleteSupplierStatusDelete(_statusName?.id)}
            onClick={() => handleDeleteSupplierStatusDelete(_statusName?.id)}
            sx={{ borderRadius: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            label={_statusName?.name}
            deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
            className='evalSummaryChipItem'
          />
        );
      });

      return <div className='evalFiltersSummaryCompaniesListContainer'>{listSelectedStatuses}</div>;
    }
    return null;
  };

  return (
    <div className='questionSummaryFiltersContainer'>
      <div className='questionSummaryChipWrapper'>{renderSelectedPhasesChips()}</div>
      <div className='questionSummaryChipWrapper'>{renderQuestionCategoriesChips()}</div>
      {!isProjectPage ? <div className='evalSummaryChipWrapper'>{renderSelectedSuppliersStatusesChips()}</div> : null}
      {renderDeleteAllFiltersButton()}
    </div>
  );
};

export default QuestionFiltersSummary;
