import { useEffect, useState } from 'react';
import { Box, Button, Pagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { useApi } from 'hooks/useApi/useApi';
import RenderFilterAccordion from 'components/shared/RenderFilterAccordion';
import './styles.scss';
import { PageLayout } from 'components';
import {
  setAllQuestionFilters,
  setLoadingQuestionFiltersProject,
  setAvailableSupplierCategories,
  SetAvailableProjectPhases,
  SetSelectedProjectQuestionFilterPhases,
  SetSelectedProjectQuestionsFilterSuppCats,
  SetSelectedProjectQuestionsFilterQuestionCats,
  ResetSelectedProjectQuestionFilters,
  SetPaginatedQuestionsProject,
  SetSelectedPageQuestionsProject,
  SetLodingQuestionProjectData,
  SetResetProjectQuestionsListManually,
} from 'redux/actions/questions';
import {
  loadingProjectQuestionsFiltersState,
  selectedProjectQuestionFilterPhasesSelector,
  selectedProjectQuestionFilterSupplierTypesSelector,
  selectedProjectQuestionFilterQuestionCatsSelector,
  projectQuestionsFilters,
  availableSupplierCategoriesSelector,
  availableProjectPhasesSelector,
  projectQuestionsDataSelector,
  paginatedQuestionsSelector,
  projectQuestionsPageNumberSelector,
  loadingProjectQuestionDataSelector,
  resetProjectQuestionListManuallySelector,
} from 'util/selectors';
import QuestionCategoriesListFilter from 'components/Questions/Filters/QuestionCategoriesListFilter';
import RenderQuestionFiltersSummary from 'components/Questions/Filters/RenderQuestionFiltersSummary';
import QuestionFiltersSekeleton from 'components/Questions/Filters/FiltersSkeleton';
import QuestionsRender from 'components/Questions/QuestionsRender';
import QuestionListSkeleton from 'components/Questions/QuestionListSkeleton';
import BackDropLoading from 'components/shared/BackdropLoading';
import QuestionFormModal from 'components/Questions/Modal/QuestionFormModal';
import { projectTypeId } from 'util/constants';

export const ProjectPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const isLoadingQuestionFilters = useSelector(loadingProjectQuestionsFiltersState);

  const [showFiltersAccordion, setShowFiltersAccordion] = useState<boolean>(false);
  const [showChosenFiltersAccordion, setShowChosenFiltersAccordion] = useState<boolean>(false);
  const [numberOfFilters, setNumberOfFilters] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [showFormModal, setShowFormModal] = useState<boolean>(false);
  const [activeEvalObj, setActiveEvalObj] = useState<QuestionState | null>(null);

  const { getQuestionCategoriesCall, getSupplierCategoriesCall, getPhasesOfProjectsCall, getQuestionsCall, updateQuestionCall } = useApi();

  // All available filters
  const avalableQuestionCategories = useSelector(projectQuestionsFilters);
  const availableSupplierCategories = useSelector(availableSupplierCategoriesSelector);
  const availableProjectPhases = useSelector(availableProjectPhasesSelector);

  // question filters state
  const selectedQuestionPhases = useSelector(selectedProjectQuestionFilterPhasesSelector);
  const selectedQuestionSuppTypes = useSelector(selectedProjectQuestionFilterSupplierTypesSelector);
  const selectedQuestionQTypes = useSelector(selectedProjectQuestionFilterQuestionCatsSelector);

  // question data
  const questionList = useSelector(projectQuestionsDataSelector);
  const paginatedQuestions = useSelector(paginatedQuestionsSelector);
  const isLoadingProjectQuestions = useSelector(loadingProjectQuestionDataSelector);
  const selectedPage = useSelector(projectQuestionsPageNumberSelector);

  const resetQuestionList = useSelector(resetProjectQuestionListManuallySelector);

  const setSelectedPage = (newNumber: number): void => {
    dispatch(SetSelectedPageQuestionsProject(newNumber));
  };

  const handleSelectedPhaseChange = (value: IProjectPhaseGetModel[]): void => {
    dispatch(SetSelectedProjectQuestionFilterPhases(value));
    setSelectedPage(1);
  };
  const handleSelectedSuppTypesChange = (value: ISupplierCategoryGetModel[]): void => {
    dispatch(SetSelectedProjectQuestionsFilterSuppCats(value));
    setSelectedPage(1);
  };
  const handleSelectedQuestionCatsChange = (value: QuestionCategory[]): void => {
    dispatch(SetSelectedProjectQuestionsFilterQuestionCats(value));
    setSelectedPage(1);
  };
  const handleResetAllFilters = (): void => {
    dispatch(ResetSelectedProjectQuestionFilters());
  };

  const handleFetchQuestionData = async (): Promise<void> => {
    dispatch(SetLodingQuestionProjectData(true));
    const params = {
      // id 2 indicates project
      evaluationTypeId: projectTypeId,
      projectPhaseId: selectedQuestionPhases.map((item) => item.id),
      supplierCategoryId: selectedQuestionSuppTypes.map((item) => item.id),
      QuestionCategoryId: selectedQuestionQTypes.map((item) => item.id),
      pageNumber: selectedPage,
      pageSize: 30,
    };
    await getQuestionsCall(params)
      .then((response) => {
        dispatch(SetPaginatedQuestionsProject(response));
      })
      .catch((err) => {
        if (typeof err?.response?.data?.detail === 'string') {
          toast.error(err?.response?.data?.detail);
        } else {
          toast.error('Klarte ikke å hente prosjektspørsmål.');
        }
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(SetLodingQuestionProjectData(false));
        }, 500);
      });
  };

  useEffect(() => {
    if (resetQuestionList) {
      (() => {
        void handleFetchQuestionData().then(() => dispatch(SetResetProjectQuestionsListManually(false)));
      })();
    }
  }, [resetQuestionList]);

  useEffect(() => {
    dispatch(setLoadingQuestionFiltersProject(true));
    getSupplierCategoriesCall()
      .then((response) => {
        dispatch(setAvailableSupplierCategories(response));
      })
      .catch((err) => {
        if (typeof err?.response?.data?.detail === 'string') {
          toast.error(err?.response?.data?.detail);
        } else {
          toast.error('Klarte ikke å hente leverandørkategorier.');
        }
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setLoadingQuestionFiltersProject(false));
        }, 500);
      });
  }, []);

  useEffect(() => {
    dispatch(setLoadingQuestionFiltersProject(true));
    getQuestionCategoriesCall()
      .then((response) => {
        dispatch(setAllQuestionFilters(response));
      })
      .catch((err) => {
        if (typeof err === 'string') {
          toast.error(err);
        } else {
          toast.error('Klarte ikke å hente spørsmålfiltrer.');
        }
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setLoadingQuestionFiltersProject(false));
        }, 500);
      });
  }, []);

  useEffect(() => {
    dispatch(setLoadingQuestionFiltersProject(true));
    getPhasesOfProjectsCall()
      .then((response) => {
        dispatch(SetAvailableProjectPhases(response));
      })
      .catch((err) => {
        if (typeof err?.response?.data?.detail === 'string') {
          toast.error(err?.response?.data?.detail);
        } else {
          toast.error('Klarte ikke å hente prosjektfaser.');
        }
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setLoadingQuestionFiltersProject(false));
        }, 500);
      });
  }, []);

  useEffect(() => {
    (() => {
      void handleFetchQuestionData();
    })();
  }, [selectedQuestionPhases, selectedQuestionSuppTypes, selectedQuestionQTypes, selectedPage]);

  useEffect(() => {
    let totalNumberOfFilters = 0;

    if (selectedQuestionPhases?.length > 0) {
      totalNumberOfFilters += selectedQuestionPhases?.length;
    }
    if (selectedQuestionSuppTypes?.length > 0) {
      totalNumberOfFilters += selectedQuestionSuppTypes?.length;
    }
    if (selectedQuestionQTypes?.length > 0) {
      totalNumberOfFilters += selectedQuestionQTypes?.length;
    }

    setNumberOfFilters(totalNumberOfFilters);
  }, [selectedQuestionPhases, selectedQuestionSuppTypes, selectedQuestionQTypes]);

  const renderFilters = (): JSX.Element => {
    if (isLoadingQuestionFilters) {
      return (
        <div className='filtersWrapperQuestions'>
          <QuestionFiltersSekeleton />
        </div>
      );
    }
    return (
      <div className='filtersWrapperQuestions'>
        <div className='evalMenuItem'>
          <Button variant='contained' className='addEvaluationButton' onClick={() => setShowFormModal(true)}>
            <AddIcon fontSize='small' />
            Opprett nytt spørsmål
          </Button>
        </div>
        <div className='evalMenuItem'>
          <RenderFilterAccordion
            accordionTitle={numberOfFilters > 0 ? `VALGTE FILTRE (${numberOfFilters})` : 'VALGTE FILTRE'}
            accordionId='Eval-chosen-filters'
            content={
              <RenderQuestionFiltersSummary
                isProjectPage
                selectedQuestionPhases={selectedQuestionPhases}
                selectedQuestionSuppTypes={selectedQuestionSuppTypes}
                selectedQuestionQTypes={selectedQuestionQTypes}
                handleSelectedPhaseChange={handleSelectedPhaseChange}
                handleSelectedSuppTypesChange={handleSelectedSuppTypesChange}
                handleSelectedQuestionCatsChange={handleSelectedQuestionCatsChange}
                handleResetAllFilters={handleResetAllFilters}
                avalableQuestionCategories={avalableQuestionCategories}
                availableSupplierCategories={availableSupplierCategories}
                availableProjectPhases={availableProjectPhases}
              />
            }
            open={showChosenFiltersAccordion}
            setOpen={setShowChosenFiltersAccordion}
          />
        </div>
        <div className='evalMenuItem'>
          <RenderFilterAccordion
            accordionTitle='FILTRERING'
            accordionId='Eval-filters'
            content={
              <QuestionCategoriesListFilter
                isProjectPage
                selectedQuestionPhases={selectedQuestionPhases}
                selectedQuestionSuppTypes={selectedQuestionSuppTypes}
                selectedQuestionQTypes={selectedQuestionQTypes}
                handleSelectedPhaseChange={handleSelectedPhaseChange}
                handleSelectedSuppTypesChange={handleSelectedSuppTypesChange}
                handleSelectedQuestionCatsChange={handleSelectedQuestionCatsChange}
              />
            }
            open={showFiltersAccordion}
            setOpen={setShowFiltersAccordion}
          />
        </div>
      </div>
    );
  };

  const handleToggleQItemAction = async (questionId: number, questionitem: QuestionState): Promise<void> => {
    // dispatch(SetLodingQuestionProjectData(true));

    const oldFlag = questionitem?.isActive;
    const mappedParams = {
      questionCategoryId: questionitem?.questionCategory.id,
      isActive: typeof oldFlag === 'boolean' ? !oldFlag : false,
      questionSupplierCategoryIds: questionitem?.supplierCategory.map((item) => item.id),
      questionProjectPhaseIds: questionitem?.projectPhase.map((item) => item.id),
    };
    setLoading(true);
    updateQuestionCall(mappedParams, questionId)
      .then(() => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
        dispatch(SetResetProjectQuestionsListManually(true));
      })
      .catch((err) => {
        if (typeof err?.response?.data?.detail === 'string') {
          toast.error(err?.response?.data?.detail);
        } else {
          toast.error('Klarte ikke å oppdatere spørsmål.');
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  };

  const handleEditQuestionAction = (questionItem: QuestionState): void => {
    setActiveEvalObj(questionItem);
    setShowFormModal(true);
  };

  const renderQuestionsContent = (): JSX.Element => {
    if (isLoadingProjectQuestions) {
      return (
        <div className='flex2'>
          <QuestionListSkeleton />
        </div>
      );
    } else {
      return (
        <div className='flex2'>
          <QuestionsRender
            handleEditQuestionAction={handleEditQuestionAction}
            questionList={questionList}
            handleToggleQItemAction={handleToggleQItemAction}
          />
          {paginatedQuestions?.totalPages > 1 ? (
            <div className='paginationContainerQ'>
              <Pagination
                color='primary'
                count={paginatedQuestions?.totalPages}
                page={selectedPage}
                onChange={(event, newPage) => {
                  setSelectedPage(newPage);
                }}
                showFirstButton
                showLastButton
              />
            </div>
          ) : null}
        </div>
      );
    }
  };

  return (
    <PageLayout hideSidebar={false}>
      <BackDropLoading open={loading} />
      <QuestionFormModal
        setActiveEvalObj={setActiveEvalObj}
        open={showFormModal}
        setOpen={setShowFormModal}
        isProject
        activeEvalObj={activeEvalObj}
      />
      <Box
        sx={{
          px: { md: 0, lg: '20px' },
          gridTemplateColumns: { xs: '1fr 1fr' },
          gap: '20px 20px',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        {renderFilters()}
        {renderQuestionsContent()}
      </Box>
    </PageLayout>
  );
};
