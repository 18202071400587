import { ListItemIcon, ListItemText, MenuItem, MenuList, Typography, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import ButtonBase from '@mui/material/ButtonBase';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SettingsIcon from '@mui/icons-material/Settings';
import HandymanIcon from '@mui/icons-material/Handyman';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import './styles.scss';
import AuthorizedTemplate from 'auth/AuthorizedTemplate';
import { AppRole } from 'auth/msalConfig';
import config from 'config/appSettings';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: 'none',
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreOutlinedIcon fontSize='small' />} {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

export const SidebarMenu = ({ ...props }): JSX.Element => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<boolean | false>(false);
  const { pathname } = useLocation();

  const evaluationsRouteName = '/evaluations';
  const supplierRouteName = '/supplier';
  const projectRouteName = '/project';

  const handleLayoutSubMenuToggle = (): void => {
    setExpanded(!expanded);
  };

  return (
    <MenuList>
      <AuthorizedTemplate roles={[AppRole.QuestionAdmin]}>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded}
          onChange={handleLayoutSubMenuToggle}
          className='menuAccordionContainer'
        >
          <AccordionSummary
            id='proj_submenu'
            className='submenuAccordionTitleContainer'
            expandIcon={<ExpandMoreOutlinedIcon fontSize='small' htmlColor={theme.palette.text.secondary} />}
          >
            <MenuItem sx={{ px: 0 }} disableRipple className='menuItem'>
              <ButtonBase onClick={() => handleLayoutSubMenuToggle()} className='expandableMenuItem'>
                <div className='expandableItemTextAndIconWrapper'>
                  <ListItemIcon style={{ minWidth: '30px' }}>
                    <BusinessCenterIcon fontSize='small' htmlColor={theme.palette.text.secondary} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant='body1'
                      color='text.secondary'
                      sx={pathname === projectRouteName || pathname === supplierRouteName ? { textDecoration: 'underline' } : null}
                    >
                      Mal
                    </Typography>
                  </ListItemText>
                </div>
              </ButtonBase>
            </MenuItem>
          </AccordionSummary>
          <AccordionDetails className='submenuAccordionContent'>
            <div className='submenuContainer'>
              <Link to={projectRouteName} style={{ textDecoration: 'none' }}>
                <MenuItem className='submenuItem menuItem' sx={{ px: 0 }} disableRipple>
                  <ListItemIcon style={{ minWidth: '30px' }}>
                    <SettingsIcon fontSize='small' htmlColor={theme.palette.text.secondary} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant='body1'
                      color='text.secondary'
                      sx={pathname === projectRouteName ? { textDecoration: 'underline' } : null}
                    >
                      Prosjekt
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </Link>
              <Link to={supplierRouteName} style={{ textDecoration: 'none' }}>
                <MenuItem className='submenuItem menuItem' sx={{ px: 0 }} disableRipple>
                  <ListItemIcon style={{ minWidth: '30px' }}>
                    <HandymanIcon fontSize='small' htmlColor={theme.palette.text.secondary} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant='body1'
                      color='text.secondary'
                      sx={pathname === supplierRouteName ? { textDecoration: 'underline' } : null}
                    >
                      Leverandør
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </Link>
            </div>
          </AccordionDetails>
        </Accordion>
      </AuthorizedTemplate>
      <AuthorizedTemplate roles={[AppRole.EvaluationAdmin]}>
        <Link to={evaluationsRouteName} style={{ textDecoration: 'none' }}>
          <MenuItem sx={{ px: 0 }} disableRipple className='menuItem'>
            <ListItemIcon style={{ minWidth: '30px' }}>
              <AssignmentIcon fontSize='small' htmlColor={theme.palette.text.secondary} />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant='body1'
                color='text.secondary'
                sx={pathname === evaluationsRouteName ? { textDecoration: 'underline' } : null}
              >
                Evalueringsportal
              </Typography>
            </ListItemText>
          </MenuItem>
        </Link>
      </AuthorizedTemplate>

      {config?.staticMenuItems?.map((item, index) => (
        <AuthorizedTemplate roles={[AppRole.EvaluationAdmin]} key={index}>
          <a href={item.href} target={item.target} style={{ textDecoration: 'none' }}>
            <MenuItem sx={{ px: 0 }} disableRipple className='menuItem'>
              <ListItemIcon style={{ minWidth: '30px' }}>
                <AssignmentIcon fontSize='small' htmlColor={theme.palette.text.secondary} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant='body1'
                  color='text.secondary'
                  sx={pathname === evaluationsRouteName ? { textDecoration: 'underline' } : null}
                >
                  {item.label}
                </Typography>
              </ListItemText>
            </MenuItem>
          </a>
        </AuthorizedTemplate>
      )) ?? null}
    </MenuList>
  );
};

export default SidebarMenu;
