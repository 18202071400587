export const SET_SELECTED_TYPE = 'SET_SELECTED_TYPE';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
export const SET_SELECTED_PROJECT_PHASE = 'SET_SELECTED_PROJECT_PHASE';
export const SET_SELECTED_DATE_FROM = 'SET_SELECTED_DATE_FROM';
export const SET_SELECTED_DATE_TO = 'SET_SELECTED_DATE_TO';
export const SET_SELECTED_SUPPLIER_IDS = 'SET_SELECTED_SUPPLIER_IDS';
export const SET_SELECTED_SUPPLIER_WITH_CATS = 'SET_SELECTED_SUPPLIER_WITH_CATS';
export const SET_SELECTED_EVALUATION_SUMMARY = 'SET_SELECTED_EVALUATION_SUMMARY';
export const SET_SELECTED_RESPONDERS = 'SET_SELECTED_RESPONDERS';
export const RESET_EVALUATION_FORM = 'RESET_EVALUATION_FORM';
export const SET_ALL_AVAILABLE_RECIPIENTS = 'SET_ALL_AVAILABLE_RECIPIENTS';

export interface SetSelectedTypeActionType {
  type: typeof SET_SELECTED_TYPE;
  data: IEvaluationTypeGetModel | undefined;
}

export interface SetSelectedCompanyActionType {
  type: typeof SET_SELECTED_COMPANY;
  data: string;
}

export interface SetSelectedProjectActionType {
  type: typeof SET_SELECTED_PROJECT;
  data: string;
}

export interface SetSelectedProjectPhaseActionType {
  type: typeof SET_SELECTED_PROJECT_PHASE;
  data: string;
}

export interface SetSelectedDateFromActionType {
  type: typeof SET_SELECTED_DATE_FROM;
  data: string;
}

export interface SetSelectedDateToActionType {
  type: typeof SET_SELECTED_DATE_TO;
  data: string | null;
}

export interface SetSelectedSupplierIdsActionType {
  type: typeof SET_SELECTED_SUPPLIER_IDS;
  data: string[];
}

export interface SetEvaluationSummaryActionType {
  type: typeof SET_SELECTED_EVALUATION_SUMMARY;
  data: EvaluationSummaryObject;
}

export interface SetSelectedSuppliersWithCatsActionType {
  type: typeof SET_SELECTED_SUPPLIER_WITH_CATS;
  data: ISupplierCategoryPostModel[];
}

export interface SetSelectedRespondersActionType {
  type: typeof SET_SELECTED_RESPONDERS;
  data: EvaluationRecipientObject[];
}

export interface ResetEvaluationFormActionType {
  type: typeof RESET_EVALUATION_FORM;
}

export interface SetAllAvailableRecipientsActionType {
  type: typeof SET_ALL_AVAILABLE_RECIPIENTS;
  data: EvaluationRecipientObject[];
}

export function setSelectedTypeAction(data: IEvaluationTypeGetModel | undefined): SetSelectedTypeActionType {
  return {
    type: SET_SELECTED_TYPE,
    data,
  };
}

export function setSelectedCompanyAction(data: string): SetSelectedCompanyActionType {
  return {
    type: SET_SELECTED_COMPANY,
    data,
  };
}

export function setSelectedProjectAction(data: string): SetSelectedProjectActionType {
  return {
    type: SET_SELECTED_PROJECT,
    data,
  };
}

export function setSelectedProjectPhaseAction(data: string): SetSelectedProjectPhaseActionType {
  return {
    type: SET_SELECTED_PROJECT_PHASE,
    data,
  };
}

export function setDateFromAction(data: string): SetSelectedDateFromActionType {
  return {
    type: SET_SELECTED_DATE_FROM,
    data,
  };
}

export function setDateToAction(data: string | null): SetSelectedDateToActionType {
  return {
    type: SET_SELECTED_DATE_TO,
    data,
  };
}

export function setSelectedSupplierIdsAction(data: string[]): SetSelectedSupplierIdsActionType {
  return {
    type: SET_SELECTED_SUPPLIER_IDS,
    data,
  };
}

export function setEvaluationSummaryAction(data: EvaluationSummaryObject): SetEvaluationSummaryActionType {
  return {
    type: SET_SELECTED_EVALUATION_SUMMARY,
    data,
  };
}

export function setSelectedSuppliersWithCatsAction(data: ISupplierCategoryPostModel[]): SetSelectedSuppliersWithCatsActionType {
  return {
    type: SET_SELECTED_SUPPLIER_WITH_CATS,
    data,
  };
}

export function setSelectedRespondersAction(data: EvaluationRecipientObject[]): SetSelectedRespondersActionType {
  return {
    type: SET_SELECTED_RESPONDERS,
    data,
  };
}

export function resetEvaluationFormAction(): ResetEvaluationFormActionType {
  return {
    type: RESET_EVALUATION_FORM,
  };
}

export function setAllAvailableRecipientsAction(data: EvaluationRecipientObject[]): SetAllAvailableRecipientsActionType {
  return {
    type: SET_ALL_AVAILABLE_RECIPIENTS,
    data,
  };
}
