import { useDispatch, useSelector } from 'react-redux';
import { Button, Chip, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  evaluationFiltersSelector,
  selectedDateFromState,
  selectedDateToState,
  selectedScopeState,
  selectedOwnershipState,
  selectedStateOfSelectedCompanies,
  selectedPhasesState,
  selectedStatusesState,
  selectedSupplierStatusesState,
  selectedTextSearchState,
} from 'util/selectors';
import {
  setSelectedEvaluationFilterDateFrom,
  setSelectedEvaluationFilterDateTo,
  setSelectedEvaluationFilterOwnership,
  setSelectedEvaluationFilterScope,
  setSelectedEvaluationFilterCompanies,
  setSelectedEvaluationFilterPhase,
  setSelectedEvaluationFilterStatuses,
  setSelectedEvaluationFilterSupplierStatuses,
  setSelectedEvaluationFilterPage,
  resetAllEvaluationsFilters,
  setSelectedEvaluationFilterSearchText,
  setManuallyRefreshEvaluationsList,
} from 'redux/actions/evaluations';
import '../styles.scss';

const RenderFiltersSummary = (): JSX.Element => {
  const theme = useTheme();
  const dispatch = useDispatch();
  // all filters data
  const evaluationFilters = useSelector(evaluationFiltersSelector);
  // filters
  const dateFrom = useSelector(selectedDateFromState);
  const dateTo = useSelector(selectedDateToState);
  const selectedScope = useSelector(selectedScopeState);
  const selectedOwnership = useSelector(selectedOwnershipState);
  const selectedCompanies = useSelector(selectedStateOfSelectedCompanies);
  const selectedPhases = useSelector(selectedPhasesState);
  const selectedStatuses = useSelector(selectedStatusesState);
  const selectedSupplierStatuses = useSelector(selectedSupplierStatusesState);
  const selectedTextSeach = useSelector(selectedTextSearchState);

  const resetPageAndRefreshEvalsList = (): void => {
    dispatch(setSelectedEvaluationFilterPage(1));
    dispatch(setManuallyRefreshEvaluationsList(true));
  };

  const renderTextSearchChip = (): JSX.Element | null => {
    if (selectedTextSeach === null || selectedTextSeach?.length === 0) {
      return null;
    } else {
      const handleResetTextSearch = (): void => {
        dispatch(setSelectedEvaluationFilterSearchText(''));
        resetPageAndRefreshEvalsList();
      };

      return (
        <Chip
          onDelete={() => handleResetTextSearch()}
          onClick={() => handleResetTextSearch()}
          sx={{ borderRadius: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          label={selectedTextSeach}
          deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
          className='evalSummaryChipItem'
        />
      );
    }
  };

  const renderDateFromChip = (): JSX.Element | null => {
    if (dateFrom === null) {
      return null;
    } else {
      return (
        <Chip
          onDelete={() => dispatch(setSelectedEvaluationFilterDateFrom(null))}
          onClick={() => dispatch(setSelectedEvaluationFilterDateFrom(null))}
          sx={{ borderRadius: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          label={dateFrom}
          deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
          className='evalSummaryChipItem'
        />
      );
    }
  };

  const renderSelectedScopeChips = (): JSX.Element | null => {
    if (selectedScope?.length === 0 || selectedScope === null) {
      return null;
    } else {
      const handleDeleteScope = (idToDelete: number): void => {
        const filteredScopes = selectedScope.filter((item) => item.id !== idToDelete);
        dispatch(setSelectedEvaluationFilterScope(filteredScopes));
        dispatch(setSelectedEvaluationFilterPage(1));
      };

      const listScopes = (): JSX.Element[] | null =>
        selectedScope.map((scope) => {
          return (
            <Chip
              key={scope.id}
              onDelete={() => handleDeleteScope(scope.id)}
              onClick={() => handleDeleteScope(scope.id)}
              sx={{ borderRadius: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              label={scope?.name}
              deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
              className='evalSummaryChipItem'
            />
          );
        });

      return <div>{listScopes()}</div>;
    }
  };

  const renderDateToChip = (): JSX.Element | null => {
    if (dateTo === null) {
      return null;
    } else {
      return (
        <Chip
          onDelete={() => dispatch(setSelectedEvaluationFilterDateTo(null))}
          onClick={() => dispatch(setSelectedEvaluationFilterDateTo(null))}
          sx={{ borderRadius: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          label={dateTo}
          deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
          className='evalSummaryChipItem'
        />
      );
    }
  };

  const renderSelectedOwnershipChip = (): JSX.Element | null => {
    if (selectedOwnership?.description === null || typeof selectedOwnership?.description === 'undefined') {
      return null;
    } else {
      const ownershipName = typeof selectedOwnership?.description === 'string' ? selectedOwnership?.description : '';

      const handleRemoveOwnershipItem = (): void => {
        dispatch(setSelectedEvaluationFilterOwnership(null));
        dispatch(setSelectedEvaluationFilterPage(1));
      };

      return (
        <Chip
          onDelete={() => handleRemoveOwnershipItem()}
          onClick={() => handleRemoveOwnershipItem()}
          sx={{ borderRadius: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
          label={ownershipName}
          deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
          className='evalSummaryChipItem'
        />
      );
    }
  };

  const renderSelectedCompaniesChips = (): JSX.Element | null => {
    const allCompanies =
      typeof evaluationFilters?.companies !== 'undefined' && evaluationFilters?.companies?.length > 0 ? evaluationFilters?.companies : [];
    if (selectedCompanies?.length === 0 || allCompanies?.length === 0) {
      return null;
    } else {
      const listSelectedCompanies = selectedCompanies.map((companyItem) => {
        const _companyName = allCompanies.find((company) => company?.id === companyItem?.id);

        if (typeof _companyName?.name === 'undefined') {
          return null;
        }

        const handleRemoveCompany = (companyId: number): void => {
          const filteredCompanies = selectedCompanies.filter((item) => item.id !== companyId);
          dispatch(setSelectedEvaluationFilterCompanies(filteredCompanies));
          dispatch(setSelectedEvaluationFilterPage(1));
        };

        return (
          <Chip
            key={companyItem?.id}
            onDelete={() => handleRemoveCompany(companyItem?.id)}
            onClick={() => handleRemoveCompany(companyItem?.id)}
            sx={{ borderRadius: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            label={_companyName?.name}
            deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
            className='evalSummaryChipItem'
          />
        );
      });

      return <div className='evalFiltersSummaryCompaniesListContainer'>{listSelectedCompanies}</div>;
    }
  };

  const renderSelectedPhasesChips = (): JSX.Element | null => {
    const allPhases =
      typeof evaluationFilters?.projectPhases !== 'undefined' && evaluationFilters?.projectPhases?.length > 0
        ? evaluationFilters?.projectPhases
        : [];
    if (selectedPhases?.length === 0 || allPhases?.length === 0) {
      return null;
    } else {
      const listSelectedPhases = selectedPhases.map((phaseItem) => {
        const _phaseName = allPhases.find((phase) => phase?.id === phaseItem?.id);

        if (typeof _phaseName?.name === 'undefined') {
          return null;
        }

        const handleDeleteSelectedPhase = (phaseId: number): void => {
          const filteredPhases = selectedPhases.filter((item) => item.id !== phaseId);
          dispatch(setSelectedEvaluationFilterPhase(filteredPhases));
          dispatch(setSelectedEvaluationFilterPage(1));
        };

        return (
          <Chip
            key={phaseItem?.id}
            onDelete={() => handleDeleteSelectedPhase(phaseItem?.id)}
            onClick={() => handleDeleteSelectedPhase(phaseItem?.id)}
            sx={{ borderRadius: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            label={_phaseName?.name}
            deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
            className='evalSummaryChipItem'
          />
        );
      });

      return <div className='evalFiltersSummaryCompaniesListContainer'>{listSelectedPhases}</div>;
    }
  };

  const renderSelectedStatusesChips = (): JSX.Element | null => {
    const allStatuses =
      typeof evaluationFilters?.statuses !== 'undefined' && evaluationFilters?.statuses?.length > 0 ? evaluationFilters?.statuses : [];
    if (selectedStatuses?.length === 0 || selectedStatuses?.length === 0) {
      return null;
    } else {
      const listSelectedStatuses = selectedStatuses.map((statusItem) => {
        const _statusName = allStatuses.find((status) => status?.key === statusItem?.id);

        if (typeof _statusName?.description === 'undefined') {
          return null;
        }

        const handleDeleteSelectedStatus = (statusId: number): void => {
          const filteredStatuses = selectedStatuses.filter((item) => item.id !== statusId);
          dispatch(setSelectedEvaluationFilterStatuses(filteredStatuses));
          dispatch(setSelectedEvaluationFilterPage(1));
        };

        return (
          <Chip
            key={_statusName?.key}
            onDelete={() => handleDeleteSelectedStatus(_statusName?.key)}
            onClick={() => handleDeleteSelectedStatus(_statusName?.key)}
            sx={{ borderRadius: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            label={_statusName?.description}
            deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
            className='evalSummaryChipItem'
          />
        );
      });

      return <div className='evalFiltersSummaryCompaniesListContainer'>{listSelectedStatuses}</div>;
    }
  };

  const renderSelectedSuppliersStatusesChips = (): JSX.Element | null => {
    const allStatuses =
      typeof evaluationFilters?.supplierCategories !== 'undefined' && evaluationFilters?.supplierCategories?.length > 0
        ? evaluationFilters?.supplierCategories
        : [];

    if (selectedSupplierStatuses?.length === 0 || allStatuses?.length === 0) {
      return null;
    } else {
      const listSelectedStatuses = selectedSupplierStatuses.map((statusItem) => {
        const _statusName = allStatuses.find((status) => status?.id === statusItem?.id);

        if (typeof _statusName?.name === 'undefined') {
          return null;
        }

        const handleDeleteSupplierStatusDelete = (statusId: number): void => {
          const filteredStatuses = selectedSupplierStatuses.filter((item) => item.id !== statusId);
          dispatch(setSelectedEvaluationFilterSupplierStatuses(filteredStatuses));
          dispatch(setSelectedEvaluationFilterPage(1));
        };

        return (
          <Chip
            key={_statusName?.id}
            onDelete={() => handleDeleteSupplierStatusDelete(_statusName?.id)}
            onClick={() => handleDeleteSupplierStatusDelete(_statusName?.id)}
            sx={{ borderRadius: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            label={_statusName?.name}
            deleteIcon={<CloseIcon sx={{ color: `${theme.palette.primary.main} !important` }} fontSize='small' />}
            className='evalSummaryChipItem'
          />
        );
      });

      return <div className='evalFiltersSummaryCompaniesListContainer'>{listSelectedStatuses}</div>;
    }
  };

  const handleRemoveAllFilters = (): void => {
    dispatch(resetAllEvaluationsFilters());
  };

  const renderDeleteAllFiltersButton = (): JSX.Element | null => {
    if (
      dateFrom === null &&
      dateTo === null &&
      selectedScope?.length === 0 &&
      selectedOwnership === null &&
      selectedCompanies?.length === 0 &&
      selectedPhases?.length === 0 &&
      selectedStatuses?.length === 0 &&
      selectedSupplierStatuses?.length === 0 &&
      (selectedTextSeach === null || selectedTextSeach?.length === 0)
    ) {
      return (
        <Typography
          sx={{
            letterSpacing: '0.46px',
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}
        >
          Ingen filtre valgt
        </Typography>
      );
    }

    return (
      <div className='evalFiltersSummaryResetContainer'>
        <Button
          sx={{ padding: 0, paddingRight: '5px', paddingLeft: '5px' }}
          size='small'
          variant='outlined'
          className=''
          onClick={() => handleRemoveAllFilters()}
        >
          Fjern alle
        </Button>
      </div>
    );
  };

  return (
    <div className='evalSummaryFiltersContainer'>
      <div className='evalSummaryChipWrapper'>{renderTextSearchChip()}</div>
      <div className='evalSummaryChipWrapper'>{renderDateFromChip()}</div>
      <div className='evalSummaryChipWrapper'>{renderDateToChip()}</div>
      <div className='evalSummaryChipWrapper'>{renderSelectedScopeChips()}</div>
      <div className='evalSummaryChipWrapper'>{renderSelectedOwnershipChip()}</div>
      <div className='evalSummaryChipWrapper'>{renderSelectedCompaniesChips()}</div>
      <div className='evalSummaryChipWrapper'>{renderSelectedPhasesChips()}</div>
      <div className='evalSummaryChipWrapper'>{renderSelectedStatusesChips()}</div>
      <div className='evalSummaryChipWrapper'>{renderSelectedSuppliersStatusesChips()}</div>
      {renderDeleteAllFiltersButton()}
    </div>
  );
};

export default RenderFiltersSummary;
