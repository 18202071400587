import { useTheme, ButtonBase, useMediaQuery, Modal, Typography, Box, Button, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ModalSelectMoreRecipientsProps {
  open: boolean;
  setOpen: Function;
  allUsersFromSearch: UserSearchFromMicrosoftObject[];
  selectedUsersExtra: UserSearchFromMicrosoftObject[];
  setSelectedUsersExtra: Function;
  handleAddExtraUsersToSelectedResponders: Function;
}

const ModalSelectMoreRecipients: React.FC<ModalSelectMoreRecipientsProps> = ({
  open,
  setOpen,
  allUsersFromSearch,
  selectedUsersExtra,
  setSelectedUsersExtra,
  handleAddExtraUsersToSelectedResponders,
}: ModalSelectMoreRecipientsProps): JSX.Element | null => {
  // div hooks etc
  const theme = useTheme();
  const isMdWidth = useMediaQuery(theme.breakpoints.up('md'));

  const handleClose = (): void => setOpen(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 420,
    bgcolor: theme.palette.text.secondary,
    boxShadow: 24,
  };

  const styleSmall = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: theme.palette.text.secondary,
    boxShadow: 24,
  };

  const renderFormHeader = (): JSX.Element | null => {
    return (
      <div className='evaluationTitleModal'>
        <div></div>
        <div className='iconAndTitle'>
          <div className='evaluationIconContainer'></div>
          <Typography
            variant='body1'
            align='center'
            color='text.secondary'
            sx={{ textTransform: 'uppercase', fontSize: '0.9rem', padding: 0, margin: 0, lineHeight: '30px' }}
          >
            Legg inn flere mottakere
          </Typography>
        </div>

        <ButtonBase onClick={() => setOpen(false)}>
          <div className='evalModalCloseButton'>
            <CloseIcon fontSize='medium' htmlColor={theme.palette.text.secondary} />
          </div>
        </ButtonBase>
      </div>
    );
  };

  const renderModalContent = (): JSX.Element | null => {
    const _responderToggle = (userId: string, isMarked: boolean, userItem: UserSearchFromMicrosoftObject): void => {
      if (userId !== null && isMarked) {
        setSelectedUsersExtra([...selectedUsersExtra, userItem]);
      } else {
        const filteredResponders = selectedUsersExtra.filter((item) => item?.id !== userId);
        setSelectedUsersExtra(filteredResponders);
      }
    };

    if (allUsersFromSearch?.length === 0) {
      return (
        <div className='addUserTitle'>
          <Typography sx={{ textAlign: 'center', paddingBottom: '24px', paddingTop: '24px', letterSpacing: '0.46px', lineHeight: '26px' }}>
            Ingen funnet..
          </Typography>
        </div>
      );
    }

    return (
      <div className='addUserTitle'>
        <div className='extraUsersList'>
          {allUsersFromSearch.map((item: UserSearchFromMicrosoftObject) => {
            const isItemChecked = selectedUsersExtra.find((responderItem) => responderItem?.id === item?.id) != null;
            return (
              <div key={item?.id} className='evalRecipientLine'>
                <Checkbox
                  sx={{ color: theme?.palette?.primary?.main }}
                  value={item?.id}
                  onChange={({ target }) => _responderToggle(target.value, target.checked, item)}
                  checked={isItemChecked}
                />
                <Typography sx={{ alignSelf: 'center', marginLeft: '5px', marginBottom: 0, letterSpacing: '0.46px', lineHeight: '26px' }}>
                  {item?.displayName}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box sx={isMdWidth ? style : styleSmall}>
          <div className='addUserModalContainer'>
            {renderFormHeader()}
            <div className='addUserModalContentWrapper'>
              {renderModalContent()}
              <div className='actionButtons'>
                <Button onClick={handleClose} color='primary' variant='outlined' sx={{ margin: '4px' }}>
                  Avbryt
                </Button>
                <Button
                  disabled={allUsersFromSearch?.length === 0}
                  onClick={() => handleAddExtraUsersToSelectedResponders(selectedUsersExtra)}
                  color='primary'
                  variant='contained'
                  sx={{ margin: '4px' }}
                >
                  Legg inn
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalSelectMoreRecipients;
