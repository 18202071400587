export const SET_QUESTION_FILTERS_PROJECT = 'SET_QUESTION_FILTERS_PROJECT';
export const SET_ALL_QUESTION_FILTERS_PROJECT = 'SET_ALL_QUESTION_FILTERS_PROJECT';
export const SET_LOADING_QUESTION_FILTERS_PROJECT = 'SET_LOADING_QUESTION_FILTERS_PROJECT';
export const SET_LOADING_QUESTION_FILTERS_SUPPLIER = 'SET_LOADING_QUESTION_FILTERS_SUPPLIER';
export const SET_AVAILABLE_SUPPLIER_CATEGORIES = 'SET_AVAILABLE_SUPPLIER_CATEGORIES';
export const SET_AVAILABLE_PROJECT_PHASES = 'SET_AVAILABLE_PROJECT_PHASES';

// project question filters
export const SET_SELECTED_PROJECT_QUESTION_FILTER_PHASES = 'SET_SELECTED_PROJECT_QUESTION_FILTER_PHASES';
export const SET_SELECTED_PROJECT_QUESTION_FILTER_SUPP_CATS = 'SET_SELECTED_PROJECT_QUESTION_FILTER_SUPP_CATS';
export const SET_SELECTED_PROJECT_QUESTION_FILTER_QUESTION_CATS = 'SET_SELECTED_PROJECT_QUESTION_FILTER_QUESTION_CATS';
export const RESET_SELECTED_PROJECT_QUESTION_FILTERS = 'RESET_SELECTED_PROJECT_QUESTION_FILTERS';

// project questions list
export const SET_PAGINATED_QUESTIONS_PROJECT = 'SET_PAGINATED_QUESTIONS_PROJECT';
export const SET_SELECTED_PAGE_QUESTIONS_PROJECT = 'SET_SELECTED_PAGE_QUESTIONS_PROJECT';
export const SET_LOADING_QUESTION_DATA_PROJECT = 'SET_LOADING_QUESTION_DATA_PROJECT';
export const SET_RESET_PROJECTS_QUESTION_LIST_MANUALLY = 'SET_RESET_PROJECTS_QUESTION_LIST_MANUALLY';

// supplier question filters
export const SET_SELECTED_SUPPLIER_QUESTION_FILTER_PHASES = 'SET_SELECTED_SUPPLIER_QUESTION_FILTER_PHASES';
export const SET_SELECTED_SUPPLIER_QUESTION_FILTER_SUPP_CATS = 'SET_SELECTED_SUPPLIER_QUESTION_FILTER_SUPP_CATS';
export const SET_SELECTED_SUPPLIER_QUESTION_FILTER_QUESTION_CATS = 'SET_SELECTED_SUPPLIER_QUESTION_FILTER_QUESTION_CATS';
export const RESET_SELECTED_SUPPLIER_QUESTION_FILTERS = 'RESET_SELECTED_SUPPLIER_QUESTION_FILTERS';

// supplier questions list
export const SET_PAGINATED_QUESTIONS_SUPPLIER = 'SET_PAGINATED_QUESTIONS_SUPPLIER';
export const SET_SELECTED_PAGE_QUESTIONS_SUPPLIER = 'SET_SELECTED_PAGE_QUESTIONS_SUPPLIER';
export const SET_LOADING_QUESTION_DATA_SUPPLIER = 'SET_LOADING_QUESTION_DATA_SUPPLIER';
export const SET_RESET_SUPPLIER_QUESTION_LIST_MANUALLY = 'SET_RESET_SUPPLIER_QUESTION_LIST_MANUALLY';
export const SET_ALL_QUESTION_FILTERS_SUPPLIER = 'SET_ALL_QUESTION_FILTERS_SUPPLIER';

export interface SetProjectQuestionFiltersAction {
  type: typeof SET_QUESTION_FILTERS_PROJECT;
  data: QuestionCategory[];
}

export interface SetAllQuestionFiltersAction {
  type: typeof SET_ALL_QUESTION_FILTERS_PROJECT;
  data: QuestionCategory[];
}

export interface SetLoadingQuestionFiltersAction {
  type: typeof SET_LOADING_QUESTION_FILTERS_PROJECT;
  data: boolean;
}

export interface SetLoadingSupplierFiltersAction {
  type: typeof SET_LOADING_QUESTION_FILTERS_SUPPLIER;
  data: boolean;
}

export interface SetAvailableSupplierCategoriesAction {
  type: typeof SET_AVAILABLE_SUPPLIER_CATEGORIES;
  data: ISupplierCategoryGetModel[];
}

// Project

export interface SetAvailableProjectPhasesAction {
  type: typeof SET_AVAILABLE_PROJECT_PHASES;
  data: IProjectPhaseGetModel[];
}

export interface SetSelectedProjectQuestionsFilterPhasesAction {
  type: typeof SET_SELECTED_PROJECT_QUESTION_FILTER_PHASES;
  data: IProjectPhaseGetModel[];
}

export interface SetSelectedProjectQuestionsFilterSuppCatsAction {
  type: typeof SET_SELECTED_PROJECT_QUESTION_FILTER_SUPP_CATS;
  data: ISupplierCategoryGetModel[];
}

export interface SetSelectedProjectQuestionsFilterQuestionCatsAction {
  type: typeof SET_SELECTED_PROJECT_QUESTION_FILTER_QUESTION_CATS;
  data: QuestionCategory[];
}

export interface ResetSelectedProjectQuestionFiltersAction {
  type: typeof RESET_SELECTED_PROJECT_QUESTION_FILTERS;
}

export interface SetPaginatedQuestionsProjectAction {
  type: typeof SET_PAGINATED_QUESTIONS_PROJECT;
  data: PaginatedQuestionsState;
}

export interface SetSelectedPageQuestionsProjectAction {
  type: typeof SET_SELECTED_PAGE_QUESTIONS_PROJECT;
  data: number;
}

export interface SetLodingQuestionProjectDataAction {
  type: typeof SET_LOADING_QUESTION_DATA_PROJECT;
  data: boolean;
}

export interface SetResetProjectQuestionsListManuallyAction {
  type: typeof SET_RESET_PROJECTS_QUESTION_LIST_MANUALLY;
  data: boolean;
}

// SUPPLIER

export interface SetSelectedSupplierQuestionsFilterPhasesAction {
  type: typeof SET_SELECTED_SUPPLIER_QUESTION_FILTER_PHASES;
  data: IProjectPhaseGetModel[];
}

export interface SetSelectedSupplierQuestionsFilterSuppCatsAction {
  type: typeof SET_SELECTED_SUPPLIER_QUESTION_FILTER_SUPP_CATS;
  data: ISupplierCategoryGetModel[];
}

export interface SetSelectedSupplierQuestionsFilterQuestionCatsAction {
  type: typeof SET_SELECTED_SUPPLIER_QUESTION_FILTER_QUESTION_CATS;
  data: QuestionCategory[];
}

export interface ResetSelectedSupplierQuestionFiltersAction {
  type: typeof RESET_SELECTED_SUPPLIER_QUESTION_FILTERS;
}

export interface SetPaginatedQuestionsSupplierAction {
  type: typeof SET_PAGINATED_QUESTIONS_SUPPLIER;
  data: PaginatedQuestionsState;
}

export interface SetSelectedPageQuestionsSupplierAction {
  type: typeof SET_SELECTED_PAGE_QUESTIONS_SUPPLIER;
  data: number;
}

export interface SetLodingQuestionSupplierDataAction {
  type: typeof SET_LOADING_QUESTION_DATA_SUPPLIER;
  data: boolean;
}

export interface SetResetSupplierQuestionsListManuallyAction {
  type: typeof SET_RESET_SUPPLIER_QUESTION_LIST_MANUALLY;
  data: boolean;
}

export interface SetAllQuestionFiltersSupplierAction {
  type: typeof SET_ALL_QUESTION_FILTERS_SUPPLIER;
  data: QuestionCategory[];
}

export function setProjectQuestionFilters(data: QuestionCategory[]): SetProjectQuestionFiltersAction {
  return {
    type: SET_QUESTION_FILTERS_PROJECT,
    data,
  };
}

export function setAllQuestionFilters(data: QuestionCategory[]): SetAllQuestionFiltersAction {
  return {
    type: SET_ALL_QUESTION_FILTERS_PROJECT,
    data,
  };
}

export function setLoadingQuestionFiltersProject(data: boolean): SetLoadingQuestionFiltersAction {
  return {
    type: SET_LOADING_QUESTION_FILTERS_PROJECT,
    data,
  };
}

export function setLoadingQuestionFiltersSupplier(data: boolean): SetLoadingSupplierFiltersAction {
  return {
    type: SET_LOADING_QUESTION_FILTERS_SUPPLIER,
    data,
  };
}

export function setAvailableSupplierCategories(data: ISupplierCategoryGetModel[]): SetAvailableSupplierCategoriesAction {
  return {
    type: SET_AVAILABLE_SUPPLIER_CATEGORIES,
    data,
  };
}

export function SetAvailableProjectPhases(data: IProjectPhaseGetModel[]): SetAvailableProjectPhasesAction {
  return {
    type: SET_AVAILABLE_PROJECT_PHASES,
    data,
  };
}

export function SetSelectedProjectQuestionFilterPhases(data: IProjectPhaseGetModel[]): SetSelectedProjectQuestionsFilterPhasesAction {
  return {
    type: SET_SELECTED_PROJECT_QUESTION_FILTER_PHASES,
    data,
  };
}

// PROJECT

export function SetSelectedProjectQuestionsFilterSuppCats(
  data: ISupplierCategoryGetModel[],
): SetSelectedProjectQuestionsFilterSuppCatsAction {
  return {
    type: SET_SELECTED_PROJECT_QUESTION_FILTER_SUPP_CATS,
    data,
  };
}

export function SetSelectedProjectQuestionsFilterQuestionCats(
  data: QuestionCategory[],
): SetSelectedProjectQuestionsFilterQuestionCatsAction {
  return {
    type: SET_SELECTED_PROJECT_QUESTION_FILTER_QUESTION_CATS,
    data,
  };
}

export function ResetSelectedProjectQuestionFilters(): ResetSelectedProjectQuestionFiltersAction {
  return {
    type: RESET_SELECTED_PROJECT_QUESTION_FILTERS,
  };
}

export function SetPaginatedQuestionsProject(data: PaginatedQuestionsState): SetPaginatedQuestionsProjectAction {
  return {
    type: SET_PAGINATED_QUESTIONS_PROJECT,
    data,
  };
}

export function SetSelectedPageQuestionsProject(data: number): SetSelectedPageQuestionsProjectAction {
  return {
    type: SET_SELECTED_PAGE_QUESTIONS_PROJECT,
    data,
  };
}

export function SetLodingQuestionProjectData(data: boolean): SetLodingQuestionProjectDataAction {
  return {
    type: SET_LOADING_QUESTION_DATA_PROJECT,
    data,
  };
}

export function SetResetProjectQuestionsListManually(data: boolean): SetResetProjectQuestionsListManuallyAction {
  return {
    type: SET_RESET_PROJECTS_QUESTION_LIST_MANUALLY,
    data,
  };
}

// Supplier

export function SetSelectedSupplierQuestionFilterPhases(data: IProjectPhaseGetModel[]): SetSelectedSupplierQuestionsFilterPhasesAction {
  return {
    type: SET_SELECTED_SUPPLIER_QUESTION_FILTER_PHASES,
    data,
  };
}

export function SetSelectedSupplierQuestionsFilterSuppCats(
  data: ISupplierCategoryGetModel[],
): SetSelectedSupplierQuestionsFilterSuppCatsAction {
  return {
    type: SET_SELECTED_SUPPLIER_QUESTION_FILTER_SUPP_CATS,
    data,
  };
}

export function SetSelectedSupplierQuestionsFilterQuestionCats(
  data: QuestionCategory[],
): SetSelectedSupplierQuestionsFilterQuestionCatsAction {
  return {
    type: SET_SELECTED_SUPPLIER_QUESTION_FILTER_QUESTION_CATS,
    data,
  };
}

export function ResetSelectedSupplierQuestionFilters(): ResetSelectedSupplierQuestionFiltersAction {
  return {
    type: RESET_SELECTED_SUPPLIER_QUESTION_FILTERS,
  };
}

export function SetPaginatedQuestionsSupplier(data: PaginatedQuestionsState): SetPaginatedQuestionsSupplierAction {
  return {
    type: SET_PAGINATED_QUESTIONS_SUPPLIER,
    data,
  };
}

export function SetSelectedPageQuestionsSupplier(data: number): SetSelectedPageQuestionsSupplierAction {
  return {
    type: SET_SELECTED_PAGE_QUESTIONS_SUPPLIER,
    data,
  };
}

export function SetLodingQuestionSupplierData(data: boolean): SetLodingQuestionSupplierDataAction {
  return {
    type: SET_LOADING_QUESTION_DATA_SUPPLIER,
    data,
  };
}

export function SetResetSupplierQuestionsListManually(data: boolean): SetResetSupplierQuestionsListManuallyAction {
  return {
    type: SET_RESET_SUPPLIER_QUESTION_LIST_MANUALLY,
    data,
  };
}

export function setAllQuestionFiltersSupplier(data: QuestionCategory[]): SetAllQuestionFiltersSupplierAction {
  return {
    type: SET_ALL_QUESTION_FILTERS_SUPPLIER,
    data,
  };
}
