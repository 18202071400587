import React, { PropsWithChildren } from 'react';
import appSettings from 'config/appSettings';
import { InteractionType, RedirectRequest } from '@azure/msal-browser';
import { IMsalContext, MsalAuthenticationResult, useMsal } from '@azure/msal-react';
import { MsalAuthorizationTemplate } from './MsalAuthorizationTemplate';
import { AppRole } from './msalConfig';

export interface RouteGuardProps {
  roles: AppRole[];
  loadingComponent: React.ElementType<IMsalContext> | React.ElementType;
  authenticationErrorComponent: React.ElementType<MsalAuthenticationResult> | React.ElementType;
  authorizationErrorComponent: React.ElementType;
}

/**
 * @description Just a stupid wrapper around MsalAuthorizationTemplate that creates the login request.
 */
export const RouteGuard = ({
  roles,
  loadingComponent: LoadingComponent,
  authenticationErrorComponent: AuthenticationErrorComponent,
  authorizationErrorComponent: AuthorizationErrorComponent,
  children,
}: PropsWithChildren<RouteGuardProps>): React.ReactElement | null => {
  const { instance } = useMsal();

  const getLoginRequest = (): RedirectRequest => {
    return {
      authority: appSettings.auth.authority,
      scopes: appSettings.auth.loginScopes,
      account: instance.getActiveAccount() ?? undefined,
      redirectUri: appSettings.auth.redirectUrl,
    };
  };

  return (
    <MsalAuthorizationTemplate
      interactionType={instance.getActiveAccount() != null ? InteractionType.Silent : InteractionType.Redirect}
      homeAccountId={instance.getActiveAccount()?.homeAccountId}
      authenticationRequest={getLoginRequest()}
      loadingComponent={LoadingComponent}
      authenticationErrorComponent={AuthenticationErrorComponent}
      authorizationErrorComponent={AuthorizationErrorComponent}
      roles={roles}
    >
      {children}
    </MsalAuthorizationTemplate>
  );
};

export default RouteGuard;
