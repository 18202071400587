export const white = '#FFFFFF';
export const black = '#000000';
export const blueDianne = '#274457';
export const porcelain = '#F1F5F5';
export const alto = '#D6D6D6';
export const gallery = '#ECECEC';
export const gray = '#e3e3e3';
export const seashell = '#f1f5f5';
export const blue = '#242B3B';
export const shadow = '#0000001f';

export const backe = {
  black: '#000000',
  white: '#FFFFFF',
  yellow: '#FFB500',
  blue: '#242B3B',
  red: '#ED4F2E',
  green: '#2AC87D',
  lightBlue: '#2AC87D',
  blueGray: '#8F9AAA',
  darkBlue: '#323E5A',
  gray: '#F0F0F0',
  inactive: '#6D6D6D',
  shadow: '#0000001f',
};
