/* eslint-disable @typescript-eslint/no-unused-vars */
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { useTheme, ButtonBase } from '@mui/material';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import './styles.scss';

interface RadioAccordionProps {
  accordionTitle: string;
  setOpen: Function;
  open: boolean;
  radioObjectsArr?: IEvaluationOwnersGetModel[];
  accordionId: string;
  selectedValue: IEvaluationOwnersGetModel | null;
  setSelectedValue: Function;
}

// list items by their key and description
const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  // backgroundColor: theme.palette.background.default,
  padding: 0,
  margin: 0,
  border: 'none',
  borderBottomColor: theme.palette.primary.main,
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreOutlinedIcon fontSize='small' />} {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
}));

export const RadioAccordion: React.FunctionComponent<RadioAccordionProps> = ({
  accordionTitle,
  setOpen,
  open,
  radioObjectsArr,
  accordionId,
  selectedValue,
  setSelectedValue,
}: RadioAccordionProps): JSX.Element | null => {
  const theme = useTheme();

  const toggleOpen = (): void => {
    setOpen(!open);
  };

  if (radioObjectsArr?.length === 0) {
    return null;
  }

  const isItemChecked = (radioKey: number): boolean => {
    const itemFound = selectedValue?.key === radioKey;
    return !!itemFound;
  };

  const handleClick = (radioItem: IEvaluationOwnersGetModel): void => {
    setSelectedValue(radioItem);
  };

  const renderRadioButtons = (): JSX.Element | null => {
    return (
      <RadioGroup name={accordionId}>
        {radioObjectsArr?.map((radioItem) => {
          return (
            <ButtonBase key={radioItem.key} onClick={() => handleClick(radioItem)} className='radioButtonItem'>
              <FormControlLabel
                value={radioItem.key}
                control={<Radio sx={{ color: theme?.palette?.primary?.main }} checked={isItemChecked(radioItem.key)} />}
                label={radioItem.description}
              />
            </ButtonBase>
          );
        })}
      </RadioGroup>
    );
  };

  return (
    <Accordion expanded={open} onChange={toggleOpen} className='accordionWrapper'>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={accordionId} id={accordionId} className='accordionSummary'>
        <Typography sx={{ fontWeight: 700 }}>{accordionTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails className='accordionContent'>{renderRadioButtons()}</AccordionDetails>
    </Accordion>
  );
};
