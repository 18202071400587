import { createTheme } from '@mui/material/styles';
import { backe } from './jsColors';
import { responsiveFontSizes } from '@mui/material';

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

const mainTheme = createTheme({
  palette: {
    primary: {
      main: backe.blue,
    },
    secondary: {
      main: backe.yellow,
    },
    background: {
      default: backe.gray,
    },
    text: {
      primary: backe.black,
      secondary: backe.white,
    },
    action: {
      hoverOpacity: 0.05,
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: '"Arial", "Roboto", "Helvetica", sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        // Name of the slot

        root: {
          // Some CSS
          paddingBottom: '8px',
          paddingTop: '8px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: { color: backe.black },
      },
    },
  },
});

export default responsiveFontSizes(mainTheme);
