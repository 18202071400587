/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { useMsal } from '@azure/msal-react';
import { useTheme } from '@mui/material';
import { useGraph } from 'hooks/useGraph/useGraph';
import { useEffect, useState } from 'react';

export const ProfileMenu = () => {
  const { instance } = useMsal();
  const { getProfilePictureUrl } = useGraph();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [profilePicture, setProfilePicture] = useState<string>('');

  const getAvatarNick = (): string => {
    const activeAccount = instance.getActiveAccount();
    if (activeAccount !== null) {
      if (activeAccount.name !== undefined) {
        if (activeAccount.name.length > 1) {
          return activeAccount.name[0];
        }
      }
    }

    return '';
  };
  const [avatarNick] = useState<string>(getAvatarNick());

  useEffect(() => {
    void getProfilePictureUrl().then((url) => setProfilePicture(url));
  }, []);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={instance.getActiveAccount()?.username ?? ''}>
          <Typography
            variant='h6'
            noWrap
            sx={{
              display: { xs: 'none', sm: 'block' },
              fontWeight: 400,
            }}
          >
            {instance.getActiveAccount()?.name ?? ''}
          </Typography>
        </Tooltip>
        <IconButton
          onClick={handleClick}
          size='small'
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.primary.main,
            }}
            src={profilePicture}
          >
            {avatarNick}
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        disableScrollLock={true}
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box>
          <MenuItem>
            <Avatar /> {instance.getActiveAccount()?.username ?? ''}
          </MenuItem>
        </Box>
        <Divider />

        {/* Words of wisdom regarding logout by Lasse. Calling this without an account will remove
        all accounts from local storage. This is what we want here. */}
        <MenuItem onClick={async () => await instance.logoutRedirect()}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logg av
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
