import { Configuration } from '@azure/msal-browser';
import { msalLoggerCallback } from 'telemetry/logger';
import { appSettings } from '../config/appSettings';

export const msalConfig: Configuration = {
  auth: {
    clientId: appSettings.auth.clientId,
    authority: appSettings.auth.authority,
    redirectUri: appSettings.auth.redirectUrl,
    postLogoutRedirectUri: appSettings.auth.postLogoutUrl,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: msalLoggerCallback,
      piiLoggingEnabled: true,
    },
  },
};

export enum AppRole {
  EvaluationAdmin = 'EvaluationAdmin',
  QuestionAdmin = 'QuestionAdmin',
}
