import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Page404, EvaluationPage, SupplierPage, ProjectPage, Page401, Page403, AuthPage, LoginPage, LogoutPage } from 'pages';
import RouteGuard from 'auth/RouteGuard';
import { AppRole } from 'auth/msalConfig';
import { HomePage } from 'pages/HomePage/HomePage';

export const AppRoutes = (): JSX.Element => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route
        path='/'
        element={
          <RouteGuard
            roles={[AppRole.EvaluationAdmin, AppRole.QuestionAdmin]}
            loadingComponent={AuthPage}
            authenticationErrorComponent={Page401}
            authorizationErrorComponent={Page403}
          >
            <HomePage />
          </RouteGuard>
        }
      />
      <Route
        path='/evaluations'
        element={
          <RouteGuard
            roles={[AppRole.EvaluationAdmin]}
            loadingComponent={AuthPage}
            authenticationErrorComponent={Page401}
            authorizationErrorComponent={Page403}
          >
            <EvaluationPage />
          </RouteGuard>
        }
      />
      <Route
        path='/supplier'
        element={
          <RouteGuard
            roles={[AppRole.QuestionAdmin]}
            loadingComponent={AuthPage}
            authenticationErrorComponent={Page401}
            authorizationErrorComponent={Page403}
          >
            <SupplierPage />
          </RouteGuard>
        }
      />
      <Route
        path='/project'
        element={
          <RouteGuard
            roles={[AppRole.QuestionAdmin]}
            loadingComponent={AuthPage}
            authenticationErrorComponent={Page401}
            authorizationErrorComponent={Page403}
          >
            <ProjectPage />
          </RouteGuard>
        }
      />
      <Route path='/login' element={<LoginPage />} />
      <Route path='/logout' element={<LogoutPage />} />
      <Route path='/auth' element={<AuthPage />} />
      <Route path='*' element={<Page404 />} />
    </Routes>
  );
};
