import { useSelector } from 'react-redux';
import { useState } from 'react';
import { projectQuestionsFilters, availableSupplierCategoriesSelector, availableProjectPhasesSelector } from 'util/selectors';
import '../styles.scss';
import { CheckBoxAccordion } from 'components';

interface QuestionCategoriesListProps {
  isProjectPage: boolean;
  selectedQuestionPhases: IProjectPhaseGetModel[];
  selectedQuestionSuppTypes?: ISupplierCategoryGetModel[];
  selectedQuestionQTypes: QuestionCategory[];
  handleSelectedPhaseChange: Function;
  handleSelectedSuppTypesChange?: Function;
  handleSelectedQuestionCatsChange: Function;
}

export const QuestionCategoriesList = ({
  isProjectPage,
  selectedQuestionPhases,
  selectedQuestionSuppTypes,
  selectedQuestionQTypes,
  handleSelectedPhaseChange,
  handleSelectedSuppTypesChange,
  handleSelectedQuestionCatsChange,
}: QuestionCategoriesListProps): JSX.Element => {
  const projectQuestionCategories = useSelector(projectQuestionsFilters);
  const availableSupplierCategories = useSelector(availableSupplierCategoriesSelector);
  const availableProjectPhases = useSelector(availableProjectPhasesSelector);

  const [showQuestionsCatsAccordion, setShowQuestionsCatsAccordion] = useState<boolean>(false);
  const [showProjectCatsAccordion, setShowProjectCatsAccordion] = useState<boolean>(false);
  const [showProjectPhasesAccordion, setShowProjectPhasesAccordion] = useState<boolean>(false);

  return (
    <div>
      <div className='filterItemContainer'>
        <CheckBoxAccordion
          open={showProjectPhasesAccordion}
          setOpen={setShowProjectPhasesAccordion}
          accordionTitle='Prosjektfase'
          checkBoxObjectsArr={availableProjectPhases}
          accordionId='phases-accord'
          selectedValues={selectedQuestionPhases}
          setSelectedValues={handleSelectedPhaseChange}
        />
      </div>
      {!isProjectPage ? (
        <div className='filterItemContainer'>
          <CheckBoxAccordion
            open={showProjectCatsAccordion}
            setOpen={setShowProjectCatsAccordion}
            accordionTitle='Leverandørtype'
            checkBoxObjectsArr={availableSupplierCategories}
            accordionId='cats-accord'
            selectedValues={selectedQuestionSuppTypes}
            setSelectedValues={handleSelectedSuppTypesChange}
          />
        </div>
      ) : null}

      <div className='filterItemContainer'>
        <CheckBoxAccordion
          open={showQuestionsCatsAccordion}
          setOpen={setShowQuestionsCatsAccordion}
          accordionTitle='Spørsmålskategorier'
          checkBoxObjectsArr={projectQuestionCategories}
          accordionId='questions-accord'
          selectedValues={selectedQuestionQTypes}
          setSelectedValues={handleSelectedQuestionCatsChange}
        />
      </div>
    </div>
  );
};

export default QuestionCategoriesList;
